<template>
  <full-screen-box :top="true" :fullscreen="true">
    <span>
      <ul
        class="collection"
        style="margin-top: 10px; margin-bottom: 20px;"
      >
        <li class="collection-item"
        >
          <p>
            Условия использования пользования OfflinePad
          </p>
          <p>
            1 Общие положения
          </p>
          <p>
            1.1 Разработчик не гарантирует отсутствие ошибок в Программном продукте, но будет принимать все меры обеспечиванию корректной работы Программного продукта. В случае обнаружения ошибок в Программном продукте, Разработчик обязуется их исправлять по мере возможности.
          </p>
          <p>
            1.2 Работа Программного продукта на стороне клиента поддерживается в современных (последних версий) браузерах: Google Chrome, Firefox, Opera.
          </p>
          <p>
            1.3 Программный продукт предоставляется по принципу «как есть» и Разработчик не гарантирует, что все функциональные возможности Программного продукта будут отвечать ожиданиям Пользователя или смогут быть применимы для конкретной его цели. Разработчик не отвечает за возможные упущенные выгоды, возникшие в связи с использованием Программного продукта или иные потери, понесенные Пользователем в связи с использованием Программного продукта.
          </p>
          <p>
            2 Права и обязанности Пользователя
          </p>
          <p>
            2.1 Пользователь не может просматривать, изменять исходный код или иным образом воздействовать на работу Программного продукта и его функции. Пользователь также не может использовать Программный продукт или отдельные его фрагменты в других проектах и продуктах.
          </p>
          <p>
            2.2 Программный продукт построен с использованием технологий хранения данных в браузере. Очистка кеша может повлечь потерю данных Пользователя Программного продукта. Пользователь самостоятельно обеспечивает резервное копирование своих данных. Разработчик не несет ответственности за потерю данных вызванную очисткой кеша браузера или его удалением с устройства Пользователя.
          </p>
        </li>
        <li class="collection-item">
          <button
            class="btn waves-effect waves-light notepads-selector-item"
            type="submit"
            name="action"
            style="width: 100%; margin-bottom: 10px;"
            @click="$emit('accept')"
          >
            <font-awesome-icon icon="check" />
            Принять
          </button>
          <button
            class="btn waves-effect waves-light red"
            type="submit"
            name="action"
            style="width: 100%;"
            @click="$emit('reject')"
          >
            <font-awesome-icon icon="times-circle" />
            Отказаться
          </button>
        </li>
      </ul>
    </span>
  </full-screen-box>
</template>

<script>
import FullScreenBox from "./FullScreenBox.vue";
export default {
  components: {
    FullScreenBox,
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
