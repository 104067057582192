<template>
  <full-screen-box
    :top="true"
    style="background: rgba(0,0,0,0.5);"
    >
  </full-screen-box>
</template>

<script>
import FullScreenBox from "./FullScreenBox.vue";
export default {
  components: {
    FullScreenBox,
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
