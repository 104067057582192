export default {
    "11111": "абажур",
    "11112": "абаз",
    "11113": "абак",
    "11114": "абака",
    "11115": "аббат",
    "11116": "абзац",
    "11121": "аблаут",
    "11122": "абордаж",
    "11123": "абрис",
    "11124": "абсент",
    "11125": "абсида",
    "11126": "абсурд",
    "11131": "абулия",
    "11132": "абхаз",
    "11133": "абшид",
    "11134": "аванс",
    "11135": "авар",
    "11136": "аварец",
    "11141": "авария",
    "11142": "аварка",
    "11143": "авгиев",
    "11144": "авгур",
    "11145": "август",
    "11146": "авеню",
    "11151": "авизо",
    "11152": "авось",
    "11153": "аврал",
    "11154": "аврора",
    "11155": "авто",
    "11156": "автол",
    "11161": "автор",
    "11162": "авуары",
    "11163": "ага",
    "11164": "агава",
    "11165": "агалит",
    "11166": "агамия",
    "11211": "агамы",
    "11212": "агат",
    "11213": "агент",
    "11214": "агитка",
    "11215": "агнец",
    "11216": "агония",
    "11221": "аграф",
    "11222": "агу",
    "11223": "агул",
    "11224": "агулец",
    "11225": "агулка",
    "11226": "адажио",
    "11231": "адамит",
    "11232": "адамов",
    "11233": "адат",
    "11234": "аденит",
    "11235": "адепт",
    "11236": "аджика",
    "11241": "адовый",
    "11242": "адонис",
    "11243": "адрес",
    "11244": "адски",
    "11245": "адский",
    "11246": "ажио",
    "11251": "ажно",
    "11252": "ажур",
    "11253": "азалия",
    "11254": "азарт",
    "11255": "азбука",
    "11256": "азиат",
    "11261": "азимут",
    "11262": "азот",
    "11263": "азурит",
    "11264": "азям",
    "11265": "аил",
    "11266": "аир",
    "11311": "аирный",
    "11312": "аист",
    "11313": "айва",
    "11314": "айда",
    "11315": "айкать",
    "11316": "аймак",
    "11321": "айран",
    "11322": "айрол",
    "11323": "айсор",
    "11324": "акант",
    "11325": "аканф",
    "11326": "аканье",
    "11331": "акать",
    "11332": "акация",
    "11333": "аккорд",
    "11334": "аконит",
    "11335": "акр",
    "11336": "акриды",
    "11341": "акрил",
    "11342": "акт",
    "11343": "актив",
    "11344": "актин",
    "11345": "актёр",
    "11346": "акула",
    "11351": "акулий",
    "11352": "акут",
    "11353": "акушер",
    "11354": "акцент",
    "11355": "акцепт",
    "11356": "акциз",
    "11361": "акция",
    "11362": "акын",
    "11363": "аланин",
    "11364": "алгол",
    "11365": "алеть",
    "11366": "алеут",
    "11411": "али",
    "11412": "алиби",
    "11413": "алкать",
    "11414": "аллах",
    "11415": "аллея",
    "11416": "алло",
    "11421": "аллюр",
    "11422": "алмаз",
    "11423": "алость",
    "11424": "алоэ",
    "11425": "алтаец",
    "11426": "алтарь",
    "11431": "алтей",
    "11432": "алтын",
    "11433": "алунит",
    "11434": "алчно",
    "11435": "алчный",
    "11436": "алый",
    "11441": "алыча",
    "11442": "аль",
    "11443": "альбит",
    "11444": "альбом",
    "11445": "альков",
    "11446": "альт",
    "11451": "альфа",
    "11452": "альянс",
    "11453": "алёнка",
    "11454": "аманат",
    "11455": "амба",
    "11456": "амбар",
    "11461": "амбра",
    "11462": "амбре",
    "11463": "амвон",
    "11464": "амид",
    "11465": "амидол",
    "11466": "амил",
    "11511": "амилен",
    "11512": "амины",
    "11513": "аминь",
    "11514": "амитоз",
    "11515": "амия",
    "11516": "амми",
    "11521": "аммиак",
    "11522": "амнион",
    "11523": "ампер",
    "11524": "ампир",
    "11525": "амплуа",
    "11526": "ампула",
    "11531": "амулет",
    "11532": "амур",
    "11533": "амфора",
    "11534": "амхара",
    "11535": "амёба",
    "11536": "анализ",
    "11541": "аналог",
    "11542": "аналой",
    "11543": "ананас",
    "11544": "анатом",
    "11545": "ангар",
    "11546": "ангел",
    "11551": "ангина",
    "11552": "ангоб",
    "11553": "андиец",
    "11554": "анемия",
    "11555": "анетол",
    "11556": "анид",
    "11561": "анилин",
    "11562": "анион",
    "11563": "анис",
    "11564": "анкер",
    "11565": "анкета",
    "11566": "анклав",
    "11611": "служба",
    "11612": "аннаты",
    "11613": "анод",
    "11614": "аноним",
    "11615": "анонс",
    "11616": "антик",
    "11621": "антре",
    "11622": "антрит",
    "11623": "анты",
    "11624": "анурия",
    "11625": "анфас",
    "11626": "анчар",
    "11631": "анчоус",
    "11632": "аншлаг",
    "11633": "аншлюс",
    "11634": "аорист",
    "11635": "аорта",
    "11636": "аортит",
    "11641": "апарт",
    "11642": "апатит",
    "11643": "апатия",
    "11644": "апаш",
    "11645": "апелла",
    "11646": "апилак",
    "11651": "апломб",
    "11652": "апноэ",
    "11653": "апогей",
    "11654": "аполог",
    "11655": "апорт",
    "11656": "апофиз",
    "11661": "апрель",
    "11662": "апрош",
    "11663": "апсида",
    "11664": "аптека",
    "11665": "араб",
    "11666": "арабка",
    "12111": "арак",
    "12112": "аралия",
    "12113": "арама",
    "12114": "арап",
    "12115": "арапка",
    "12116": "арат",
    "12121": "арахис",
    "12122": "арба",
    "12123": "арбитр",
    "12124": "арбуз",
    "12125": "аргал",
    "12126": "аргали",
    "12131": "аргас",
    "12132": "арго",
    "12133": "аргон",
    "12134": "аргус",
    "12135": "ареал",
    "12136": "арена",
    "12141": "аренда",
    "12142": "арест",
    "12143": "ариец",
    "12144": "арийка",
    "12145": "ариозо",
    "12146": "ария",
    "12151": "арка",
    "12152": "аркада",
    "12153": "аркан",
    "12154": "армада",
    "12155": "армеец",
    "12156": "армия",
    "12161": "армяк",
    "12162": "арника",
    "12163": "аромат",
    "12164": "арсин",
    "12165": "артель",
    "12166": "артист",
    "12211": "артрит",
    "12212": "артроз",
    "12213": "арфа",
    "12214": "арфист",
    "12215": "архар",
    "12216": "архив",
    "12221": "архонт",
    "12222": "арча",
    "12223": "аршин",
    "12224": "арык",
    "12225": "асбест",
    "12226": "асидол",
    "12231": "аскет",
    "12232": "аспект",
    "12233": "аспид",
    "12234": "астат",
    "12235": "астма",
    "12236": "астра",
    "12241": "асцит",
    "12242": "ась",
    "12243": "атака",
    "12244": "атаман",
    "12245": "атеизм",
    "12246": "атеист",
    "12251": "ателье",
    "12252": "атетоз",
    "12253": "атлант",
    "12254": "атлас",
    "12255": "атлет",
    "12256": "атолл",
    "12261": "атом",
    "12262": "атония",
    "12263": "атофан",
    "12264": "атташе",
    "12265": "аттик",
    "12266": "ату",
    "12311": "аукать",
    "12312": "ауксин",
    "12313": "аул",
    "12314": "аут",
    "12315": "афазия",
    "12316": "афакия",
    "12321": "афгани",
    "12322": "афелий",
    "12323": "афера",
    "12324": "афиша",
    "12325": "афония",
    "12326": "афронт",
    "12331": "аффект",
    "12332": "аффикс",
    "12333": "аханье",
    "12334": "ахать",
    "12335": "ахилия",
    "12336": "ахинея",
    "12341": "ахнуть",
    "12342": "аховый",
    "12343": "ахолия",
    "12344": "ахти",
    "12345": "ацетат",
    "12346": "ацетил",
    "12351": "ацетон",
    "12352": "ацтеки",
    "12353": "ашуг",
    "12354": "аэробы",
    "12355": "аэрон",
    "12356": "баба",
    "12361": "бабах",
    "12362": "баббит",
    "12363": "бабий",
    "12364": "бабин",
    "12365": "бабища",
    "12366": "бабка",
    "12411": "бабкин",
    "12412": "бабник",
    "12413": "бабуин",
    "12414": "бабуся",
    "12415": "бабьё",
    "12416": "багаж",
    "12421": "багауд",
    "12422": "багер",
    "12423": "багет",
    "12424": "багор",
    "12425": "багрец",
    "12426": "бадан",
    "12431": "бадья",
    "12432": "бадьян",
    "12433": "бадяга",
    "12434": "баз",
    "12435": "база",
    "12436": "базар",
    "12441": "базис",
    "12442": "бай",
    "12443": "байбак",
    "12444": "байка",
    "12445": "байрам",
    "12446": "бак",
    "12451": "бакан",
    "12452": "бакаут",
    "12453": "бакен",
    "12454": "бакены",
    "12455": "баки",
    "12456": "катакомба",
    "12461": "бал",
    "12462": "баланс",
    "12463": "пломба",
    "12464": "чащоба",
    "12465": "балет",
    "12466": "балка",
    "12511": "балкар",
    "12512": "балкон",
    "12513": "балл",
    "12514": "баллон",
    "12515": "балык",
    "12516": "балясы",
    "12521": "бамбук",
    "12522": "банан",
    "12523": "трущоба",
    "12524": "бандаж",
    "12525": "банджо",
    "12526": "палуба",
    "12531": "банить",
    "12532": "банк",
    "12533": "банка",
    "12534": "банкет",
    "12535": "банкир",
    "12536": "банник",
    "12541": "банный",
    "12542": "бант",
    "12543": "бантик",
    "12544": "банту",
    "12545": "банщик",
    "12546": "баня",
    "12551": "баобаб",
    "12552": "бар",
    "12553": "барак",
    "12554": "свадьба",
    "12555": "барбос",
    "12556": "бард",
    "12561": "барда",
    "12562": "баржа",
    "12563": "барий",
    "12564": "барин",
    "12565": "барит",
    "12566": "барич",
    "12611": "барк",
    "12612": "барка",
    "12613": "баркан",
    "12614": "баркас",
    "12615": "бармен",
    "12616": "бармы",
    "12621": "барон",
    "12622": "барраж",
    "12623": "барс",
    "12624": "барсук",
    "12625": "бартер",
    "12626": "бархан",
    "12631": "бархат",
    "12632": "барчук",
    "12633": "барыня",
    "12634": "барыш",
    "12635": "барьер",
    "12636": "бас",
    "12641": "басить",
    "12642": "баск",
    "12643": "баска",
    "12644": "баскак",
    "12645": "баски",
    "12646": "басма",
    "12651": "басмач",
    "12652": "басня",
    "12653": "басок",
    "12654": "басон",
    "12655": "баста",
    "12656": "бастр",
    "12661": "батан",
    "12662": "батат",
    "12663": "батист",
    "12664": "батог",
    "12665": "батон",
    "12666": "батрак",
    "13111": "батырь",
    "13112": "батька",
    "13113": "батя",
    "13114": "баул",
    "13115": "бах",
    "13116": "усадьба",
    "13121": "бахвал",
    "13122": "бахилы",
    "13123": "бахча",
    "13124": "бац",
    "13125": "бачки",
    "13126": "бачок",
    "13131": "ходьба",
    "13132": "башкир",
    "13133": "башлык",
    "13134": "башмак",
    "13135": "башня",
    "13136": "баштан",
    "13141": "баю",
    "13142": "баян",
    "13143": "баять",
    "13144": "бдение",
    "13145": "бдеть",
    "13146": "бебе",
    "13151": "бебут",
    "13152": "бег",
    "13153": "бега",
    "13154": "бегать",
    "13155": "беглая",
    "13156": "беглец",
    "13161": "бегло",
    "13162": "беглый",
    "13163": "бегом",
    "13164": "бегун",
    "13165": "беда",
    "13166": "бедлам",
    "13211": "бедно",
    "13212": "бедный",
    "13213": "бедняк",
    "13214": "бедро",
    "13215": "бедуин",
    "13216": "беж",
    "13221": "бежать",
    "13222": "без",
    "13223": "бездна",
    "13224": "безе",
    "13225": "безмен",
    "13226": "безо",
    "13231": "безоар",
    "13232": "бей",
    "13233": "бейка",
    "13234": "бейт",
    "13235": "бек",
    "13236": "бекар",
    "13241": "бекас",
    "13242": "бекеша",
    "13243": "бекон",
    "13244": "бел",
    "13245": "белена",
    "13246": "белеть",
    "13251": "бели",
    "13252": "белила",
    "13253": "белить",
    "13254": "белка",
    "13255": "белки",
    "13256": "бело",
    "13261": "белое",
    "13262": "белок",
    "13263": "белоус",
    "13264": "белуга",
    "13265": "белуха",
    "13266": "белый",
    "13311": "бель",
    "13312": "бельмо",
    "13313": "бельё",
    "13314": "беляк",
    "13315": "беляна",
    "13316": "беляши",
    "13321": "белёк",
    "13322": "бемоль",
    "13323": "бензил",
    "13324": "бензин",
    "13325": "бензол",
    "13326": "бенуар",
    "13331": "бербер",
    "13332": "бердыш",
    "13333": "бере",
    "13334": "берег",
    "13335": "беремя",
    "13336": "берест",
    "13341": "берет",
    "13342": "беречь",
    "13343": "берилл",
    "13344": "беркут",
    "13345": "берцо",
    "13346": "берёза",
    "13351": "судьба",
    "13352": "беседа",
    "13353": "бесить",
    "13354": "резьба",
    "13355": "бета",
    "13356": "бетаин",
    "13361": "бетель",
    "13362": "бетон",
    "13363": "бечева",
    "13364": "бешено",
    "13365": "бешмет",
    "13366": "бибабо",
    "13411": "стрельба",
    "13412": "бивак",
    "13413": "бивать",
    "13414": "бивень",
    "13415": "бивуак",
    "13416": "бигуди",
    "13421": "бидон",
    "13422": "биение",
    "13423": "бизань",
    "13424": "бизнес",
    "13425": "бизон",
    "13426": "билет",
    "13431": "биллон",
    "13432": "билль",
    "13433": "било",
    "13434": "бимс",
    "13435": "бином",
    "13436": "бинт",
    "13441": "биолит",
    "13442": "биолог",
    "13443": "бионик",
    "13444": "биотит",
    "13445": "биоток",
    "13446": "биотоп",
    "13451": "биплан",
    "13452": "биржа",
    "13453": "бирка",
    "13454": "бирюза",
    "13455": "бирюк",
    "13456": "бирюч",
    "13461": "бис",
    "13462": "бисер",
    "13463": "бистро",
    "13464": "бита",
    "13465": "битва",
    "13466": "битки",
    "13511": "битком",
    "13512": "битник",
    "13513": "битум",
    "13514": "битый",
    "13515": "бить",
    "13516": "биться",
    "13521": "битьё",
    "13522": "битюг",
    "13523": "бицепс",
    "13524": "просьба",
    "13525": "бишь",
    "13526": "благо",
    "13531": "благой",
    "13532": "блажь",
    "13533": "бланк",
    "13534": "блат",
    "13535": "бледно",
    "13536": "бленда",
    "13541": "блеск",
    "13542": "блесна",
    "13543": "блеф",
    "13544": "женитьба",
    "13545": "ближе",
    "13546": "близ",
    "13551": "близко",
    "13552": "блик",
    "13553": "блин",
    "13554": "блинт",
    "13555": "блок",
    "13556": "блонды",
    "13561": "блоха",
    "13562": "блошка",
    "13563": "держава",
    "13564": "блуза",
    "13565": "блузка",
    "13566": "блювал",
    "13611": "блюдо",
    "13612": "блюдце",
    "13613": "блюз",
    "13614": "блюсти",
    "13615": "пахлава",
    "13616": "бляшка",
    "13621": "блёкло",
    "13622": "боа",
    "13623": "боб",
    "13624": "бобина",
    "13625": "бобки",
    "13626": "бобок",
    "13631": "бобр",
    "13632": "бобрик",
    "13633": "бобыль",
    "13634": "бобёр",
    "13635": "переправа",
    "13636": "богара",
    "13641": "богато",
    "13642": "богач",
    "13643": "богаче",
    "13644": "богема",
    "13645": "богиня",
    "13646": "бодать",
    "13651": "бодро",
    "13652": "бодрый",
    "13653": "бодяга",
    "13654": "бодяк",
    "13655": "боевик",
    "13656": "боевой",
    "13661": "боец",
    "13662": "божба",
    "13663": "приправа",
    "13664": "оправа",
    "13665": "божок",
    "13666": "бой",
    "14111": "бойкий",
    "14112": "бойко",
    "14113": "бойкот",
    "14114": "бойлер",
    "14115": "бойня",
    "14116": "бок",
    "14121": "бокал",
    "14122": "боком",
    "14123": "бокс",
    "14124": "боксит",
    "14125": "боксёр",
    "14126": "справа",
    "14131": "боле",
    "14132": "более",
    "14133": "болеро",
    "14134": "болеть",
    "14135": "болид",
    "14136": "болото",
    "14141": "болт",
    "14142": "болтун",
    "14143": "боль",
    "14144": "больно",
    "14145": "больше",
    "14146": "бом",
    "14151": "бомба",
    "14152": "бонза",
    "14153": "бонна",
    "14154": "боны",
    "14155": "бор",
    "14156": "бора",
    "14161": "борат",
    "14162": "боргес",
    "14163": "бордо",
    "14164": "бордюр",
    "14165": "борей",
    "14166": "борец",
    "14211": "боржом",
    "14212": "борзая",
    "14213": "управа",
    "14214": "борид",
    "14215": "борнит",
    "14216": "борный",
    "14221": "боров",
    "14222": "борода",
    "14223": "борона",
    "14224": "бороть",
    "14225": "борт",
    "14226": "бортик",
    "14231": "борть",
    "14232": "борщ",
    "14233": "борьба",
    "14234": "боскет",
    "14235": "босняк",
    "14236": "босой",
    "14241": "босс",
    "14242": "бостон",
    "14243": "босяк",
    "14244": "бот",
    "14245": "ботало",
    "14246": "ботать",
    "14251": "ботва",
    "14252": "ботик",
    "14253": "ботики",
    "14254": "боты",
    "14255": "боцман",
    "14256": "бочаг",
    "14261": "бочар",
    "14262": "бочка",
    "14263": "бочком",
    "14264": "бочок",
    "14265": "боязно",
    "14266": "боязнь",
    "14311": "боярин",
    "14312": "боёк",
    "14313": "бра",
    "14314": "браво",
    "14315": "бравый",
    "14316": "брага",
    "14321": "бражка",
    "14322": "бразда",
    "14323": "бразды",
    "14324": "брак",
    "14325": "бракёр",
    "14326": "браман",
    "14331": "брамин",
    "14332": "брань",
    "14333": "брас",
    "14334": "брасс",
    "14335": "брат",
    "14336": "октава",
    "14341": "застава",
    "14342": "братец",
    "14343": "братия",
    "14344": "подстава",
    "14345": "брать",
    "14346": "бревно",
    "14351": "брег",
    "14352": "брегет",
    "14353": "бред",
    "14354": "бредни",
    "14355": "брелок",
    "14356": "бремя",
    "14361": "брести",
    "14362": "бретёр",
    "14363": "кружева",
    "14364": "оладьи",
    "14365": "брешь",
    "14366": "бриг",
    "14411": "бридж",
    "14412": "бриджи",
    "14413": "бриз",
    "14414": "брикет",
    "14415": "бриошь",
    "14416": "бритва",
    "14421": "бритт",
    "14422": "бритый",
    "14423": "брить",
    "14424": "бритьё",
    "14425": "бричка",
    "14426": "бровка",
    "14431": "бровь",
    "14432": "брод",
    "14433": "брокер",
    "14434": "бром",
    "14435": "бронза",
    "14436": "бронхи",
    "14441": "броня",
    "14442": "бросок",
    "14443": "брошка",
    "14444": "брошь",
    "14445": "брр",
    "14446": "брудер",
    "14451": "брус",
    "14452": "брусок",
    "14453": "брутто",
    "14454": "брыжи",
    "14455": "брызги",
    "14456": "брынза",
    "14461": "брысь",
    "14462": "брюзга",
    "14463": "брюква",
    "14464": "брюки",
    "14465": "брюмер",
    "14466": "брюнет",
    "14511": "брюхан",
    "14512": "брюхач",
    "14513": "брюхо",
    "14514": "брюшко",
    "14515": "бряк",
    "14516": "брёх",
    "14521": "бубал",
    "14522": "бубен",
    "14523": "бублик",
    "14524": "бубна",
    "14525": "бубны",
    "14526": "бубон",
    "14531": "бугай",
    "14532": "бугель",
    "14533": "буги",
    "14534": "бугор",
    "14535": "будара",
    "14536": "буде",
    "14541": "будет",
    "14542": "будить",
    "14543": "будка",
    "14544": "будни",
    "14545": "будний",
    "14546": "будра",
    "14551": "будто",
    "14552": "будуар",
    "14553": "будучи",
    "14554": "буер",
    "14555": "буерак",
    "14556": "буж",
    "14561": "буза",
    "14562": "бузина",
    "14563": "королева",
    "14564": "буй",
    "14565": "буйвол",
    "14566": "буйно",
    "14611": "синева",
    "14612": "бук",
    "14613": "бука",
    "14614": "буква",
    "14615": "букет",
    "14616": "буккер",
    "14621": "букле",
    "14622": "буклет",
    "14623": "букли",
    "14624": "букса",
    "14625": "буксир",
    "14626": "булава",
    "14631": "булат",
    "14632": "булка",
    "14633": "булла",
    "14634": "буллит",
    "14635": "бултых",
    "14636": "бульон",
    "14641": "бум",
    "14642": "бумага",
    "14643": "бунд",
    "14644": "бункер",
    "14645": "бунт",
    "14646": "бунчук",
    "14651": "бур",
    "14652": "бура",
    "14653": "бурав",
    "14654": "бурак",
    "14655": "буран",
    "14656": "бурбон",
    "14661": "бурда",
    "14662": "бурдюк",
    "14663": "буреть",
    "14664": "буржуа",
    "14665": "буржуй",
    "14666": "буриме",
    "15111": "бурить",
    "15112": "бурка",
    "15113": "бурки",
    "15114": "бурлак",
    "15115": "бурно",
    "15116": "бурнус",
    "15121": "бурный",
    "15122": "бурса",
    "15123": "бурсак",
    "15124": "бурсит",
    "15125": "бурт",
    "15126": "бурун",
    "15131": "бурхан",
    "15132": "бурщик",
    "15133": "бурый",
    "15134": "бурьян",
    "15135": "буря",
    "15136": "бурят",
    "15141": "бусина",
    "15142": "бусы",
    "15143": "бут",
    "15144": "бутан",
    "15145": "бутил",
    "15146": "бутить",
    "15151": "бутон",
    "15152": "бутсы",
    "15153": "бутуз",
    "15154": "бутыль",
    "15155": "буфер",
    "15156": "буфет",
    "15161": "буфф",
    "15162": "буффон",
    "15163": "буфы",
    "15164": "бух",
    "15165": "нажива",
    "15166": "бухта",
    "15211": "буча",
    "15212": "бучить",
    "15213": "бушель",
    "15214": "бушлат",
    "15215": "бушмен",
    "15216": "буян",
    "15221": "буёк",
    "15222": "бывало",
    "15223": "бывать",
    "15224": "бывший",
    "15225": "подлива",
    "15226": "бык",
    "15231": "былина",
    "15232": "было",
    "15233": "былое",
    "15234": "былой",
    "15235": "быль",
    "15236": "быльё",
    "15241": "быстро",
    "15242": "быт",
    "15243": "бытие",
    "15244": "быть",
    "15245": "бычий",
    "15246": "бычина",
    "15251": "бычок",
    "15252": "бьеф",
    "15253": "бэр",
    "15254": "бювар",
    "15255": "бюджет",
    "15256": "бюргер",
    "15261": "бюро",
    "15262": "бюст",
    "15263": "бязь",
    "15264": "бёрдо",
    "15265": "бёрце",
    "15266": "вабик",
    "15311": "вабило",
    "15312": "вабить",
    "15313": "вага",
    "15314": "вагон",
    "15315": "важно",
    "15316": "важный",
    "15321": "ваза",
    "15322": "вазон",
    "15323": "вайда",
    "15324": "вайя",
    "15325": "вакса",
    "15326": "вакуум",
    "15331": "вал",
    "15332": "валах",
    "15333": "валет",
    "15334": "валец",
    "15335": "валик",
    "15336": "валить",
    "15341": "валка",
    "15342": "валкий",
    "15343": "валко",
    "15344": "валок",
    "15345": "валом",
    "15346": "валуй",
    "15351": "валун",
    "15352": "валух",
    "15353": "вальс",
    "15354": "вальцы",
    "15355": "валюта",
    "15356": "валять",
    "15361": "валёк",
    "15362": "валёр",
    "15363": "вам",
    "15364": "вами",
    "15365": "вампир",
    "15366": "вандал",
    "15411": "ваниль",
    "15412": "ванна",
    "15413": "ванная",
    "15414": "ванный",
    "15415": "ванта",
    "15416": "ванька",
    "15421": "вапоры",
    "15422": "вар",
    "15423": "варан",
    "15424": "варвар",
    "15425": "варево",
    "15426": "варить",
    "15431": "варка",
    "15432": "варкий",
    "15433": "варнак",
    "15434": "варяг",
    "15435": "вас",
    "15436": "вассал",
    "15441": "вата",
    "15442": "ватага",
    "15443": "ватер",
    "15444": "ватин",
    "15445": "ватман",
    "15446": "ватник",
    "15451": "ватный",
    "15452": "ватт",
    "15453": "вафля",
    "15454": "вахлак",
    "15455": "вахта",
    "15456": "вахтер",
    "15461": "вахтёр",
    "15462": "ваш",
    "15463": "ваша",
    "15464": "ваше",
    "15465": "ваши",
    "15466": "ваяние",
    "15511": "ваять",
    "15512": "вбитый",
    "15513": "вбить",
    "15514": "вблизи",
    "15515": "вбок",
    "15516": "вброд",
    "15521": "ввезти",
    "15522": "ввек",
    "15523": "вверх",
    "15524": "вверху",
    "15525": "ввести",
    "15526": "ввиду",
    "15531": "ввитый",
    "15532": "ввить",
    "15533": "ввод",
    "15534": "ввоз",
    "15535": "вволю",
    "15536": "ввысь",
    "15541": "вгиб",
    "15542": "вглубь",
    "15543": "вдали",
    "15544": "вдаль",
    "15545": "вдвое",
    "15546": "вдвоём",
    "15551": "вделка",
    "15552": "вдетый",
    "15553": "вдеть",
    "15554": "вдова",
    "15555": "вдовец",
    "15556": "вдовий",
    "15561": "вдовый",
    "15562": "вдогон",
    "15563": "вдоль",
    "15564": "вдох",
    "15565": "вдруг",
    "15566": "вдрызг",
    "15611": "вдуть",
    "15612": "ведать",
    "15613": "ведома",
    "15614": "ведомо",
    "15615": "ведро",
    "15616": "ведун",
    "15621": "веды",
    "15622": "ведь",
    "15623": "ведьма",
    "15624": "веер",
    "15625": "вежды",
    "15626": "везде",
    "15631": "везти",
    "15632": "вейник",
    "15633": "век",
    "15634": "веко",
    "15635": "вектор",
    "15636": "векша",
    "15641": "велеть",
    "15642": "вельми",
    "15643": "велюр",
    "15644": "вена",
    "15645": "венгр",
    "15646": "венды",
    "15651": "венеды",
    "15652": "венец",
    "15653": "веник",
    "15654": "вено",
    "15655": "венок",
    "15656": "венчик",
    "15661": "вепрь",
    "15662": "вепс",
    "15663": "вера",
    "15664": "верба",
    "15665": "веред",
    "15666": "верезг",
    "16111": "вереск",
    "16112": "верея",
    "16113": "верже",
    "16114": "вериги",
    "16115": "верить",
    "16116": "верки",
    "16121": "вермут",
    "16122": "вернее",
    "16123": "верно",
    "16124": "верный",
    "16125": "версет",
    "16126": "версия",
    "16131": "верста",
    "16132": "вертел",
    "16133": "вертеп",
    "16134": "вертун",
    "16135": "верть",
    "16136": "верфь",
    "16141": "верх",
    "16142": "верхом",
    "16143": "верша",
    "16144": "вершок",
    "16145": "вес",
    "16146": "весело",
    "16151": "весить",
    "16152": "веский",
    "16153": "веско",
    "16154": "весло",
    "16155": "весна",
    "16156": "весной",
    "16161": "весною",
    "16162": "весомо",
    "16163": "вест",
    "16164": "вести",
    "16165": "весть",
    "16166": "весы",
    "16211": "весь",
    "16212": "весьма",
    "16213": "ветвь",
    "16214": "ветер",
    "16215": "ветка",
    "16216": "ветла",
    "16221": "вето",
    "16222": "ветошь",
    "16223": "ветряк",
    "16224": "ветхий",
    "16225": "вех",
    "16226": "веха",
    "16231": "вече",
    "16232": "вечер",
    "16233": "вечеря",
    "16234": "вечно",
    "16235": "вечный",
    "16236": "вечор",
    "16241": "вешало",
    "16242": "вешать",
    "16243": "вешить",
    "16244": "вешка",
    "16245": "вешний",
    "16246": "вещать",
    "16251": "вещизм",
    "16252": "вещий",
    "16253": "вещица",
    "16254": "вещный",
    "16255": "вещун",
    "16256": "вещь",
    "16261": "веялка",
    "16262": "веяние",
    "16263": "веяный",
    "16264": "веять",
    "16265": "вжатие",
    "16266": "вжатый",
    "16311": "вжать",
    "16312": "взад",
    "16313": "взаймы",
    "16314": "взамен",
    "16315": "нива",
    "16316": "взачёт",
    "16321": "взашеи",
    "16322": "взашей",
    "16323": "взбить",
    "16324": "взброс",
    "16325": "взвар",
    "16326": "взвесь",
    "16331": "взвизг",
    "16332": "взвить",
    "16333": "взвод",
    "16334": "взвоз",
    "16335": "взвыть",
    "16336": "взгляд",
    "16341": "вздор",
    "16342": "вздох",
    "16343": "вздуть",
    "16344": "взлиза",
    "16345": "взлом",
    "16346": "взлёт",
    "16351": "взмах",
    "16352": "взмыть",
    "16353": "взмёт",
    "16354": "взнос",
    "16355": "взойти",
    "16356": "взор",
    "16361": "взрыв",
    "16362": "взрыть",
    "16363": "взъезд",
    "16364": "взятие",
    "16365": "крапива",
    "16366": "взяток",
    "16411": "взятый",
    "16412": "взять",
    "16413": "виадук",
    "16414": "виват",
    "16415": "вигвам",
    "16416": "виги",
    "16421": "вигонь",
    "16422": "вид",
    "16423": "видать",
    "16424": "видеть",
    "16425": "видимо",
    "16426": "видно",
    "16431": "видный",
    "16432": "виза",
    "16433": "визави",
    "16434": "визг",
    "16435": "визгун",
    "16436": "визига",
    "16441": "визир",
    "16442": "визирь",
    "16443": "визит",
    "16444": "вика",
    "16445": "викинг",
    "16446": "виконт",
    "16451": "вилка",
    "16452": "вилла",
    "16453": "виллис",
    "16454": "вилок",
    "16455": "вилы",
    "16456": "вилять",
    "16461": "вина",
    "16462": "вини",
    "16463": "винил",
    "16464": "винить",
    "16465": "винный",
    "16466": "вино",
    "16511": "винт",
    "16512": "винтик",
    "16513": "винтом",
    "16514": "виола",
    "16515": "вир",
    "16516": "вира",
    "16521": "вираж",
    "16522": "вирус",
    "16523": "вирши",
    "16524": "вис",
    "16525": "висеть",
    "16526": "виски",
    "16531": "вислый",
    "16532": "висмут",
    "16533": "висок",
    "16534": "виссон",
    "16535": "вист",
    "16536": "витать",
    "16541": "вития",
    "16542": "витой",
    "16543": "виток",
    "16544": "витраж",
    "16545": "виттов",
    "16546": "витый",
    "16551": "вить",
    "16552": "виться",
    "16553": "витьё",
    "16554": "витязь",
    "16555": "вихляй",
    "16556": "вихор",
    "16561": "вихорь",
    "16562": "вихрем",
    "16563": "вихрь",
    "16564": "вишня",
    "16565": "вишняк",
    "16566": "вишь",
    "16611": "вкатка",
    "16612": "вклад",
    "16613": "вковка",
    "16614": "вконец",
    "16615": "вкось",
    "16616": "вкривь",
    "16621": "вкруг",
    "16622": "вкупе",
    "16623": "вкус",
    "16624": "вкусно",
    "16625": "влага",
    "16626": "влажно",
    "16631": "власть",
    "16632": "влево",
    "16633": "влезть",
    "16634": "влечь",
    "16635": "влитой",
    "16636": "влитый",
    "16641": "влить",
    "16642": "влиять",
    "16643": "влёт",
    "16644": "вмазка",
    "16645": "вместе",
    "16646": "вмести",
    "16651": "вместо",
    "16652": "вмиг",
    "16653": "вмятый",
    "16654": "вмять",
    "16655": "вмётка",
    "16656": "внаймы",
    "16661": "внаём",
    "16662": "вне",
    "16663": "внести",
    "16664": "внешне",
    "16665": "вниз",
    "16666": "внизу",
    "21111": "вничью",
    "21112": "внове",
    "21113": "вновь",
    "21114": "внос",
    "21115": "вноска",
    "21116": "внук",
    "21121": "внутри",
    "21122": "внутрь",
    "21123": "внучек",
    "21124": "внучка",
    "21125": "внятно",
    "21126": "внять",
    "21131": "вобла",
    "21132": "вовек",
    "21133": "вовеки",
    "21134": "вовне",
    "21135": "вовсе",
    "21136": "вовсю",
    "21141": "вогул",
    "21142": "вода",
    "21143": "водить",
    "21144": "водица",
    "21145": "прерогатива",
    "21146": "водник",
    "21151": "водный",
    "21152": "водоём",
    "21153": "воды",
    "21154": "вожак",
    "21155": "вождь",
    "21156": "вожжи",
    "21161": "воз",
    "21162": "воздух",
    "21163": "возить",
    "21164": "возка",
    "21165": "возле",
    "21166": "возня",
    "21211": "возок",
    "21212": "возчик",
    "21213": "воин",
    "21214": "вой",
    "21215": "войлок",
    "21216": "война",
    "21221": "войско",
    "21222": "войт",
    "21223": "войти",
    "21224": "вокзал",
    "21225": "вокруг",
    "21226": "вол",
    "21231": "волан",
    "21232": "волк",
    "21233": "волна",
    "21234": "волок",
    "21235": "волос",
    "21236": "волочь",
    "21241": "волхв",
    "21242": "волчий",
    "21243": "волчок",
    "21244": "вольер",
    "21245": "вольно",
    "21246": "вольт",
    "21251": "вольта",
    "21252": "волюта",
    "21253": "воля",
    "21254": "вон",
    "21255": "вона",
    "21256": "инициатива",
    "21261": "альтернатива",
    "21262": "тетива",
    "21263": "вообще",
    "21264": "воочию",
    "21265": "перспектива",
    "21266": "директива",
    "21311": "вопрос",
    "21312": "полслова",
    "21313": "ворог",
    "21314": "ворон",
    "21315": "ворона",
    "21316": "ворот",
    "21321": "ворота",
    "21322": "ворох",
    "21323": "ворс",
    "21324": "ворсит",
    "21325": "ворчун",
    "21326": "ворюга",
    "21331": "восемь",
    "21332": "воск",
    "21333": "вослед",
    "21334": "восток",
    "21335": "востро",
    "21336": "восход",
    "21341": "вот",
    "21342": "вотум",
    "21343": "вотчим",
    "21344": "вотще",
    "21345": "вотяк",
    "21346": "вошь",
    "21351": "вощина",
    "21352": "вощить",
    "21353": "воющий",
    "21354": "вояж",
    "21355": "вояжёр",
    "21356": "вояка",
    "21361": "впайка",
    "21362": "впалый",
    "21363": "впасть",
    "21364": "впаять",
    "21365": "вперёд",
    "21366": "обнова",
    "21411": "впить",
    "21412": "вплавь",
    "21413": "вплоть",
    "21414": "вплыть",
    "21415": "вполне",
    "21416": "впопад",
    "21421": "впору",
    "21422": "вправе",
    "21423": "вправо",
    "21424": "впредь",
    "21425": "впрок",
    "21426": "впрямь",
    "21431": "впрячь",
    "21432": "впуск",
    "21433": "враг",
    "21434": "вражда",
    "21435": "вражий",
    "21436": "враз",
    "21441": "враки",
    "21442": "враль",
    "21443": "враньё",
    "21444": "врасти",
    "21445": "врата",
    "21446": "врать",
    "21451": "врач",
    "21452": "вред",
    "21453": "вредно",
    "21454": "врезка",
    "21455": "время",
    "21456": "врид",
    "21461": "вроде",
    "21462": "врознь",
    "21463": "врозь",
    "21464": "вруб",
    "21465": "врубка",
    "21466": "врубок",
    "21511": "врун",
    "21512": "врунья",
    "21513": "врытый",
    "21514": "врыть",
    "21515": "вряд",
    "21516": "все",
    "21521": "всегда",
    "21522": "всего",
    "21523": "всеять",
    "21524": "вскачь",
    "21525": "вскок",
    "21526": "вскоре",
    "21531": "вскрик",
    "21532": "вслед",
    "21533": "вслух",
    "21534": "вспять",
    "21535": "встарь",
    "21536": "встать",
    "21541": "всуе",
    "21542": "всхлип",
    "21543": "всходы",
    "21544": "всхрап",
    "21545": "всыпка",
    "21546": "всюду",
    "21551": "вся",
    "21552": "всяк",
    "21553": "всякий",
    "21554": "всяко",
    "21555": "всё",
    "21556": "втайне",
    "21561": "втачка",
    "21562": "втечь",
    "21563": "втихую",
    "21564": "втора",
    "21565": "вторая",
    "21566": "второе",
    "21611": "второй",
    "21612": "втрое",
    "21613": "втроём",
    "21614": "втулка",
    "21615": "втуне",
    "21616": "вуаль",
    "21621": "вулкан",
    "21622": "вход",
    "21623": "вхожий",
    "21624": "вчера",
    "21625": "вчерне",
    "21626": "вчуже",
    "21631": "вша",
    "21632": "вшивец",
    "21633": "вшивка",
    "21634": "вшивый",
    "21635": "вширь",
    "21636": "вшитый",
    "21641": "вшить",
    "21642": "въезд",
    "21643": "въяве",
    "21644": "въявь",
    "21645": "выбег",
    "21646": "выбить",
    "21651": "выбор",
    "21652": "выборы",
    "21653": "выброс",
    "21654": "выбыть",
    "21655": "выверт",
    "21656": "вывить",
    "21661": "вывих",
    "21662": "вывод",
    "21663": "вывоз",
    "21664": "выгиб",
    "21665": "выгода",
    "21666": "выгон",
    "22111": "выгреб",
    "22112": "выгул",
    "22113": "выдать",
    "22114": "выдача",
    "22115": "выдел",
    "22116": "выдох",
    "22121": "выдра",
    "22122": "выдрий",
    "22123": "выдуть",
    "22124": "выезд",
    "22125": "выем",
    "22126": "выемка",
    "22131": "выесть",
    "22132": "выжать",
    "22133": "выжечь",
    "22134": "выжиг",
    "22135": "выжига",
    "22136": "выжим",
    "22141": "выжить",
    "22142": "выжлец",
    "22143": "вызов",
    "22144": "вызол",
    "22145": "выйти",
    "22146": "выкат",
    "22151": "выкать",
    "22152": "выклик",
    "22153": "выкорм",
    "22154": "выкос",
    "22155": "выкрик",
    "22156": "выкуп",
    "22161": "выкус",
    "22162": "вылаз",
    "22163": "вылет",
    "22164": "вылечь",
    "22165": "вылить",
    "22166": "вылов",
    "22211": "вылом",
    "22212": "вымпел",
    "22213": "вымыть",
    "22214": "вымя",
    "22215": "вымять",
    "22216": "вынос",
    "22221": "вынуть",
    "22222": "выпад",
    "22223": "выпас",
    "22224": "выпеть",
    "22225": "выпечь",
    "22226": "выпить",
    "22231": "выпор",
    "22232": "выпот",
    "22233": "выпуск",
    "22234": "выпь",
    "22235": "вырез",
    "22236": "вырост",
    "22241": "вырыть",
    "22242": "высев",
    "22243": "высечь",
    "22244": "высоко",
    "22245": "высота",
    "22246": "выступ",
    "22251": "высший",
    "22252": "высь",
    "22253": "вытечь",
    "22254": "выть",
    "22255": "вытьё",
    "22256": "выучка",
    "22261": "выхлоп",
    "22262": "выход",
    "22263": "вычет",
    "22264": "вычуры",
    "22265": "выше",
    "22266": "вышина",
    "22311": "вышить",
    "22312": "вышка",
    "22313": "вышний",
    "22314": "выя",
    "22315": "вьюга",
    "22316": "вьюк",
    "22321": "вьюн",
    "22322": "вьюнок",
    "22323": "вьюрок",
    "22324": "вьюшка",
    "22325": "вяз",
    "22326": "вязать",
    "22331": "вязель",
    "22332": "вязка",
    "22333": "вязкий",
    "22334": "вязче",
    "22335": "вязчик",
    "22336": "вязь",
    "22341": "основа",
    "22342": "вялить",
    "22343": "вяло",
    "22344": "вялый",
    "22345": "вянуть",
    "22346": "вятичи",
    "22351": "вяхирь",
    "22352": "вящий",
    "22353": "вёдро",
    "22354": "габбро",
    "22355": "гаваец",
    "22356": "гавань",
    "22361": "гавот",
    "22362": "гага",
    "22363": "гагара",
    "22364": "гагат",
    "22365": "гагауз",
    "22366": "гад",
    "22411": "гадать",
    "22412": "корова",
    "22413": "электробритва",
    "22414": "плотва",
    "22415": "средства",
    "22416": "гадюка",
    "22421": "гаер",
    "22422": "газ",
    "22423": "газель",
    "22424": "газета",
    "22425": "газик",
    "22426": "газон",
    "22431": "газыри",
    "22432": "гай",
    "22433": "гайдук",
    "22434": "гайка",
    "22435": "гак",
    "22436": "галдёж",
    "22441": "галера",
    "22442": "галета",
    "22443": "галифе",
    "22444": "галка",
    "22445": "галл",
    "22446": "галлий",
    "22451": "галлон",
    "22452": "гало",
    "22453": "галоид",
    "22454": "галоп",
    "22455": "галоша",
    "22456": "галс",
    "22461": "галун",
    "22462": "галька",
    "22463": "гальюн",
    "22464": "гам",
    "22465": "гамак",
    "22466": "гамаши",
    "22511": "гамбит",
    "22512": "гамма",
    "22513": "ганец",
    "22514": "ганка",
    "22515": "гаолян",
    "22516": "гараж",
    "22521": "гарант",
    "22522": "гарем",
    "22523": "гарнец",
    "22524": "гарнир",
    "22525": "гарный",
    "22526": "гарпия",
    "22531": "гарпун",
    "22532": "гарсон",
    "22533": "гарт",
    "22534": "гарус",
    "22535": "гарь",
    "22536": "листва",
    "22541": "гатить",
    "22542": "гать",
    "22543": "гафель",
    "22544": "гафний",
    "22545": "подошва",
    "22546": "гвалт",
    "22551": "гваюла",
    "22552": "гвоздь",
    "22553": "где",
    "22554": "геенна",
    "22555": "колымага",
    "22556": "гейзер",
    "22561": "гейм",
    "22562": "гейша",
    "22563": "гексан",
    "22564": "гектар",
    "22565": "гелий",
    "22566": "гемма",
    "22611": "ген",
    "22612": "гений",
    "22613": "генный",
    "22614": "геолог",
    "22615": "геофон",
    "22616": "гепард",
    "22621": "герань",
    "22622": "герб",
    "22623": "герма",
    "22624": "курага",
    "22625": "герой",
    "22626": "герц",
    "22631": "герцог",
    "22632": "гетера",
    "22633": "гетман",
    "22634": "гетры",
    "22635": "гетто",
    "22636": "гешефт",
    "22641": "гиббон",
    "22642": "полшага",
    "22643": "гибка",
    "22644": "гибкий",
    "22645": "гибко",
    "22646": "телега",
    "22651": "гибрид",
    "22652": "гигант",
    "22653": "гид",
    "22654": "гидра",
    "22655": "гидрат",
    "22656": "гидрид",
    "22661": "гиена",
    "22662": "гик",
    "22663": "гикать",
    "22664": "гильза",
    "22665": "гиляк",
    "22666": "гимн",
    "23111": "гинея",
    "23112": "гинкго",
    "23113": "гинуть",
    "23114": "гипноз",
    "23115": "гипс",
    "23116": "гипюр",
    "23121": "гирло",
    "23122": "гиря",
    "23123": "гит",
    "23124": "гитан",
    "23125": "гитана",
    "23126": "гитара",
    "23131": "гичка",
    "23132": "глава",
    "23133": "главк",
    "23134": "глагол",
    "23135": "гладко",
    "23136": "гладыш",
    "23141": "гладь",
    "23142": "глаже",
    "23143": "глаз",
    "23144": "глазет",
    "23145": "глазик",
    "23146": "глазок",
    "23151": "гланды",
    "23152": "глас",
    "23153": "гласно",
    "23154": "глей",
    "23155": "глина",
    "23156": "глинка",
    "23161": "глипт",
    "23162": "коллега",
    "23163": "интрига",
    "23164": "глицин",
    "23165": "глобин",
    "23166": "глобус",
    "23211": "глог",
    "23212": "глосса",
    "23213": "глотка",
    "23214": "глоток",
    "23215": "глубже",
    "23216": "глубь",
    "23221": "глум",
    "23222": "волга",
    "23223": "фаланга",
    "23224": "штанга",
    "23225": "шеренга",
    "23226": "глухая",
    "23231": "глухо",
    "23232": "глухой",
    "23233": "глуше",
    "23234": "глушь",
    "23235": "глыба",
    "23236": "глядь",
    "23241": "глянец",
    "23242": "глясе",
    "23243": "глёт",
    "23244": "гмина",
    "23245": "гнать",
    "23246": "гнев",
    "23251": "гневно",
    "23252": "гнедой",
    "23253": "гнездо",
    "23254": "гнейс",
    "23255": "гнести",
    "23256": "тревога",
    "23261": "берлога",
    "23262": "подмога",
    "23263": "рога",
    "23264": "кочерга",
    "23265": "радуга",
    "23266": "заслуга",
    "23311": "прислуга",
    "23312": "услуга",
    "23313": "гном",
    "23314": "гнома",
    "23315": "гномон",
    "23316": "гну",
    "23321": "подруга",
    "23322": "полкруга",
    "23323": "округа",
    "23324": "гнуть",
    "23325": "гнутьё",
    "23326": "гнёт",
    "23331": "гоби",
    "23332": "гобой",
    "23333": "говеть",
    "23334": "говор",
    "23335": "гоголь",
    "23336": "гогот",
    "23341": "год",
    "23342": "годами",
    "23343": "годик",
    "23344": "година",
    "23345": "годить",
    "23346": "годный",
    "23351": "годок",
    "23352": "гой",
    "23353": "гол",
    "23354": "голень",
    "23355": "голец",
    "23356": "голиаф",
    "23361": "голик",
    "23362": "голица",
    "23363": "голова",
    "23364": "голод",
    "23365": "голос",
    "23366": "голубь",
    "23411": "супруга",
    "23412": "кольчуга",
    "23413": "голь",
    "23414": "гольд",
    "23415": "гольф",
    "23416": "гольфы",
    "23421": "гольцы",
    "23422": "гольян",
    "23423": "голядь",
    "23424": "мотыга",
    "23425": "гоммоз",
    "23426": "гомон",
    "23431": "гон",
    "23432": "гонвед",
    "23433": "гонг",
    "23434": "гонец",
    "23435": "гонка",
    "23436": "гонки",
    "23441": "гонкий",
    "23442": "гонок",
    "23443": "гонор",
    "23444": "гонт",
    "23445": "гончар",
    "23446": "гончая",
    "23451": "гончий",
    "23452": "гонщик",
    "23453": "гоньба",
    "23454": "гонять",
    "23455": "гоп",
    "23456": "гопак",
    "23461": "гопля",
    "23462": "гора",
    "23463": "горазд",
    "23464": "горал",
    "23465": "горб",
    "23466": "фольга",
    "23511": "горбик",
    "23512": "горбок",
    "23513": "горбун",
    "23514": "гордец",
    "23515": "гордо",
    "23516": "гордый",
    "23521": "горе",
    "23522": "гореть",
    "23523": "горец",
    "23524": "горечь",
    "23525": "горка",
    "23526": "горком",
    "23531": "серьга",
    "23532": "горлач",
    "23533": "горло",
    "23534": "гормон",
    "23535": "горн",
    "23536": "горный",
    "23541": "горняк",
    "23542": "город",
    "23543": "гороно",
    "23544": "горох",
    "23545": "горст",
    "23546": "горсть",
    "23551": "горче",
    "23552": "горше",
    "23553": "горший",
    "23554": "горшок",
    "23555": "горько",
    "23556": "горюн",
    "23561": "горячо",
    "23562": "гост",
    "23563": "гость",
    "23564": "гостья",
    "23565": "госхоз",
    "23566": "гот",
    "23611": "готика",
    "23612": "готово",
    "23613": "готы",
    "23614": "гофр",
    "23615": "граб",
    "23616": "грабли",
    "23621": "грабёж",
    "23622": "гравер",
    "23623": "гравий",
    "23624": "гравёр",
    "23625": "град",
    "23626": "градом",
    "23631": "градус",
    "23632": "грай",
    "23633": "грамм",
    "23634": "гран",
    "23635": "гранат",
    "23636": "гранд",
    "23641": "гранит",
    "23642": "гранка",
    "23643": "грань",
    "23644": "граф",
    "23645": "графа",
    "23646": "график",
    "23651": "графин",
    "23652": "графит",
    "23653": "грация",
    "23654": "грач",
    "23655": "гребец",
    "23656": "гребля",
    "23661": "гребок",
    "23662": "грек",
    "23663": "грелка",
    "23664": "грена",
    "23665": "гренаж",
    "23666": "гренок",
    "24111": "грести",
    "24112": "греть",
    "24113": "бродяга",
    "24114": "греча",
    "24115": "гречка",
    "24116": "дворняга",
    "24121": "присяга",
    "24122": "гриб",
    "24123": "грибок",
    "24124": "грива",
    "24125": "гривка",
    "24126": "гривна",
    "24131": "гридь",
    "24132": "гризли",
    "24133": "грим",
    "24134": "гримёр",
    "24135": "грипп",
    "24136": "гриф",
    "24141": "грифон",
    "24142": "бригада",
    "24143": "грог",
    "24144": "гроза",
    "24145": "гроздь",
    "24146": "грозно",
    "24151": "гром",
    "24152": "громко",
    "24153": "громче",
    "24154": "гросс",
    "24155": "грот",
    "24156": "грох",
    "24161": "грохот",
    "24162": "грош",
    "24163": "гроши",
    "24164": "грубее",
    "24165": "грубо",
    "24166": "грубый",
    "24211": "груда",
    "24212": "грудка",
    "24213": "олимпиада",
    "24214": "груз",
    "24215": "груздь",
    "24216": "грузин",
    "24221": "грузно",
    "24222": "грум",
    "24223": "грунт",
    "24224": "группа",
    "24225": "грусть",
    "24226": "груша",
    "24231": "эстакада",
    "24232": "баррикада",
    "24233": "блокада",
    "24234": "грызть",
    "24235": "грызун",
    "24236": "гряда",
    "24241": "грядка",
    "24242": "грязи",
    "24243": "грязно",
    "24244": "грязь",
    "24245": "грясти",
    "24246": "грёза",
    "24251": "гуано",
    "24252": "гуашь",
    "24253": "губа",
    "24254": "губан",
    "24255": "губить",
    "24256": "губка",
    "24261": "губной",
    "24262": "гугу",
    "24263": "гуд",
    "24264": "гудеть",
    "24265": "гудок",
    "24266": "гудрон",
    "24311": "гуж",
    "24312": "гужом",
    "24313": "гузка",
    "24314": "гукать",
    "24315": "гул",
    "24316": "гули",
    "24321": "гулкий",
    "24322": "гулко",
    "24323": "гульба",
    "24324": "гуля",
    "24325": "гуляка",
    "24326": "гулять",
    "24331": "гуляш",
    "24332": "гулёна",
    "24333": "гумма",
    "24334": "гумми",
    "24335": "гуммит",
    "24336": "гуммоз",
    "24341": "гумно",
    "24342": "гумус",
    "24343": "гунны",
    "24344": "гуппи",
    "24345": "гураль",
    "24346": "гуриец",
    "24351": "гурия",
    "24352": "гурман",
    "24353": "гурт",
    "24354": "гуртом",
    "24355": "гурьба",
    "24356": "гусак",
    "24361": "гусар",
    "24362": "гусит",
    "24363": "гусли",
    "24364": "гусляр",
    "24365": "густо",
    "24366": "густой",
    "24411": "гусыня",
    "24412": "гусь",
    "24413": "гута",
    "24414": "гуцул",
    "24415": "гуща",
    "24416": "гуще",
    "24421": "гущина",
    "24422": "гюйс",
    "24423": "гюрза",
    "24424": "гяур",
    "24425": "гёзы",
    "24426": "дабы",
    "24431": "давать",
    "24432": "давеча",
    "24433": "давило",
    "24434": "давить",
    "24435": "давка",
    "24436": "давний",
    "24441": "давно",
    "24442": "даже",
    "24443": "дакать",
    "24444": "дакрон",
    "24445": "далее",
    "24446": "далеко",
    "24451": "далече",
    "24452": "даль",
    "24453": "дальше",
    "24454": "далёко",
    "24455": "дама",
    "24456": "дамба",
    "24461": "дамка",
    "24462": "данаец",
    "24463": "данник",
    "24464": "данные",
    "24465": "данный",
    "24466": "дань",
    "24511": "дар",
    "24512": "дарить",
    "24513": "даром",
    "24514": "дата",
    "24515": "датчик",
    "24516": "дать",
    "24521": "даться",
    "24522": "дафния",
    "24523": "дача",
    "24524": "дачник",
    "24525": "дачный",
    "24526": "даяние",
    "24531": "два",
    "24532": "дважды",
    "24533": "две",
    "24534": "дверка",
    "24535": "дверца",
    "24536": "дверь",
    "24541": "двести",
    "24542": "движок",
    "24543": "двое",
    "24544": "двоить",
    "24545": "двойка",
    "24546": "двойня",
    "24551": "двор",
    "24552": "дворец",
    "24553": "дворня",
    "24554": "двояко",
    "24555": "дебаты",
    "24556": "дебет",
    "24561": "дебит",
    "24562": "дебош",
    "24563": "дебри",
    "24564": "дебют",
    "24565": "дева",
    "24566": "девать",
    "24611": "деверь",
    "24612": "девиз",
    "24613": "девиза",
    "24614": "лада",
    "24615": "баллада",
    "24616": "девон",
    "24621": "девять",
    "24622": "дед",
    "24623": "дедина",
    "24624": "дедка",
    "24625": "дежа",
    "24626": "деизм",
    "24631": "деист",
    "24632": "дек",
    "24633": "дека",
    "24634": "декада",
    "24635": "декан",
    "24636": "декель",
    "24641": "декокт",
    "24642": "деколь",
    "24643": "декор",
    "24644": "декрет",
    "24645": "делать",
    "24646": "делец",
    "24651": "делить",
    "24652": "дело",
    "24653": "дельно",
    "24654": "дельта",
    "24655": "дельце",
    "24656": "деляга",
    "24661": "делёж",
    "24662": "демарш",
    "24663": "демон",
    "24664": "демос",
    "24665": "денди",
    "24666": "денник",
    "25111": "денно",
    "25112": "дентин",
    "25113": "денщик",
    "25114": "день",
    "25115": "деньга",
    "25116": "деньги",
    "25121": "денёк",
    "25122": "депеша",
    "25123": "депо",
    "25124": "депорт",
    "25125": "дерби",
    "25126": "дервиш",
    "25131": "дергач",
    "25132": "дерево",
    "25133": "дереза",
    "25134": "держак",
    "25135": "дерзко",
    "25136": "дерма",
    "25141": "деррик",
    "25142": "дерть",
    "25143": "прохлада",
    "25144": "дерюга",
    "25145": "деряба",
    "25146": "десант",
    "25151": "десерт",
    "25152": "десна",
    "25153": "деспот",
    "25154": "десть",
    "25155": "десять",
    "25156": "деталь",
    "25161": "детант",
    "25162": "детва",
    "25163": "детдом",
    "25164": "дети",
    "25165": "детина",
    "25166": "детище",
    "25211": "детка",
    "25212": "детки",
    "25213": "детрит",
    "25214": "детсад",
    "25215": "деть",
    "25216": "деться",
    "25221": "дефект",
    "25222": "дефиле",
    "25223": "дефис",
    "25224": "децима",
    "25225": "дечный",
    "25226": "деяние",
    "25231": "джаз",
    "25232": "джем",
    "25233": "джерси",
    "25234": "джига",
    "25235": "джигит",
    "25236": "джин",
    "25241": "джинн",
    "25242": "джинсы",
    "25243": "джип",
    "25244": "джокер",
    "25245": "джонка",
    "25246": "джоуль",
    "25251": "джунта",
    "25252": "джут",
    "25253": "дзинь",
    "25254": "дзот",
    "25255": "дзюдо",
    "25256": "диабаз",
    "25261": "диабет",
    "25262": "диакон",
    "25263": "диализ",
    "25264": "диалог",
    "25265": "диамат",
    "25266": "диамин",
    "25311": "диатез",
    "25312": "див",
    "25313": "дива",
    "25314": "дивала",
    "25315": "диван",
    "25316": "дивить",
    "25321": "дивно",
    "25322": "дивный",
    "25323": "диво",
    "25324": "диез",
    "25325": "диета",
    "25326": "диетик",
    "25331": "дизайн",
    "25332": "дизель",
    "25333": "дикарь",
    "25334": "дикий",
    "25335": "дико",
    "25336": "диктат",
    "25341": "диктор",
    "25342": "дикуша",
    "25343": "дикция",
    "25344": "дина",
    "25345": "динамо",
    "25346": "динар",
    "25351": "динас",
    "25352": "динго",
    "25353": "диод",
    "25354": "дионин",
    "25355": "диоптр",
    "25356": "диорит",
    "25361": "диплом",
    "25362": "диполь",
    "25363": "диптих",
    "25364": "дирхем",
    "25365": "диск",
    "25366": "диспут",
    "25411": "дистих",
    "25412": "дитя",
    "25413": "дичать",
    "25414": "дичина",
    "25415": "дичок",
    "25416": "дичь",
    "25421": "длань",
    "25422": "длина",
    "25423": "длинно",
    "25424": "для",
    "25425": "днище",
    "25426": "дно",
    "25431": "днями",
    "25432": "днёвка",
    "25433": "днём",
    "25434": "добела",
    "25435": "добить",
    "25436": "добор",
    "25441": "добрее",
    "25442": "добро",
    "25443": "добром",
    "25444": "добрый",
    "25445": "добряк",
    "25446": "добыть",
    "25451": "добыча",
    "25452": "довеку",
    "25453": "довить",
    "25454": "довод",
    "25455": "дог",
    "25456": "догма",
    "25461": "догмат",
    "25462": "догола",
    "25463": "додать",
    "25464": "додача",
    "25465": "доение",
    "25466": "доесть",
    "25511": "дож",
    "25512": "дожать",
    "25513": "дождик",
    "25514": "дождь",
    "25515": "дожечь",
    "25516": "дожить",
    "25521": "тамада",
    "25522": "дозор",
    "25523": "доить",
    "25524": "дойка",
    "25525": "дойна",
    "25526": "дойник",
    "25531": "дойный",
    "25532": "дойти",
    "25533": "док",
    "25534": "дока",
    "25535": "докер",
    "25536": "доклад",
    "25541": "доколе",
    "25542": "доколь",
    "25543": "доктор",
    "25544": "докуда",
    "25545": "докука",
    "25546": "дол",
    "25551": "долбня",
    "25552": "долбяк",
    "25553": "долг",
    "25554": "долган",
    "25555": "долгий",
    "25556": "долго",
    "25561": "долее",
    "25562": "должен",
    "25563": "должно",
    "25564": "должок",
    "25565": "долина",
    "25566": "долить",
    "25611": "доллар",
    "25612": "долой",
    "25613": "долото",
    "25614": "долу",
    "25615": "долька",
    "25616": "дольше",
    "25621": "доля",
    "25622": "дом",
    "25623": "дома",
    "25624": "домбра",
    "25625": "домен",
    "25626": "домик",
    "25631": "помада",
    "25632": "домино",
    "25633": "домище",
    "25634": "домком",
    "25635": "домна",
    "25636": "домой",
    "25641": "домок",
    "25642": "домра",
    "25643": "домыть",
    "25644": "домять",
    "25645": "дон",
    "25646": "донага",
    "25651": "донг",
    "25652": "донец",
    "25653": "донизу",
    "25654": "донна",
    "25655": "донник",
    "25656": "донный",
    "25661": "донор",
    "25662": "донос",
    "25663": "донце",
    "25664": "дончак",
    "25665": "доныне",
    "25666": "донья",
    "26111": "донять",
    "26112": "допеть",
    "26113": "допечь",
    "26114": "допинг",
    "26115": "допить",
    "26116": "допрос",
    "26121": "допуск",
    "26122": "дормез",
    "26123": "дорога",
    "26124": "дорого",
    "26125": "дороже",
    "26126": "дорыть",
    "26131": "досада",
    "26132": "доселе",
    "26133": "досель",
    "26134": "досиня",
    "26135": "доска",
    "26136": "досол",
    "26141": "доступ",
    "26142": "громада",
    "26143": "досуха",
    "26144": "досыта",
    "26145": "досье",
    "26146": "досюда",
    "26151": "дот",
    "26152": "дотечь",
    "26153": "дотла",
    "26154": "дотоле",
    "26155": "дотоль",
    "26156": "дотуда",
    "26161": "дофин",
    "26162": "доха",
    "26163": "серенада",
    "26164": "клоунада",
    "26165": "доход",
    "26166": "доцент",
    "26211": "дочка",
    "26212": "дочкин",
    "26213": "дочь",
    "26214": "дошить",
    "26215": "дошлый",
    "26216": "дояр",
    "26221": "доярка",
    "26222": "доёный",
    "26223": "дравид",
    "26224": "драга",
    "26225": "драгун",
    "26226": "драже",
    "26231": "драить",
    "26232": "драйв",
    "26233": "драка",
    "26234": "дракон",
    "26235": "драла",
    "26236": "драма",
    "26241": "дранка",
    "26242": "награда",
    "26243": "преграда",
    "26244": "ограда",
    "26245": "драп",
    "26246": "драпри",
    "26251": "дратва",
    "26252": "автострада",
    "26253": "драхма",
    "26254": "эстрада",
    "26255": "рассада",
    "26256": "пощада",
    "26261": "древко",
    "26262": "древо",
    "26263": "дрейф",
    "26264": "дрель",
    "26265": "дрема",
    "26266": "дрена",
    "26311": "дренаж",
    "26312": "дресва",
    "26313": "дриада",
    "26314": "дробно",
    "26315": "дробь",
    "26316": "дрова",
    "26321": "дровни",
    "26322": "дрога",
    "26323": "дроги",
    "26324": "дрожжи",
    "26325": "дрожки",
    "26326": "дрожь",
    "26331": "дрозд",
    "26332": "дрок",
    "26333": "дротик",
    "26334": "дрофа",
    "26335": "друг",
    "26336": "другие",
    "26341": "другое",
    "26342": "другой",
    "26343": "дружба",
    "26344": "дружка",
    "26345": "дружно",
    "26346": "дружок",
    "26351": "друза",
    "26352": "друид",
    "26353": "дрюк",
    "26354": "дрючок",
    "26355": "дрязги",
    "26356": "дрянцо",
    "26361": "правда",
    "26362": "дрёма",
    "26363": "дуайен",
    "26364": "дуб",
    "26365": "дубина",
    "26366": "дубить",
    "26411": "дубка",
    "26412": "дублет",
    "26413": "дублон",
    "26414": "дубль",
    "26415": "дубляж",
    "26416": "дублёр",
    "26421": "дубняк",
    "26422": "дубок",
    "26423": "дубьё",
    "26424": "дуга",
    "26425": "дуда",
    "26426": "дудак",
    "26431": "дудеть",
    "26432": "дудка",
    "26433": "дудки",
    "26434": "дудник",
    "26435": "дудук",
    "26436": "дужка",
    "26441": "дукат",
    "26442": "дулебы",
    "26443": "дуло",
    "26444": "дуля",
    "26445": "дума",
    "26446": "думать",
    "26451": "думец",
    "26452": "думка",
    "26453": "думный",
    "26454": "думпер",
    "26455": "неправда",
    "26456": "дупель",
    "26461": "дуплет",
    "26462": "дупло",
    "26463": "навсегда",
    "26464": "некогда",
    "26465": "никогда",
    "26466": "победа",
    "26511": "торпеда",
    "26512": "привереда",
    "26513": "череда",
    "26514": "непоседа",
    "26515": "невежда",
    "26516": "надежда",
    "26521": "одежда",
    "26522": "спецодежда",
    "26523": "кинозвезда",
    "26524": "дуст",
    "26525": "дутар",
    "26526": "дутый",
    "26531": "дутыш",
    "26532": "дуть",
    "26533": "дуться",
    "26534": "дутьё",
    "26535": "дух",
    "26536": "духан",
    "26541": "духи",
    "26542": "пирамида",
    "26543": "дуче",
    "26544": "душ",
    "26545": "душа",
    "26546": "пропаганда",
    "26551": "душица",
    "26552": "душка",
    "26553": "душник",
    "26554": "душно",
    "26555": "душный",
    "26556": "душок",
    "26561": "дуэль",
    "26562": "дуэнья",
    "26563": "дуэт",
    "26564": "дыба",
    "26565": "дыбом",
    "26566": "дыбы",
    "26611": "команда",
    "26612": "дым",
    "26613": "дымарь",
    "26614": "дымить",
    "26615": "дымка",
    "26616": "дымно",
    "26621": "дымный",
    "26622": "дымок",
    "26623": "дынный",
    "26624": "дыня",
    "26625": "панда",
    "26626": "веранда",
    "26631": "дышать",
    "26632": "дышло",
    "26633": "легенда",
    "26634": "дьяк",
    "26635": "дьякон",
    "26636": "дьячок",
    "26641": "дюбек",
    "26642": "дюгонь",
    "26643": "дюжий",
    "26644": "дюжина",
    "26645": "дюйм",
    "26646": "дюкер",
    "26651": "дюна",
    "26652": "дюнный",
    "26653": "дюраль",
    "26654": "дюшес",
    "26655": "дягиль",
    "26656": "дядин",
    "26661": "дядька",
    "26662": "дядя",
    "26663": "дятел",
    "26664": "дёготь",
    "26665": "дёр",
    "26666": "дёрг",
    "31111": "дёрен",
    "31112": "дёрка",
    "31113": "дёрн",
    "31114": "дёшево",
    "31115": "секунда",
    "31116": "еврей",
    "31121": "егерь",
    "31122": "его",
    "31123": "егоза",
    "31124": "еда",
    "31125": "едать",
    "31126": "едва",
    "31131": "единый",
    "31132": "едкий",
    "31133": "едко",
    "31134": "едок",
    "31135": "едун",
    "31136": "ежа",
    "31141": "ежели",
    "31142": "ежиха",
    "31143": "ежовый",
    "31144": "ежонок",
    "31145": "езда",
    "31146": "ездить",
    "31151": "ездка",
    "31152": "ездок",
    "31153": "езжать",
    "31154": "еканье",
    "31155": "екать",
    "31156": "еле",
    "31161": "елей",
    "31162": "елейно",
    "31163": "елец",
    "31164": "елико",
    "31165": "еловый",
    "31166": "ель",
    "31211": "ельник",
    "31212": "ему",
    "31213": "ендова",
    "31214": "енот",
    "31215": "епанча",
    "31216": "ералаш",
    "31221": "ересь",
    "31222": "свобода",
    "31223": "ерик",
    "31224": "ерник",
    "31225": "ерунда",
    "31226": "ершить",
    "31231": "еры",
    "31232": "ерь",
    "31233": "есаул",
    "31234": "если",
    "31235": "есть",
    "31236": "есться",
    "31241": "ехать",
    "31242": "ехида",
    "31243": "ехидна",
    "31244": "ехидно",
    "31245": "ещё",
    "31246": "жаба",
    "31251": "жабий",
    "31252": "жабник",
    "31253": "жабо",
    "31254": "жабрей",
    "31255": "жабры",
    "31256": "жавель",
    "31261": "жадеит",
    "31262": "жадина",
    "31263": "жадно",
    "31264": "жадный",
    "31265": "полгода",
    "31266": "жажда",
    "31311": "жакан",
    "31312": "жакет",
    "31313": "жако",
    "31314": "жакоб",
    "31315": "жакт",
    "31316": "жалеть",
    "31321": "жалить",
    "31322": "жалкий",
    "31323": "жалко",
    "31324": "жало",
    "31325": "жалоба",
    "31326": "жаль",
    "31331": "жальце",
    "31332": "жалюзи",
    "31333": "жанр",
    "31334": "жар",
    "31335": "жара",
    "31336": "жаргон",
    "31341": "жарить",
    "31342": "жарища",
    "31343": "жаркий",
    "31344": "жарко",
    "31345": "жаркое",
    "31346": "жарынь",
    "31351": "жасмин",
    "31352": "жатва",
    "31353": "жатка",
    "31354": "жать",
    "31355": "жаться",
    "31356": "погода",
    "31361": "жбан",
    "31362": "жвало",
    "31363": "жвачка",
    "31364": "жгут",
    "31365": "жгутик",
    "31366": "жгучий",
    "31411": "ждать",
    "31412": "жевать",
    "31413": "жезл",
    "31414": "желать",
    "31415": "желвак",
    "31416": "желе",
    "31421": "железа",
    "31422": "железо",
    "31423": "желна",
    "31424": "желток",
    "31425": "желть",
    "31426": "желтяк",
    "31431": "жемчуг",
    "31432": "жена",
    "31433": "женин",
    "31434": "женить",
    "31435": "жених",
    "31436": "жердь",
    "31441": "жерех",
    "31442": "жерло",
    "31443": "непогода",
    "31444": "жеруха",
    "31445": "жест",
    "31446": "невыгода",
    "31451": "жетон",
    "31452": "жечь",
    "31453": "жечься",
    "31454": "жжение",
    "31455": "жжёнка",
    "31456": "жжёный",
    "31461": "живее",
    "31462": "живец",
    "31463": "живить",
    "31464": "живица",
    "31465": "живо",
    "31466": "живой",
    "31511": "живот",
    "31512": "живчик",
    "31513": "живьём",
    "31514": "жига",
    "31515": "жидкий",
    "31516": "жидко",
    "31521": "жижа",
    "31522": "жиже",
    "31523": "жижица",
    "31524": "жизнь",
    "31525": "жиклёр",
    "31526": "жила",
    "31531": "жилет",
    "31532": "жилец",
    "31533": "жилица",
    "31534": "жилище",
    "31535": "жилка",
    "31536": "жилой",
    "31541": "жильё",
    "31542": "жим",
    "31543": "жир",
    "31544": "жираф",
    "31545": "жирафа",
    "31546": "жиреть",
    "31551": "жирнее",
    "31552": "жирно",
    "31553": "жирный",
    "31554": "жиро",
    "31555": "жирок",
    "31556": "житель",
    "31561": "житие",
    "31562": "житник",
    "31563": "житный",
    "31564": "житняк",
    "31565": "жито",
    "31566": "жить",
    "31611": "житься",
    "31612": "житьё",
    "31613": "природа",
    "31614": "жмудь",
    "31615": "сковорода",
    "31616": "жмых",
    "31621": "жмыхи",
    "31622": "жнейка",
    "31623": "порода",
    "31624": "жнея",
    "31625": "жнива",
    "31626": "жниво",
    "31631": "жнивьё",
    "31632": "жнитво",
    "31633": "жница",
    "31634": "жок",
    "31635": "жокей",
    "31636": "жом",
    "31641": "жор",
    "31642": "жох",
    "31643": "петарда",
    "31644": "чехарда",
    "31645": "жребий",
    "31646": "жрец",
    "31651": "жрица",
    "31652": "жуир",
    "31653": "жук",
    "31654": "жулан",
    "31655": "некуда",
    "31656": "откуда",
    "31661": "жупан",
    "31662": "жупел",
    "31663": "журить",
    "31664": "журка",
    "31665": "журнал",
    "31666": "журьба",
    "32111": "неоткуда",
    "32112": "ниоткуда",
    "32113": "посуда",
    "32114": "жухлый",
    "32115": "жучить",
    "32116": "жучок",
    "32121": "жюри",
    "32122": "жёлоб",
    "32123": "жёлтый",
    "32124": "жёлудь",
    "32125": "жёлчно",
    "32126": "жёлчь",
    "32131": "жёрнов",
    "32132": "ссуда",
    "32133": "амплитуда",
    "32134": "забава",
    "32135": "забег",
    "32136": "забить",
    "32141": "забой",
    "32142": "забор",
    "32143": "забота",
    "32144": "заброс",
    "32145": "забыть",
    "32146": "зав",
    "32151": "завал",
    "32152": "заваль",
    "32153": "завгар",
    "32154": "завеса",
    "32155": "простуда",
    "32156": "завить",
    "32161": "завком",
    "32162": "завлит",
    "32163": "завмаг",
    "32164": "завод",
    "32165": "заводь",
    "32166": "завоз",
    "32211": "завтра",
    "32212": "завуч",
    "32213": "завхоз",
    "32214": "завыть",
    "32215": "завязь",
    "32216": "загар",
    "32221": "загиб",
    "32222": "загодя",
    "32223": "загон",
    "32224": "загул",
    "32225": "оттуда",
    "32226": "задать",
    "32231": "задача",
    "32232": "задел",
    "32233": "задеть",
    "32234": "задира",
    "32235": "задний",
    "32236": "задник",
    "32241": "причуда",
    "32242": "отсюда",
    "32243": "задор",
    "32244": "задуть",
    "32245": "продажа",
    "32246": "заезд",
    "32251": "заесть",
    "32252": "зажать",
    "32253": "зажечь",
    "32254": "перепродажа",
    "32255": "зажим",
    "32256": "зажин",
    "32261": "зажить",
    "32262": "зажор",
    "32263": "зажора",
    "32264": "зазор",
    "32265": "распродажа",
    "32266": "заимка",
    "32311": "заир",
    "32312": "заирец",
    "32313": "заирка",
    "32314": "зайка",
    "32315": "зайти",
    "32316": "зайчик",
    "32321": "заказ",
    "32322": "закал",
    "32323": "закат",
    "32324": "закись",
    "32325": "заклад",
    "32326": "закон",
    "32331": "закрой",
    "32332": "закром",
    "32333": "закуп",
    "32334": "закут",
    "32335": "закута",
    "32336": "зал",
    "32341": "зала",
    "32342": "залежи",
    "32343": "залежь",
    "32344": "залечь",
    "32345": "залив",
    "32346": "залить",
    "32351": "залог",
    "32352": "залом",
    "32353": "залп",
    "32354": "залпом",
    "32355": "стража",
    "32356": "зам",
    "32361": "замах",
    "32362": "замена",
    "32363": "замер",
    "32364": "госпожа",
    "32365": "замета",
    "32366": "заметь",
    "32411": "замок",
    "32412": "замор",
    "32413": "замуж",
    "32414": "замша",
    "32415": "замыть",
    "32416": "замять",
    "32421": "замёт",
    "32422": "заново",
    "32423": "авиабаза",
    "32424": "занос",
    "32425": "нефтебаза",
    "32426": "занять",
    "32431": "заодно",
    "32432": "заочно",
    "32433": "запад",
    "32434": "запал",
    "32435": "запань",
    "32436": "запас",
    "32441": "запах",
    "32442": "запев",
    "32443": "запеть",
    "32444": "запечь",
    "32445": "запись",
    "32446": "запить",
    "32451": "заплыв",
    "32452": "агробаза",
    "32453": "автобаза",
    "32454": "турбаза",
    "32455": "запрет",
    "32456": "запрос",
    "32461": "запуск",
    "32462": "глаза",
    "32463": "гипотеза",
    "32464": "зарево",
    "32465": "экспертиза",
    "32466": "зарок",
    "32511": "зарука",
    "32512": "стрекоза",
    "32513": "заря",
    "32514": "заряд",
    "32515": "засада",
    "32516": "засев",
    "32521": "засека",
    "32522": "засечь",
    "32523": "засим",
    "32524": "заскок",
    "32525": "заслон",
    "32526": "засов",
    "32531": "засол",
    "32532": "глюкоза",
    "32533": "застой",
    "32534": "заступ",
    "32535": "засуха",
    "32536": "затакт",
    "32541": "затвор",
    "32542": "затем",
    "32543": "затечь",
    "32544": "затея",
    "32545": "зато",
    "32546": "заток",
    "32551": "затон",
    "32552": "затор",
    "32553": "затёк",
    "32554": "затёс",
    "32555": "заулок",
    "32556": "заумь",
    "32561": "заутра",
    "32562": "захват",
    "32563": "заход",
    "32564": "зацеп",
    "32565": "мимоза",
    "32566": "зачем",
    "32611": "зачин",
    "32612": "зачёс",
    "32613": "зачёт",
    "32614": "зашеек",
    "32615": "зашить",
    "32616": "защита",
    "32621": "заявка",
    "32622": "заяц",
    "32623": "заячий",
    "32624": "заём",
    "32625": "звание",
    "32626": "званый",
    "32631": "звать",
    "32632": "звезда",
    "32633": "звено",
    "32634": "зверок",
    "32635": "зверь",
    "32636": "угроза",
    "32641": "зверёк",
    "32642": "звон",
    "32643": "звонец",
    "32644": "звонко",
    "32645": "звонок",
    "32646": "звонче",
    "32651": "звук",
    "32652": "звучно",
    "32653": "фруктоза",
    "32654": "зга",
    "32655": "здание",
    "32656": "здесь",
    "32661": "здраво",
    "32662": "зебра",
    "32663": "зебу",
    "32664": "зев",
    "32665": "зевака",
    "32666": "метаморфоза",
    "33111": "медуза",
    "33112": "гипотенуза",
    "33113": "зейгер",
    "33114": "зелень",
    "33115": "зеленя",
    "33116": "зело",
    "33121": "зелье",
    "33122": "зельц",
    "33123": "земец",
    "33124": "земля",
    "33125": "земляк",
    "33126": "земно",
    "33131": "земной",
    "33132": "зенит",
    "33133": "зеница",
    "33134": "зенкер",
    "33135": "зенки",
    "33136": "зерно",
    "33141": "зеро",
    "33142": "зет",
    "33143": "зефир",
    "33144": "зигзаг",
    "33145": "зигота",
    "33146": "зима",
    "33151": "зимний",
    "33152": "зимник",
    "33153": "зимой",
    "33154": "зипун",
    "33155": "зияние",
    "33156": "зиять",
    "33161": "злак",
    "33162": "злаки",
    "33163": "златка",
    "33164": "злато",
    "33165": "златой",
    "33166": "злее",
    "33211": "злеть",
    "33212": "злить",
    "33213": "кукуруза",
    "33214": "польза",
    "33215": "собака",
    "33216": "контратака",
    "33221": "прабабка",
    "33222": "похлёбка",
    "33223": "злотый",
    "33224": "ошибка",
    "33225": "скобка",
    "33226": "коробка",
    "33231": "пробка",
    "33232": "змееяд",
    "33233": "змей",
    "33234": "змейка",
    "33235": "змея",
    "33236": "змий",
    "33241": "знайка",
    "33242": "знак",
    "33243": "знамо",
    "33244": "знамя",
    "33245": "знание",
    "33246": "знатно",
    "33251": "знаток",
    "33252": "знать",
    "33253": "значит",
    "33254": "значок",
    "33255": "зной",
    "33256": "знойно",
    "33261": "зоб",
    "33262": "зобный",
    "33263": "зов",
    "33264": "зодиак",
    "33265": "зодчий",
    "33266": "зоил",
    "33311": "зола",
    "33312": "золить",
    "33313": "золка",
    "33314": "золото",
    "33315": "золь",
    "33316": "зона",
    "33321": "зонд",
    "33322": "зондаж",
    "33323": "зонный",
    "33324": "зонт",
    "33325": "зонтик",
    "33326": "зоолог",
    "33331": "зоосад",
    "33332": "зоркий",
    "33333": "зорко",
    "33334": "зразы",
    "33335": "зрачок",
    "33336": "зрело",
    "33341": "зрелый",
    "33342": "зрение",
    "33343": "зреть",
    "33344": "зримо",
    "33345": "зримый",
    "33346": "зря",
    "33351": "зрячий",
    "33352": "зуав",
    "33353": "зуб",
    "33354": "зубец",
    "33355": "зубик",
    "33356": "зубило",
    "33361": "зубище",
    "33362": "зубной",
    "33363": "зубок",
    "33364": "зубр",
    "33365": "зубрёж",
    "33366": "зубчик",
    "33411": "зубья",
    "33412": "голубка",
    "33413": "нарубка",
    "33414": "обрубка",
    "33415": "зулу",
    "33416": "зулус",
    "33421": "зуммер",
    "33422": "зумпф",
    "33423": "зурна",
    "33424": "зурнач",
    "33425": "зуёк",
    "33426": "зыбить",
    "33431": "зыбка",
    "33432": "зыбкий",
    "33433": "зыбко",
    "33434": "зыбун",
    "33435": "зыбь",
    "33436": "зык",
    "33441": "зыкать",
    "33442": "зычно",
    "33443": "зычный",
    "33444": "зюйд",
    "33445": "зябкий",
    "33446": "зябко",
    "33451": "зяблик",
    "33452": "зябра",
    "33453": "зябрей",
    "33454": "зябь",
    "33455": "зять",
    "33456": "и.о.",
    "33461": "ибис",
    "33462": "ибо",
    "33463": "ива",
    "33464": "иваси",
    "33465": "ивина",
    "33466": "ивняк",
    "33511": "ивовые",
    "33512": "ивовый",
    "33513": "иволга",
    "33514": "иврит",
    "33515": "игла",
    "33516": "иго",
    "33521": "иголка",
    "33522": "игра",
    "33523": "играть",
    "33524": "игрек",
    "33525": "игриво",
    "33526": "игрище",
    "33531": "игрок",
    "33532": "игрун",
    "33533": "игуана",
    "33534": "игумен",
    "33535": "идеал",
    "33536": "идейка",
    "33541": "идейно",
    "33542": "идея",
    "33543": "идиом",
    "33544": "идиома",
    "33545": "мясорубка",
    "33546": "идиш",
    "33551": "идол",
    "33552": "идти",
    "33553": "иезуит",
    "33554": "иена",
    "33555": "иерарх",
    "33556": "иерей",
    "33561": "иже",
    "33562": "ижица",
    "33563": "изба",
    "33564": "избач",
    "33565": "трубка",
    "33566": "вырубка",
    "33611": "извет",
    "33612": "извив",
    "33613": "извить",
    "33614": "извне",
    "33615": "извод",
    "33616": "извоз",
    "33621": "изгиб",
    "33622": "изгой",
    "33623": "издали",
    "33624": "издать",
    "33625": "изжечь",
    "33626": "изжить",
    "33631": "изжога",
    "33632": "излить",
    "33633": "излом",
    "33634": "излёт",
    "33635": "улыбка",
    "33636": "измор",
    "33641": "измять",
    "33642": "износ",
    "33643": "изныть",
    "33644": "изо",
    "33645": "изойти",
    "33646": "изомер",
    "33651": "изотоп",
    "33652": "изречь",
    "33653": "изрыть",
    "33654": "изувер",
    "33655": "изъян",
    "33656": "изъять",
    "33661": "изюбр",
    "33662": "изюбрь",
    "33663": "изюм",
    "33664": "изящно",
    "33665": "икание",
    "33666": "икать",
    "34111": "икнуть",
    "34112": "надбавка",
    "34113": "икота",
    "34114": "икра",
    "34115": "икрина",
    "34116": "икс",
    "34121": "или",
    "34122": "иловый",
    "34123": "илот",
    "34124": "иль",
    "34125": "илька",
    "34126": "ильм",
    "34131": "ильный",
    "34132": "имам",
    "34133": "имамат",
    "34134": "имбирь",
    "34135": "имение",
    "34136": "именно",
    "34141": "прибавка",
    "34142": "ими",
    "34143": "имидж",
    "34144": "импорт",
    "34145": "имущий",
    "34146": "имя",
    "34151": "имярек",
    "34152": "иначе",
    "34153": "инвар",
    "34154": "ингуш",
    "34155": "инда",
    "34156": "индеец",
    "34161": "индекс",
    "34162": "индиго",
    "34163": "индиец",
    "34164": "индий",
    "34165": "индикт",
    "34166": "индо",
    "34211": "индол",
    "34212": "индус",
    "34213": "индюк",
    "34214": "иней",
    "34215": "инженю",
    "34216": "инжир",
    "34221": "иногда",
    "34222": "инозин",
    "34223": "инозит",
    "34224": "иной",
    "34225": "инок",
    "34226": "интерн",
    "34231": "добавка",
    "34232": "инулин",
    "34233": "инфант",
    "34234": "инфикс",
    "34235": "ион",
    "34236": "ионий",
    "34241": "ионит",
    "34242": "иониты",
    "34243": "ионный",
    "34244": "ипомея",
    "34245": "иприт",
    "34246": "иракец",
    "34251": "иранец",
    "34252": "иранка",
    "34253": "ирачка",
    "34254": "ирбис",
    "34255": "ирга",
    "34256": "иридий",
    "34261": "ирис",
    "34262": "ириска",
    "34263": "ирмос",
    "34264": "ирод",
    "34265": "ирокез",
    "34266": "ирония",
    "34311": "иск",
    "34312": "искать",
    "34313": "искони",
    "34314": "искоса",
    "34315": "искра",
    "34316": "искряк",
    "34321": "безрукавка",
    "34322": "плавка",
    "34323": "испечь",
    "34324": "испить",
    "34325": "испод",
    "34326": "исполу",
    "34331": "испуг",
    "34332": "иссечь",
    "34333": "истец",
    "34334": "истечь",
    "34335": "истина",
    "34336": "истица",
    "34341": "истмат",
    "34342": "истово",
    "34343": "истод",
    "34344": "исток",
    "34345": "истома",
    "34346": "истый",
    "34351": "исход",
    "34352": "итак",
    "34353": "итог",
    "34354": "итого",
    "34355": "иттрий",
    "34356": "переплавка",
    "34361": "сплавка",
    "34362": "ихний",
    "34363": "ихтиоз",
    "34364": "ихтиол",
    "34365": "расплавка",
    "34366": "широта",
    "34411": "ишемия",
    "34412": "ишиас",
    "34413": "ишь",
    "34414": "ищейка",
    "34415": "ищущий",
    "34416": "июль",
    "34421": "июнь",
    "34422": "йог",
    "34423": "йод",
    "34424": "йодид",
    "34425": "йодизм",
    "34426": "йодный",
    "34431": "йот",
    "34432": "йота",
    "34433": "кабак",
    "34434": "кабала",
    "34435": "кабан",
    "34436": "кабаре",
    "34441": "выплавка",
    "34442": "кабил",
    "34443": "кабина",
    "34444": "булавка",
    "34445": "кабул",
    "34446": "кабы",
    "34451": "кавун",
    "34452": "кагал",
    "34453": "кагат",
    "34454": "кагор",
    "34455": "каданс",
    "34456": "кадет",
    "34461": "кади",
    "34462": "кадило",
    "34463": "кадить",
    "34464": "кадка",
    "34465": "кадмий",
    "34466": "кадр",
    "34511": "кадры",
    "34512": "кадык",
    "34513": "каждый",
    "34514": "кажись",
    "34515": "казак",
    "34516": "казан",
    "34521": "казать",
    "34522": "казах",
    "34523": "казеин",
    "34524": "казино",
    "34525": "казна",
    "34526": "казнь",
    "34531": "казуар",
    "34532": "казус",
    "34533": "каик",
    "34534": "каинит",
    "34535": "кайзер",
    "34536": "кайла",
    "34541": "кайло",
    "34542": "кайма",
    "34543": "каймак",
    "34544": "кайман",
    "34545": "кайра",
    "34546": "как",
    "34551": "какаду",
    "34552": "какао",
    "34553": "каков",
    "34554": "каково",
    "34555": "какое",
    "34556": "какой",
    "34561": "кактус",
    "34562": "правка",
    "34563": "калан",
    "34564": "калач",
    "34565": "калган",
    "34566": "калека",
    "34611": "калибр",
    "34612": "калий",
    "34613": "калика",
    "34614": "калина",
    "34615": "калита",
    "34616": "калить",
    "34621": "калиф",
    "34622": "калмык",
    "34623": "калоша",
    "34624": "калуга",
    "34625": "калым",
    "34626": "калька",
    "34631": "заправка",
    "34632": "камбий",
    "34633": "камбуз",
    "34634": "камедь",
    "34635": "камень",
    "34636": "камера",
    "34641": "камея",
    "34642": "камзол",
    "34643": "камин",
    "34644": "камка",
    "34645": "камлот",
    "34646": "камора",
    "34651": "кампеш",
    "34652": "камса",
    "34653": "камыш",
    "34654": "канава",
    "34655": "канал",
    "34656": "канат",
    "34661": "канаус",
    "34662": "канва",
    "34663": "кандык",
    "34664": "канкан",
    "34665": "канна",
    "34666": "канон",
    "35111": "каноэ",
    "35112": "кант",
    "35113": "кантон",
    "35114": "кантор",
    "35115": "канун",
    "35116": "канури",
    "35121": "кануть",
    "35122": "каньон",
    "35123": "канюк",
    "35124": "канюля",
    "35125": "каолин",
    "35126": "кап",
    "35131": "капать",
    "35132": "капель",
    "35133": "капер",
    "35134": "каперс",
    "35135": "капище",
    "35136": "капкан",
    "35141": "каплун",
    "35142": "капля",
    "35143": "капок",
    "35144": "капор",
    "35145": "капот",
    "35146": "капрал",
    "35151": "каприз",
    "35152": "капрон",
    "35153": "каптаж",
    "35154": "каптёр",
    "35155": "капут",
    "35156": "кара",
    "35161": "караиб",
    "35162": "караим",
    "35163": "карась",
    "35164": "карат",
    "35165": "карать",
    "35166": "каратэ",
    "35211": "караул",
    "35212": "карбас",
    "35213": "карбид",
    "35214": "карбин",
    "35215": "карга",
    "35216": "карда",
    "35221": "кардан",
    "35222": "кардит",
    "35223": "каре",
    "35224": "карел",
    "35225": "карен",
    "35226": "карета",
    "35231": "кариес",
    "35232": "карий",
    "35233": "кариоз",
    "35234": "каркас",
    "35235": "карлик",
    "35236": "карман",
    "35241": "кармин",
    "35242": "карниз",
    "35243": "карп",
    "35244": "карст",
    "35245": "карт",
    "35246": "карта",
    "35251": "картер",
    "35252": "картон",
    "35253": "картуз",
    "35254": "картуш",
    "35255": "картёж",
    "35256": "карцер",
    "35261": "карьер",
    "35262": "каска",
    "35263": "каскад",
    "35264": "касса",
    "35265": "кассир",
    "35266": "кассия",
    "35311": "каста",
    "35312": "кастет",
    "35313": "кастор",
    "35314": "кат",
    "35315": "каталь",
    "35316": "катар",
    "35321": "катать",
    "35322": "катер",
    "35323": "катет",
    "35324": "катион",
    "35325": "катить",
    "35326": "каткий",
    "35331": "катод",
    "35332": "каток",
    "35333": "катран",
    "35334": "катрен",
    "35335": "катыш",
    "35336": "катюша",
    "35341": "каупер",
    "35342": "каурый",
    "35343": "каучук",
    "35344": "кафе",
    "35345": "кафель",
    "35346": "кафтан",
    "35351": "качать",
    "35352": "качели",
    "35353": "качим",
    "35354": "качка",
    "35355": "качуча",
    "35356": "каша",
    "35361": "кашель",
    "35362": "кашица",
    "35363": "кашка",
    "35364": "кашне",
    "35365": "кашпо",
    "35366": "каштан",
    "35411": "кашуб",
    "35412": "каюк",
    "35413": "каюр",
    "35414": "каюта",
    "35415": "каяк",
    "35416": "каёмка",
    "35421": "квазар",
    "35422": "квак",
    "35423": "квакер",
    "35424": "квакша",
    "35425": "квант",
    "35426": "кварк",
    "35431": "кварта",
    "35432": "кварц",
    "35433": "квас",
    "35434": "квасцы",
    "35435": "квашня",
    "35436": "кверху",
    "35441": "квинта",
    "35442": "квирит",
    "35443": "квит",
    "35444": "квиты",
    "35445": "кворум",
    "35446": "квота",
    "35451": "квёлый",
    "35452": "кеб",
    "35453": "кегель",
    "35454": "кегли",
    "35455": "кегль",
    "35456": "кедр",
    "35461": "кеды",
    "35462": "кейф",
    "35463": "кекс",
    "35464": "кекур",
    "35465": "келарь",
    "35466": "кельма",
    "35511": "кельня",
    "35512": "кельт",
    "35513": "келья",
    "35514": "кем",
    "35515": "кенар",
    "35516": "кенаф",
    "35521": "кениец",
    "35522": "кепи",
    "35523": "кепка",
    "35524": "керн",
    "35525": "кернер",
    "35526": "кесарь",
    "35531": "кессон",
    "35532": "кета",
    "35533": "кетгут",
    "35534": "кетон",
    "35535": "кетч",
    "35536": "кеты",
    "35541": "кефаль",
    "35542": "кефир",
    "35543": "кечуа",
    "35544": "кивать",
    "35545": "кивер",
    "35546": "киви",
    "35551": "кивок",
    "35552": "кидать",
    "35553": "кизил",
    "35554": "кизиль",
    "35555": "кизяк",
    "35556": "кий",
    "35561": "кикс",
    "35562": "кил",
    "35563": "кила",
    "35564": "кило",
    "35565": "киль",
    "35566": "килька",
    "35611": "кимвал",
    "35612": "кимоно",
    "35613": "кинжал",
    "35614": "киник",
    "35615": "кинкан",
    "35616": "кино",
    "35621": "кинуть",
    "35622": "киоск",
    "35623": "киот",
    "35624": "кипа",
    "35625": "кипень",
    "35626": "кипеть",
    "35631": "кипрей",
    "35632": "кираса",
    "35633": "киргиз",
    "35634": "кирза",
    "35635": "кирка",
    "35636": "кирпич",
    "35641": "киса",
    "35642": "кисель",
    "35643": "кисет",
    "35644": "кисея",
    "35645": "кисло",
    "35646": "кислый",
    "35651": "кисляй",
    "35652": "киста",
    "35653": "кисть",
    "35654": "кит",
    "35655": "китаец",
    "35656": "китель",
    "35661": "кифара",
    "35662": "кич",
    "35663": "кичка",
    "35664": "кишеть",
    "35665": "кишка",
    "35666": "кишлак",
    "36111": "кишмиш",
    "36112": "кишмя",
    "36113": "клавир",
    "36114": "клавиш",
    "36115": "клад",
    "36116": "кладка",
    "36121": "кладь",
    "36122": "клака",
    "36123": "клакёр",
    "36124": "клан",
    "36125": "клапан",
    "36126": "класс",
    "36131": "класть",
    "36132": "клевер",
    "36133": "клевок",
    "36134": "клеить",
    "36135": "клей",
    "36136": "клейка",
    "36141": "клеймо",
    "36142": "клемма",
    "36143": "клерк",
    "36144": "клетка",
    "36145": "клеть",
    "36146": "клешни",
    "36151": "клешня",
    "36152": "клещ",
    "36153": "клещи",
    "36154": "кливер",
    "36155": "клиент",
    "36156": "вправка",
    "36161": "клик",
    "36162": "клика",
    "36163": "климат",
    "36164": "клин",
    "36165": "клинок",
    "36166": "клип",
    "36211": "клипер",
    "36212": "клипсы",
    "36213": "клир",
    "36214": "клирик",
    "36215": "клирос",
    "36216": "клич",
    "36221": "кличка",
    "36222": "клише",
    "36223": "справка",
    "36224": "клобук",
    "36225": "заставка",
    "36226": "клок",
    "36231": "клокот",
    "36232": "клон",
    "36233": "клоп",
    "36234": "клотик",
    "36235": "клоун",
    "36236": "клочок",
    "36241": "клочья",
    "36242": "клуб",
    "36243": "клубок",
    "36244": "клумба",
    "36245": "клупп",
    "36246": "клуша",
    "36251": "клык",
    "36252": "клюв",
    "36253": "клюз",
    "36254": "клюка",
    "36255": "клюква",
    "36256": "ключ",
    "36261": "ключик",
    "36262": "клюшка",
    "36263": "клякса",
    "36264": "вставка",
    "36265": "клясть",
    "36266": "клятва",
    "36311": "кляуза",
    "36312": "подставка",
    "36313": "клёв",
    "36314": "клёкот",
    "36315": "клён",
    "36316": "клёпка",
    "36321": "клёст",
    "36322": "клёцка",
    "36323": "клёш",
    "36324": "кнель",
    "36325": "кнехт",
    "36326": "книга",
    "36331": "книжка",
    "36332": "книжно",
    "36333": "книзу",
    "36334": "кница",
    "36335": "кнопка",
    "36336": "доставка",
    "36341": "княжий",
    "36342": "княжич",
    "36343": "княжна",
    "36344": "князь",
    "36345": "князёк",
    "36346": "отставка",
    "36351": "кобза",
    "36352": "кобра",
    "36353": "кобура",
    "36354": "кобчик",
    "36355": "кобыз",
    "36356": "выставка",
    "36361": "коваль",
    "36362": "ковать",
    "36363": "ковбой",
    "36364": "ковка",
    "36365": "ковкий",
    "36366": "коврик",
    "36411": "ковчег",
    "36412": "ковш",
    "36413": "ковы",
    "36414": "ковыль",
    "36415": "ковёр",
    "36416": "когда",
    "36421": "кого",
    "36422": "коготь",
    "36423": "код",
    "36424": "кода",
    "36425": "кодак",
    "36426": "кодеин",
    "36431": "кодекс",
    "36432": "кожа",
    "36433": "кожан",
    "36434": "кожеед",
    "36435": "кожица",
    "36436": "кожник",
    "36441": "кожный",
    "36442": "кожура",
    "36443": "кожух",
    "36444": "коза",
    "36445": "козий",
    "36446": "козлы",
    "36451": "козляк",
    "36452": "козни",
    "36453": "козуля",
    "36454": "козырь",
    "36455": "козёл",
    "36456": "кой",
    "36461": "койка",
    "36462": "койне",
    "36463": "койот",
    "36464": "кок",
    "36465": "шпаклёвка",
    "36466": "верёвка",
    "36511": "путёвка",
    "36512": "кокиль",
    "36513": "кокки",
    "36514": "коклюш",
    "36515": "кокон",
    "36516": "кокора",
    "36521": "кокос",
    "36522": "ночёвка",
    "36523": "коксит",
    "36524": "жеребьёвка",
    "36525": "колба",
    "36526": "набивка",
    "36531": "колено",
    "36532": "колер",
    "36533": "колесо",
    "36534": "колет",
    "36535": "колеть",
    "36536": "колея",
    "36541": "коли",
    "36542": "колики",
    "36543": "колит",
    "36544": "взбивка",
    "36545": "колкий",
    "36546": "колко",
    "36551": "коллеж",
    "36552": "коло",
    "36553": "колода",
    "36554": "колок",
    "36555": "колон",
    "36556": "колос",
    "36561": "колосс",
    "36562": "прибивка",
    "36563": "колоша",
    "36564": "колпак",
    "36565": "колтун",
    "36566": "колун",
    "36611": "колхоз",
    "36612": "колчан",
    "36613": "коль",
    "36614": "колье",
    "36615": "кольт",
    "36616": "кольцо",
    "36621": "колюче",
    "36622": "коляда",
    "36623": "ком",
    "36624": "завивка",
    "36625": "комар",
    "36626": "комбат",
    "36631": "комбед",
    "36632": "комвуз",
    "36633": "комдив",
    "36634": "комель",
    "36635": "комета",
    "36636": "коми",
    "36641": "комизм",
    "36642": "комик",
    "36643": "комикс",
    "36644": "комкор",
    "36645": "комма",
    "36646": "комод",
    "36651": "комок",
    "36652": "компас",
    "36653": "компот",
    "36654": "кому",
    "36655": "кон",
    "36656": "конвой",
    "36661": "кондак",
    "36662": "кондор",
    "36663": "кондёр",
    "36664": "конец",
    "36665": "конина",
    "36666": "конка",
    "41111": "конкур",
    "41112": "конник",
    "41113": "конный",
    "41114": "коноид",
    "41115": "консул",
    "41116": "конто",
    "41121": "контра",
    "41122": "контры",
    "41123": "контур",
    "41124": "конура",
    "41125": "конус",
    "41126": "конфуз",
    "41131": "кончая",
    "41132": "кончик",
    "41133": "конь",
    "41134": "коньки",
    "41135": "конюх",
    "41136": "конёк",
    "41141": "копа",
    "41142": "копал",
    "41143": "копач",
    "41144": "копи",
    "41145": "копия",
    "41146": "копка",
    "41151": "копна",
    "41152": "копра",
    "41153": "копт",
    "41154": "копун",
    "41155": "копыл",
    "41156": "копь",
    "41161": "копьё",
    "41162": "копёр",
    "41163": "кора",
    "41164": "коран",
    "41165": "корд",
    "41166": "корда",
    "41211": "корец",
    "41212": "корж",
    "41213": "корка",
    "41214": "корм",
    "41215": "корма",
    "41216": "короб",
    "41221": "корт",
    "41222": "корчи",
    "41223": "корь",
    "41224": "корьё",
    "41225": "коряк",
    "41226": "коса",
    "41231": "косач",
    "41232": "косец",
    "41233": "косма",
    "41234": "космы",
    "41235": "косно",
    "41236": "косо",
    "41241": "прививка",
    "41242": "кость",
    "41243": "заливка",
    "41244": "кот",
    "41245": "котик",
    "41246": "коты",
    "41251": "котёл",
    "41252": "кофе",
    "41253": "кофр",
    "41254": "кофта",
    "41255": "кочан",
    "41256": "кочет",
    "41261": "кочка",
    "41262": "кош",
    "41263": "кошка",
    "41264": "кошма",
    "41265": "кощей",
    "41266": "краб",
    "41311": "краги",
    "41312": "нашивка",
    "41313": "край",
    "41314": "краля",
    "41315": "кран",
    "41316": "крап",
    "41321": "крапп",
    "41322": "краса",
    "41323": "крат",
    "41324": "крах",
    "41325": "краше",
    "41326": "кредо",
    "41331": "крез",
    "41332": "крем",
    "41333": "крен",
    "41334": "креол",
    "41335": "креп",
    "41336": "крепь",
    "41341": "кресс",
    "41342": "крест",
    "41343": "криво",
    "41344": "крик",
    "41345": "криль",
    "41346": "крица",
    "41351": "кров",
    "41352": "обшивка",
    "41353": "крой",
    "41354": "кроки",
    "41355": "кроль",
    "41356": "кроме",
    "41361": "крон",
    "41362": "крона",
    "41363": "кросс",
    "41364": "крот",
    "41365": "кроха",
    "41366": "кроше",
    "41411": "круг",
    "41412": "круиз",
    "41413": "круп",
    "41414": "крупа",
    "41415": "круто",
    "41416": "круча",
    "41421": "круче",
    "41422": "крыло",
    "41423": "крыса",
    "41424": "крыть",
    "41425": "крыша",
    "41426": "крюк",
    "41431": "кряду",
    "41432": "кряж",
    "41433": "кто",
    "41434": "куб",
    "41435": "кубик",
    "41436": "кубок",
    "41441": "куга",
    "41442": "куда",
    "41443": "кудри",
    "41444": "кузен",
    "41445": "кузня",
    "41446": "кузов",
    "41451": "кукан",
    "41452": "кукиш",
    "41453": "кукла",
    "41454": "куку",
    "41455": "кулак",
    "41456": "кулан",
    "41461": "кулеш",
    "41462": "кули",
    "41463": "кулик",
    "41464": "кулич",
    "41465": "кулон",
    "41466": "куль",
    "41511": "культ",
    "41512": "кулёк",
    "41513": "кум",
    "41514": "кума",
    "41515": "кумач",
    "41516": "кумжа",
    "41521": "кумир",
    "41522": "кумык",
    "41523": "кумыс",
    "41524": "кун",
    "41525": "кунак",
    "41526": "куний",
    "41531": "купа",
    "41532": "купаж",
    "41533": "купе",
    "41534": "купец",
    "41535": "купля",
    "41536": "купол",
    "41541": "купон",
    "41542": "кур",
    "41543": "кураж",
    "41544": "курай",
    "41545": "курд",
    "41546": "курия",
    "41551": "курок",
    "41552": "курс",
    "41553": "куры",
    "41554": "кус",
    "41555": "кусок",
    "41556": "куст",
    "41561": "кутум",
    "41562": "кутья",
    "41563": "прошивка",
    "41564": "кухня",
    "41565": "куцый",
    "41566": "куча",
    "41611": "кучер",
    "41612": "вышивка",
    "41613": "кучно",
    "41614": "куш",
    "41615": "кушак",
    "41616": "куща",
    "41621": "кхмер",
    "41622": "кьят",
    "41623": "кювет",
    "41624": "кюре",
    "41625": "кюри",
    "41626": "кюрий",
    "41631": "кюрин",
    "41632": "кяриз",
    "41633": "лабаз",
    "41634": "лава",
    "41635": "лаваш",
    "41636": "лавка",
    "41641": "лавр",
    "41642": "лавра",
    "41643": "лаг",
    "41644": "лад",
    "41645": "ладан",
    "41646": "ладно",
    "41651": "ладья",
    "41652": "лаж",
    "41653": "лаз",
    "41654": "лазер",
    "41655": "лазка",
    "41656": "лай",
    "41661": "лайба",
    "41662": "лайка",
    "41663": "лак",
    "41664": "лакей",
    "41665": "лал",
    "41666": "лама",
    "42111": "лампа",
    "42112": "лан",
    "42113": "ландо",
    "42114": "ланды",
    "42115": "ланка",
    "42116": "лань",
    "42121": "лао",
    "42122": "лапа",
    "42123": "лапка",
    "42124": "лапта",
    "42125": "лапша",
    "42126": "ларго",
    "42131": "ларец",
    "42132": "ларь",
    "42133": "ларёк",
    "42134": "ласка",
    "42135": "лассо",
    "42136": "ласт",
    "42141": "лат",
    "42142": "латка",
    "42143": "латук",
    "42144": "латы",
    "42145": "латыш",
    "42146": "лафа",
    "42151": "лафет",
    "42152": "лафит",
    "42153": "лаять",
    "42154": "лгать",
    "42155": "лгун",
    "42156": "лев",
    "42161": "левак",
    "42162": "левша",
    "42163": "левый",
    "42164": "легат",
    "42165": "легаш",
    "42166": "легко",
    "42211": "легче",
    "42212": "леди",
    "42213": "ледок",
    "42214": "леер",
    "42215": "лежак",
    "42216": "лежмя",
    "42221": "лезть",
    "42222": "леи",
    "42223": "лей",
    "42224": "лейка",
    "42225": "лейяс",
    "42226": "лек",
    "42231": "лемех",
    "42232": "лемма",
    "42233": "лемур",
    "42234": "лен",
    "42235": "ленец",
    "42236": "ленок",
    "42241": "лента",
    "42242": "ленто",
    "42243": "ленца",
    "42244": "ленч",
    "42245": "лень",
    "42246": "лепет",
    "42251": "лепка",
    "42252": "лепра",
    "42253": "лепта",
    "42254": "лерка",
    "42255": "лес",
    "42256": "леса",
    "42261": "леска",
    "42262": "лесок",
    "42263": "лесть",
    "42264": "лета",
    "42265": "лето",
    "42266": "леток",
    "42311": "летом",
    "42312": "летун",
    "42313": "лечо",
    "42314": "лечь",
    "42315": "фальшивка",
    "42316": "лещ",
    "42321": "лещик",
    "42322": "долгота",
    "42323": "лиана",
    "42324": "либо",
    "42325": "ливер",
    "42326": "ливмя",
    "42331": "ливр",
    "42332": "лига",
    "42333": "лидер",
    "42334": "лизин",
    "42335": "лизис",
    "42336": "лизол",
    "42341": "кладовка",
    "42342": "лик",
    "42343": "ликёр",
    "42344": "лилея",
    "42345": "лилия",
    "42346": "лиман",
    "42351": "лимб",
    "42352": "лимит",
    "42353": "лимон",
    "42354": "лимфа",
    "42355": "линза",
    "42356": "линия",
    "42361": "линч",
    "42362": "линь",
    "42363": "линёк",
    "42364": "липа",
    "42365": "липаз",
    "42366": "липка",
    "42411": "липси",
    "42412": "лира",
    "42413": "лирик",
    "42414": "лис",
    "42415": "лиса",
    "42416": "лисий",
    "42421": "лист",
    "42422": "лит",
    "42423": "литер",
    "42424": "литий",
    "42425": "лития",
    "42426": "литка",
    "42431": "литой",
    "42432": "литр",
    "42433": "литый",
    "42434": "лить",
    "42435": "литьё",
    "42436": "лиф",
    "42441": "лифт",
    "42442": "голодовка",
    "42443": "лихва",
    "42444": "лихо",
    "42445": "мордовка",
    "42446": "лицей",
    "42451": "лицо",
    "42452": "лично",
    "42453": "распаковка",
    "42454": "лишек",
    "42455": "лишь",
    "42456": "лоб",
    "42461": "лобан",
    "42462": "лобби",
    "42463": "лобик",
    "42464": "упаковка",
    "42465": "лов",
    "42466": "ловец",
    "42511": "ловко",
    "42512": "ловля",
    "42513": "лог",
    "42514": "логик",
    "42515": "лодка",
    "42516": "ложа",
    "42521": "ложе",
    "42522": "ложка",
    "42523": "ложно",
    "42524": "ложок",
    "42525": "ложь",
    "42526": "лоза",
    "42531": "локон",
    "42532": "лом",
    "42533": "морковка",
    "42534": "лонжа",
    "42535": "лоно",
    "42536": "стыковка",
    "42541": "лорд",
    "42542": "лори",
    "42543": "лосий",
    "42544": "лоск",
    "42545": "лось",
    "42546": "лот",
    "42551": "лото",
    "42552": "лоток",
    "42553": "лотос",
    "42554": "мышеловка",
    "42555": "лохмы",
    "42556": "лоция",
    "42561": "боеголовка",
    "42562": "луб",
    "42563": "лубок",
    "42564": "луг",
    "42565": "луда",
    "42566": "лужа",
    "42611": "лужок",
    "42612": "луза",
    "42613": "лузга",
    "42614": "лук",
    "42615": "лука",
    "42616": "луна",
    "42621": "лунит",
    "42622": "лунка",
    "42623": "лунь",
    "42624": "лупа",
    "42625": "лупка",
    "42626": "луч",
    "42631": "лучик",
    "42632": "лучок",
    "42633": "лучше",
    "42634": "лыжи",
    "42635": "лыжня",
    "42636": "лыко",
    "42641": "лысун",
    "42642": "лысый",
    "42643": "лычко",
    "42644": "льяло",
    "42645": "лэди",
    "42646": "лэп",
    "42651": "люб",
    "42652": "люба",
    "42653": "любая",
    "42654": "любка",
    "42655": "любо",
    "42656": "любой",
    "42661": "люгер",
    "42662": "люд",
    "42663": "люди",
    "42664": "людно",
    "42665": "люк",
    "42666": "люкс",
    "43111": "люли",
    "43112": "люмен",
    "43113": "люнет",
    "43114": "люпин",
    "43115": "люпус",
    "43116": "лютик",
    "43121": "лютня",
    "43122": "мухоловка",
    "43123": "перловка",
    "43124": "люфа",
    "43125": "люфт",
    "43126": "люэс",
    "43131": "ляд",
    "43132": "ляда",
    "43133": "ляжка",
    "43134": "лязг",
    "43135": "лямка",
    "43136": "ляп",
    "43141": "ляпис",
    "43142": "лярд",
    "43143": "лясы",
    "43144": "лях",
    "43145": "уловка",
    "43146": "лёд",
    "43151": "лёжка",
    "43152": "лёжмя",
    "43153": "лён",
    "43154": "лёска",
    "43155": "лёсс",
    "43156": "лёт",
    "43161": "лётка",
    "43162": "лётом",
    "43163": "мавр",
    "43164": "маг",
    "43165": "магия",
    "43166": "магма",
    "43211": "магот",
    "43212": "мадам",
    "43213": "маета",
    "43214": "мажор",
    "43215": "маз",
    "43216": "мазер",
    "43221": "мазка",
    "43222": "мазня",
    "43223": "зимовка",
    "43224": "мазур",
    "43225": "мазут",
    "43226": "мазь",
    "43231": "маис",
    "43232": "май",
    "43233": "майка",
    "43234": "майна",
    "43235": "майор",
    "43236": "майя",
    "43241": "мак",
    "43242": "макао",
    "43243": "макет",
    "43244": "маки",
    "43245": "маков",
    "43246": "малец",
    "43251": "малка",
    "43252": "мало",
    "43253": "малое",
    "43254": "малый",
    "43255": "малыш",
    "43256": "маляр",
    "43261": "малёк",
    "43262": "мама",
    "43263": "мамин",
    "43264": "мамка",
    "43265": "манго",
    "43266": "манеж",
    "43311": "манер",
    "43312": "мание",
    "43313": "мания",
    "43314": "манка",
    "43315": "манко",
    "43316": "манна",
    "43321": "манок",
    "43322": "манси",
    "43323": "манто",
    "43324": "манул",
    "43325": "мара",
    "43326": "марал",
    "43331": "мари",
    "43332": "марка",
    "43333": "марля",
    "43334": "марс",
    "43335": "март",
    "43336": "марш",
    "43341": "марши",
    "43342": "марь",
    "43343": "маска",
    "43344": "масло",
    "43345": "масон",
    "43346": "масса",
    "43351": "масть",
    "43352": "обстановка",
    "43353": "подстановка",
    "43354": "матч",
    "43355": "мать",
    "43356": "маун",
    "43361": "перестановка",
    "43362": "мах",
    "43363": "махом",
    "43364": "махра",
    "43365": "махры",
    "43366": "маца",
    "43411": "мачок",
    "43412": "мачта",
    "43413": "маяк",
    "43414": "маять",
    "43415": "мгла",
    "43416": "мегом",
    "43421": "медик",
    "43422": "медок",
    "43423": "медь",
    "43424": "медяк",
    "43425": "меж",
    "43426": "межа",
    "43431": "между",
    "43432": "мезга",
    "43433": "мезон",
    "43434": "мейоз",
    "43435": "мел",
    "43436": "мелко",
    "43441": "мелок",
    "43442": "мелос",
    "43443": "мель",
    "43444": "мена",
    "43445": "менее",
    "43446": "меню",
    "43451": "меня",
    "43452": "мера",
    "43453": "мерин",
    "43454": "мерка",
    "43455": "мерно",
    "43456": "мерси",
    "43461": "меря",
    "43462": "месса",
    "43463": "мести",
    "43464": "место",
    "43465": "остановка",
    "43466": "месяц",
    "43511": "мета",
    "43512": "метан",
    "43513": "метил",
    "43514": "метис",
    "43515": "метка",
    "43516": "метко",
    "43521": "метла",
    "43522": "метод",
    "43523": "метол",
    "43524": "метоп",
    "43525": "метр",
    "43526": "метро",
    "43531": "мех",
    "43532": "меццо",
    "43533": "меч",
    "43534": "мечта",
    "43535": "мешок",
    "43536": "мзда",
    "43541": "миг",
    "43542": "мигом",
    "43543": "миди",
    "43544": "мидия",
    "43545": "микст",
    "43546": "милая",
    "43551": "милка",
    "43552": "мило",
    "43553": "милый",
    "43554": "миля",
    "43555": "мим",
    "43556": "мимо",
    "43561": "мина",
    "43562": "мини",
    "43563": "минор",
    "43564": "минус",
    "43565": "минуя",
    "43566": "минёр",
    "43611": "миома",
    "43612": "мир",
    "43613": "мираж",
    "43614": "мирза",
    "43615": "мирно",
    "43616": "миро",
    "43621": "мирок",
    "43622": "мирон",
    "43623": "мирра",
    "43624": "мирт",
    "43625": "мирта",
    "43626": "миска",
    "43631": "мисс",
    "43632": "митоз",
    "43633": "митра",
    "43634": "миф",
    "43635": "мишка",
    "43636": "млат",
    "43641": "млеть",
    "43642": "мне",
    "43643": "мнить",
    "43644": "много",
    "43645": "мной",
    "43646": "мною",
    "43651": "могар",
    "43652": "мода",
    "43653": "модно",
    "43654": "модус",
    "43655": "может",
    "43656": "можно",
    "43661": "мозг",
    "43662": "мои",
    "43663": "мой",
    "43664": "мойва",
    "43665": "мойка",
    "43666": "мойра",
    "44111": "мокко",
    "44112": "мокро",
    "44113": "мокша",
    "44114": "мол",
    "44115": "молва",
    "44116": "молвь",
    "44121": "молем",
    "44122": "молот",
    "44123": "молох",
    "44124": "молча",
    "44125": "моль",
    "44126": "моляр",
    "44131": "монах",
    "44132": "моном",
    "44133": "мопед",
    "44134": "мопс",
    "44135": "мор",
    "44136": "постановка",
    "44141": "расстановка",
    "44142": "море",
    "44143": "морж",
    "44144": "морзе",
    "44145": "мороз",
    "44146": "морс",
    "44151": "морф",
    "44152": "морфа",
    "44153": "моряк",
    "44154": "мосол",
    "44155": "мост",
    "44156": "мосье",
    "44161": "мот",
    "44162": "мотив",
    "44163": "мотка",
    "44164": "мотня",
    "44165": "моток",
    "44166": "мотор",
    "44211": "мотто",
    "44212": "мох",
    "44213": "мохер",
    "44214": "установка",
    "44215": "мочка",
    "44216": "мочь",
    "44221": "мошка",
    "44222": "мошна",
    "44223": "мощи",
    "44224": "мощно",
    "44225": "мощь",
    "44226": "моя",
    "44231": "моё",
    "44232": "штамповка",
    "44233": "мрак",
    "44234": "муар",
    "44235": "мудро",
    "44236": "муж",
    "44241": "мужик",
    "44242": "муза",
    "44243": "музей",
    "44244": "калибровка",
    "44245": "мул",
    "44246": "мулат",
    "44251": "мулла",
    "44252": "муляж",
    "44253": "мумия",
    "44254": "мумиё",
    "44255": "мура",
    "44256": "мураш",
    "44261": "мурза",
    "44262": "мурло",
    "44263": "командировка",
    "44264": "мусс",
    "44265": "муст",
    "44266": "мутон",
    "44311": "бомбардировка",
    "44312": "муфта",
    "44313": "муха",
    "44314": "мушка",
    "44315": "мчать",
    "44316": "мшить",
    "44321": "мыза",
    "44322": "мыло",
    "44323": "стажировка",
    "44324": "мыс",
    "44325": "мысль",
    "44326": "мыт",
    "44331": "мытый",
    "44332": "мыть",
    "44333": "мытьё",
    "44334": "мышка",
    "44335": "мышца",
    "44336": "мышь",
    "44341": "мэлан",
    "44342": "мэр",
    "44343": "мэрия",
    "44344": "мэрон",
    "44345": "мюль",
    "44346": "мюон",
    "44351": "мюрид",
    "44352": "мягко",
    "44353": "мягче",
    "44354": "мякиш",
    "44355": "мялка",
    "44356": "газировка",
    "44361": "мясо",
    "44362": "мята",
    "44363": "мятеж",
    "44364": "мятый",
    "44365": "мять",
    "44366": "мяч",
    "44411": "мячик",
    "44412": "мёд",
    "44413": "набат",
    "44414": "набег",
    "44415": "набоб",
    "44416": "набок",
    "44421": "набор",
    "44422": "навал",
    "44423": "навар",
    "44424": "навек",
    "44425": "навес",
    "44426": "навет",
    "44431": "маскировка",
    "44432": "навой",
    "44433": "навык",
    "44434": "наган",
    "44435": "нагар",
    "44436": "полировка",
    "44441": "планировка",
    "44442": "нагой",
    "44443": "нагон",
    "44444": "нагул",
    "44445": "над",
    "44446": "надел",
    "44451": "надир",
    "44452": "надо",
    "44453": "надой",
    "44454": "надув",
    "44455": "наезд",
    "44456": "нажим",
    "44461": "нажин",
    "44462": "назад",
    "44463": "назло",
    "44464": "назём",
    "44465": "наиб",
    "44466": "найти",
    "44511": "наказ",
    "44512": "накал",
    "44513": "накат",
    "44514": "накос",
    "44515": "налив",
    "44516": "налим",
    "44521": "налог",
    "44522": "налёт",
    "44523": "нам",
    "44524": "нами",
    "44525": "намин",
    "44526": "намол",
    "44531": "намыв",
    "44532": "намёк",
    "44533": "намёт",
    "44534": "нанду",
    "44535": "нанка",
    "44536": "нанос",
    "44541": "напев",
    "44542": "напой",
    "44543": "напор",
    "44544": "нарез",
    "44545": "народ",
    "44546": "нарта",
    "44551": "нары",
    "44552": "нарыв",
    "44553": "наряд",
    "44554": "нас",
    "44555": "насос",
    "44556": "наст",
    "44561": "нате",
    "44562": "натр",
    "44563": "натёк",
    "44564": "наука",
    "44565": "тренировка",
    "44566": "наци",
    "44611": "нация",
    "44612": "начёс",
    "44613": "начёт",
    "44614": "наш",
    "44615": "наша",
    "44616": "наше",
    "44621": "наши",
    "44622": "наяву",
    "44623": "наяда",
    "44624": "наём",
    "44625": "небо",
    "44626": "невод",
    "44631": "нега",
    "44632": "негде",
    "44633": "него",
    "44634": "экипировка",
    "44635": "негус",
    "44636": "недра",
    "44641": "недуг",
    "44642": "нежно",
    "44643": "ней",
    "44644": "нейти",
    "44645": "некем",
    "44646": "некий",
    "44651": "некто",
    "44652": "дрессировка",
    "44653": "немец",
    "44654": "немка",
    "44655": "сортировка",
    "44656": "ненец",
    "44661": "ненка",
    "44662": "неон",
    "44663": "нерв",
    "44664": "нервы",
    "44665": "нерка",
    "44666": "нерол",
    "45111": "нерпа",
    "45112": "нести",
    "45113": "несть",
    "45114": "несун",
    "45115": "нет",
    "45116": "нети",
    "45121": "нетто",
    "45122": "нету",
    "45123": "неуч",
    "45124": "неф",
    "45125": "нефть",
    "45126": "нечем",
    "45131": "нечет",
    "45132": "нечто",
    "45133": "пифагор",
    "45134": "нивх",
    "45135": "нигде",
    "45136": "ниже",
    "45141": "низ",
    "45142": "низка",
    "45143": "низко",
    "45144": "низом",
    "45145": "низы",
    "45146": "никак",
    "45151": "никем",
    "45152": "никои",
    "45153": "никто",
    "45154": "нилот",
    "45155": "нимб",
    "45156": "нимфа",
    "45161": "нитка",
    "45162": "нитон",
    "45163": "нить",
    "45164": "них",
    "45165": "ниц",
    "45166": "ничей",
    "45211": "ничем",
    "45212": "ничто",
    "45213": "ничья",
    "45214": "ниша",
    "45215": "нищая",
    "45216": "нищий",
    "45221": "новый",
    "45222": "новь",
    "45223": "нога",
    "45224": "ноев",
    "45225": "нож",
    "45226": "ножик",
    "45231": "ножка",
    "45232": "ножны",
    "45233": "нок",
    "45234": "ноль",
    "45235": "номад",
    "45236": "номер",
    "45241": "нона",
    "45242": "нонет",
    "45243": "нора",
    "45244": "норд",
    "45245": "нория",
    "45246": "норка",
    "45251": "норма",
    "45252": "норов",
    "45253": "нос",
    "45254": "носач",
    "45255": "носик",
    "45256": "носка",
    "45261": "носки",
    "45262": "носок",
    "45263": "нота",
    "45264": "нотка",
    "45265": "ночь",
    "45266": "ночью",
    "45311": "ноша",
    "45312": "нощно",
    "45313": "нрав",
    "45314": "нравы",
    "45315": "нуга",
    "45316": "нудно",
    "45321": "нужда",
    "45322": "нужно",
    "45323": "нуль",
    "45324": "нумер",
    "45325": "нут",
    "45326": "нуте",
    "45331": "нутро",
    "45332": "ныне",
    "45333": "нынче",
    "45334": "нырок",
    "45335": "шифровка",
    "45336": "массовка",
    "45341": "грунтовка",
    "45342": "нюанс",
    "45343": "заготовка",
    "45344": "подготовка",
    "45345": "забастовка",
    "45346": "нянин",
    "45351": "няня",
    "45352": "нёбо",
    "45353": "нём",
    "45354": "оазис",
    "45355": "оба",
    "45356": "обвал",
    "45361": "обвес",
    "45362": "обвод",
    "45363": "обвоз",
    "45364": "обгон",
    "45365": "обе",
    "45366": "обед",
    "45411": "обер",
    "45412": "обет",
    "45413": "обжа",
    "45414": "обжиг",
    "45415": "обжим",
    "45416": "обжин",
    "45421": "обзор",
    "45422": "обида",
    "45423": "обить",
    "45424": "обкат",
    "45425": "обком",
    "45426": "обкос",
    "45431": "облив",
    "45432": "облик",
    "45433": "облов",
    "45434": "облог",
    "45435": "облом",
    "45436": "облёт",
    "45441": "обман",
    "45442": "обмен",
    "45443": "обмер",
    "45444": "обмин",
    "45445": "обмол",
    "45446": "обмыв",
    "45451": "обо",
    "45452": "обод",
    "45453": "обоз",
    "45454": "обои",
    "45455": "обок",
    "45456": "обол",
    "45461": "оборы",
    "45462": "образ",
    "45463": "обрат",
    "45464": "обрез",
    "45465": "оброк",
    "45466": "обруб",
    "45511": "обруч",
    "45512": "обрыв",
    "45513": "обряд",
    "45514": "обсев",
    "45515": "обувь",
    "45516": "обуза",
    "45521": "обуть",
    "45522": "обух",
    "45523": "обход",
    "45524": "общий",
    "45525": "общо",
    "45526": "объём",
    "45531": "обыск",
    "45532": "овал",
    "45533": "овин",
    "45534": "овить",
    "45535": "овод",
    "45536": "овоид",
    "45541": "овощи",
    "45542": "овраг",
    "45543": "овсюг",
    "45544": "овца",
    "45545": "овчар",
    "45546": "овёс",
    "45551": "ого",
    "45552": "огонь",
    "45553": "огрех",
    "45554": "ода",
    "45555": "одеть",
    "45556": "один",
    "45561": "одна",
    "45562": "одно",
    "45563": "одр",
    "45564": "листовка",
    "45565": "одёр",
    "45566": "ожечь",
    "45611": "ожина",
    "45612": "ожить",
    "45613": "ожог",
    "45614": "оземь",
    "45615": "озена",
    "45616": "озеро",
    "45621": "озимь",
    "45622": "озноб",
    "45623": "озон",
    "45624": "ойрат",
    "45625": "окапи",
    "45626": "окать",
    "45631": "океан",
    "45632": "окись",
    "45633": "оклад",
    "45634": "оклик",
    "45635": "окно",
    "45636": "око",
    "45641": "оковы",
    "45642": "около",
    "45643": "окоп",
    "45644": "окорм",
    "45645": "окот",
    "45646": "окоём",
    "45651": "окрик",
    "45652": "окрол",
    "45653": "округ",
    "45654": "оксид",
    "45655": "октан",
    "45656": "октет",
    "45661": "окунь",
    "45662": "олеат",
    "45663": "олеин",
    "45664": "олень",
    "45665": "олеум",
    "45666": "олива",
    "46111": "олимп",
    "46112": "олифа",
    "46113": "олово",
    "46114": "олух",
    "46115": "ольха",
    "46116": "омар",
    "46121": "омег",
    "46122": "омега",
    "46123": "омела",
    "46124": "омлет",
    "46125": "омуль",
    "46126": "омут",
    "46131": "омыть",
    "46132": "омёт",
    "46133": "она",
    "46134": "онагр",
    "46135": "они",
    "46136": "оникс",
    "46141": "оно",
    "46142": "онуча",
    "46143": "оный",
    "46144": "онёр",
    "46145": "оолит",
    "46146": "опак",
    "46151": "опал",
    "46152": "опала",
    "46153": "опара",
    "46154": "опека",
    "46155": "опера",
    "46156": "толстовка",
    "46161": "опил",
    "46162": "опись",
    "46163": "опить",
    "46164": "опиум",
    "46165": "оплот",
    "46166": "оплыв",
    "46211": "опоек",
    "46212": "опой",
    "46213": "опока",
    "46214": "опор",
    "46215": "опора",
    "46216": "опрос",
    "46221": "оптик",
    "46222": "оптом",
    "46223": "опус",
    "46224": "опыт",
    "46225": "опята",
    "46226": "опять",
    "46231": "орава",
    "46232": "страховка",
    "46233": "орарь",
    "46234": "духовка",
    "46235": "орган",
    "46236": "загадка",
    "46241": "орда",
    "46242": "орден",
    "46243": "ордер",
    "46244": "ореол",
    "46245": "орех",
    "46246": "орлан",
    "46251": "орлец",
    "46252": "орлон",
    "46253": "орляк",
    "46254": "ороки",
    "46255": "орочи",
    "46256": "орт",
    "46261": "ортит",
    "46262": "оршад",
    "46263": "орёл",
    "46264": "оса",
    "46265": "осада",
    "46266": "осень",
    "46311": "осечь",
    "46312": "осина",
    "46313": "оскал",
    "46314": "осляк",
    "46315": "осман",
    "46316": "осмий",
    "46321": "осмол",
    "46322": "осмос",
    "46323": "особа",
    "46324": "особо",
    "46325": "особь",
    "46326": "осоед",
    "46331": "осока",
    "46332": "осот",
    "46333": "оспа",
    "46334": "ост",
    "46335": "остит",
    "46336": "остов",
    "46341": "остро",
    "46342": "ость",
    "46343": "остяк",
    "46344": "осыпь",
    "46345": "ось",
    "46346": "осёл",
    "46351": "осётр",
    "46352": "отава",
    "46353": "отара",
    "46354": "отбив",
    "46355": "отбой",
    "46356": "отбор",
    "46361": "отвал",
    "46362": "отвар",
    "46363": "отвес",
    "46364": "ответ",
    "46365": "отвод",
    "46366": "отвоз",
    "46411": "отгиб",
    "46412": "отгон",
    "46413": "отгул",
    "46414": "отдел",
    "46415": "отдух",
    "46416": "отдых",
    "46421": "отель",
    "46422": "отец",
    "46423": "отечь",
    "46424": "отжиг",
    "46425": "отжим",
    "46426": "отзол",
    "46431": "отзыв",
    "46432": "разгадка",
    "46433": "отказ",
    "46434": "откат",
    "46435": "откол",
    "46436": "откос",
    "46441": "откуп",
    "46442": "отлив",
    "46443": "отлов",
    "46444": "отлуп",
    "46445": "отлёт",
    "46446": "относ",
    "46451": "ото",
    "46452": "отпор",
    "46453": "отрез",
    "46454": "отрог",
    "46455": "отрок",
    "46456": "отруб",
    "46461": "отрыв",
    "46462": "отряд",
    "46463": "отсев",
    "46464": "отсек",
    "46465": "отток",
    "46466": "оттёк",
    "46511": "отход",
    "46512": "отцов",
    "46513": "отчал",
    "46514": "отгадка",
    "46515": "наладка",
    "46516": "отчим",
    "46521": "отчёт",
    "46522": "отшиб",
    "46523": "отъём",
    "46524": "отёк",
    "46525": "отёл",
    "46526": "офеня",
    "46531": "офис",
    "46532": "офит",
    "46533": "офорт",
    "46534": "офсет",
    "46535": "охать",
    "46536": "охват",
    "46541": "охи",
    "46542": "охота",
    "46543": "охра",
    "46544": "охти",
    "46545": "оцет",
    "46546": "очаг",
    "46551": "очень",
    "46552": "очерк",
    "46553": "очи",
    "46554": "очки",
    "46555": "очко",
    "46556": "очный",
    "46561": "очёс",
    "46562": "ошеек",
    "46563": "ощупь",
    "46564": "павий",
    "46565": "падеж",
    "46566": "падуб",
    "46611": "падь",
    "46612": "падёж",
    "46613": "паж",
    "46614": "паз",
    "46615": "пай",
    "46616": "пайка",
    "46621": "пак",
    "46622": "пакет",
    "46623": "паки",
    "46624": "пакля",
    "46625": "пакт",
    "46626": "пал",
    "46631": "палас",
    "46632": "накладка",
    "46633": "палаш",
    "46634": "палец",
    "46635": "палея",
    "46636": "палка",
    "46641": "палый",
    "46642": "паль",
    "46643": "пампа",
    "46644": "пан",
    "46645": "пани",
    "46646": "панна",
    "46651": "панно",
    "46652": "панок",
    "46653": "панты",
    "46654": "папа",
    "46655": "папин",
    "46656": "папка",
    "46661": "пар",
    "46662": "пара",
    "46663": "парад",
    "46664": "парез",
    "46665": "пари",
    "46666": "парик",
    "51111": "пария",
    "51112": "парк",
    "51113": "парка",
    "51114": "парно",
    "51115": "паром",
    "51116": "парта",
    "51121": "парус",
    "51122": "парча",
    "51123": "парша",
    "51124": "пас",
    "51125": "паск",
    "51126": "пасмо",
    "51131": "пасс",
    "51132": "паста",
    "51133": "пасти",
    "51134": "пасть",
    "51135": "пасха",
    "51136": "пасюк",
    "51141": "пат",
    "51142": "патер",
    "51143": "патлы",
    "51144": "патуа",
    "51145": "пауза",
    "51146": "паук",
    "51151": "паут",
    "51152": "паф",
    "51153": "пафос",
    "51154": "вкладка",
    "51155": "пахта",
    "51156": "складка",
    "51161": "паче",
    "51162": "пачка",
    "51163": "паша",
    "51164": "пашня",
    "51165": "паюс",
    "51166": "паять",
    "51211": "паяц",
    "51212": "паёк",
    "51213": "пеан",
    "51214": "певец",
    "51215": "певун",
    "51216": "пегаш",
    "51221": "пегий",
    "51222": "пезо",
    "51223": "пейсы",
    "51224": "пек",
    "51225": "пекан",
    "51226": "пекло",
    "51231": "пелит",
    "51232": "пемза",
    "51233": "пена",
    "51234": "пенал",
    "51235": "пение",
    "51236": "пенка",
    "51241": "пенни",
    "51242": "пенс",
    "51243": "пень",
    "51244": "пеня",
    "51245": "пенёк",
    "51246": "пеон",
    "51251": "пепел",
    "51252": "пепин",
    "51253": "перга",
    "51254": "перед",
    "51255": "перец",
    "51256": "пери",
    "51261": "перл",
    "51262": "перо",
    "51263": "перс",
    "51264": "перси",
    "51265": "перст",
    "51266": "перун",
    "51311": "перш",
    "51312": "перёд",
    "51313": "песец",
    "51314": "песнь",
    "51315": "песня",
    "51316": "песо",
    "51321": "песок",
    "51322": "пест",
    "51323": "петит",
    "51324": "петля",
    "51325": "петух",
    "51326": "петый",
    "51331": "петь",
    "51332": "печка",
    "51333": "печь",
    "51334": "пеший",
    "51335": "пешка",
    "51336": "пешня",
    "51341": "пиала",
    "51342": "пиано",
    "51343": "шоколадка",
    "51344": "неполадка",
    "51345": "отладка",
    "51346": "пижма",
    "51351": "пижон",
    "51352": "пиит",
    "51353": "пик",
    "51354": "пика",
    "51355": "насадка",
    "51356": "пике",
    "51361": "пикет",
    "51362": "пики",
    "51363": "пикта",
    "51364": "пикша",
    "51365": "пила",
    "51366": "пилав",
    "51411": "пилка",
    "51412": "пилон",
    "51413": "пилот",
    "51414": "пиль",
    "51415": "пимы",
    "51416": "пиния",
    "51421": "рассадка",
    "51422": "пинта",
    "51423": "пион",
    "51424": "пир",
    "51425": "пират",
    "51426": "пирит",
    "51431": "пирке",
    "51432": "пирог",
    "51433": "пироп",
    "51434": "пирс",
    "51435": "писец",
    "51436": "писк",
    "51441": "питие",
    "51442": "питон",
    "51443": "питый",
    "51444": "пить",
    "51445": "питьё",
    "51446": "пихта",
    "51451": "пища",
    "51452": "пищик",
    "51453": "плав",
    "51454": "плавь",
    "51455": "плаз",
    "51456": "пламя",
    "51461": "план",
    "51462": "пласт",
    "51463": "плат",
    "51464": "плата",
    "51465": "плато",
    "51466": "плаун",
    "51511": "плаха",
    "51512": "плац",
    "51513": "плач",
    "51514": "плащ",
    "51515": "плебс",
    "51516": "плева",
    "51521": "плед",
    "51522": "племя",
    "51523": "плен",
    "51524": "плеск",
    "51525": "плеть",
    "51526": "плечо",
    "51531": "плешь",
    "51532": "пли",
    "51533": "плис",
    "51534": "плита",
    "51535": "плица",
    "51536": "плов",
    "51541": "плод",
    "51542": "плот",
    "51543": "площадка",
    "51544": "плохо",
    "51545": "плоше",
    "51546": "плуг",
    "51551": "плут",
    "51552": "плыть",
    "51553": "плюр",
    "51554": "плюс",
    "51555": "плюх",
    "51556": "разведка",
    "51561": "плюш",
    "51562": "плющ",
    "51563": "пляж",
    "51564": "пляс",
    "51565": "плёс",
    "51566": "плёсо",
    "51611": "беседка",
    "51612": "побег",
    "51613": "домосед",
    "51614": "побор",
    "51615": "повар",
    "51616": "повет",
    "51621": "повод",
    "51622": "погон",
    "51623": "под",
    "51624": "поди",
    "51625": "подий",
    "51626": "подле",
    "51631": "подло",
    "51632": "подо",
    "51633": "подол",
    "51634": "поезд",
    "51635": "пожар",
    "51636": "пожня",
    "51641": "соседка",
    "51642": "позже",
    "51643": "поездка",
    "51644": "позыв",
    "51645": "позём",
    "51646": "позёр",
    "51651": "поиск",
    "51652": "поить",
    "51653": "скидка",
    "51654": "пойма",
    "51655": "пойти",
    "51656": "пока",
    "51661": "показ",
    "51662": "покер",
    "51663": "покой",
    "51664": "покос",
    "51665": "пол",
    "51666": "пола",
    "52111": "полаб",
    "52112": "полба",
    "52113": "поле",
    "52114": "полив",
    "52115": "полип",
    "52116": "полис",
    "52121": "полк",
    "52122": "полка",
    "52123": "полно",
    "52124": "поло",
    "52125": "полог",
    "52126": "полоз",
    "52131": "полок",
    "52132": "полом",
    "52133": "полон",
    "52134": "полый",
    "52135": "полюс",
    "52136": "поляк",
    "52141": "полёт",
    "52142": "пом",
    "52143": "помин",
    "52144": "сводка",
    "52145": "помол",
    "52146": "помор",
    "52151": "помпа",
    "52152": "помёт",
    "52153": "пони",
    "52154": "подлодка",
    "52155": "понур",
    "52156": "пончо",
    "52161": "перегородка",
    "52162": "поп",
    "52163": "находка",
    "52164": "пора",
    "52165": "походка",
    "52166": "порей",
    "52211": "незабудка",
    "52212": "порог",
    "52213": "порой",
    "52214": "порок",
    "52215": "порох",
    "52216": "порою",
    "52221": "порск",
    "52222": "порт",
    "52223": "порто",
    "52224": "порты",
    "52225": "зарядка",
    "52226": "поры",
    "52231": "порыв",
    "52232": "посад",
    "52233": "посев",
    "52234": "после",
    "52235": "посол",
    "52236": "посох",
    "52241": "пост",
    "52242": "посул",
    "52243": "посыл",
    "52244": "пот",
    "52245": "поташ",
    "52246": "потир",
    "52251": "поток",
    "52252": "потом",
    "52253": "потоп",
    "52254": "потёк",
    "52255": "поход",
    "52256": "почва",
    "52261": "почин",
    "52262": "почка",
    "52263": "почта",
    "52264": "почти",
    "52265": "почто",
    "52266": "почём",
    "52311": "почёт",
    "52312": "пошиб",
    "52313": "пошив",
    "52314": "пошло",
    "52315": "поэма",
    "52316": "поэт",
    "52321": "пояс",
    "52322": "право",
    "52323": "издалека",
    "52324": "пращ",
    "52325": "праща",
    "52326": "пред",
    "52331": "предо",
    "52332": "прель",
    "52333": "пресс",
    "52334": "преть",
    "52335": "при",
    "52336": "приз",
    "52341": "прима",
    "52342": "принц",
    "52343": "приор",
    "52344": "причт",
    "52345": "приют",
    "52346": "приём",
    "52351": "про",
    "52352": "проба",
    "52353": "проза",
    "52354": "прок",
    "52355": "просо",
    "52356": "прочь",
    "52361": "проще",
    "52362": "проём",
    "52363": "пруд",
    "52364": "прус",
    "52365": "прут",
    "52366": "прыг",
    "52411": "прыть",
    "52412": "библиотека",
    "52413": "пря",
    "52414": "прядь",
    "52415": "пряжа",
    "52416": "прямо",
    "52421": "пряно",
    "52422": "пряха",
    "52423": "псарь",
    "52424": "ипотека",
    "52425": "фуражка",
    "52426": "птаха",
    "52431": "птица",
    "52432": "пробежка",
    "52433": "пуаз",
    "52434": "тележка",
    "52435": "пуд",
    "52436": "пудра",
    "52441": "сладкоежка",
    "52442": "сыроежка",
    "52443": "варежка",
    "52444": "пул",
    "52445": "пульс",
    "52446": "пульт",
    "52451": "застёжка",
    "52452": "пума",
    "52453": "пуна",
    "52454": "пункт",
    "52455": "пунш",
    "52456": "пуня",
    "52461": "пуп",
    "52462": "пупок",
    "52463": "задвижка",
    "52464": "сберкнижка",
    "52465": "пурин",
    "52466": "пуск",
    "52511": "пусто",
    "52512": "пусть",
    "52513": "путно",
    "52514": "путч",
    "52515": "путы",
    "52516": "путь",
    "52521": "путём",
    "52522": "пуф",
    "52523": "пух",
    "52524": "пучок",
    "52525": "пушка",
    "52526": "пушок",
    "52531": "пушта",
    "52532": "пушту",
    "52533": "пуща",
    "52534": "пуще",
    "52535": "пущий",
    "52536": "пхать",
    "52541": "пчела",
    "52542": "пшено",
    "52543": "пшик",
    "52544": "пшют",
    "52545": "пыж",
    "52546": "пыжик",
    "52551": "пыл",
    "52552": "пылко",
    "52553": "пыль",
    "52554": "пырей",
    "52555": "стрижка",
    "52556": "обложка",
    "52561": "велодорожка",
    "52562": "пышно",
    "52563": "пьеро",
    "52564": "пьеса",
    "52565": "пэр",
    "52566": "пюре",
    "52611": "пядь",
    "52612": "пяла",
    "52613": "пялка",
    "52614": "пяло",
    "52615": "пясть",
    "52616": "пята",
    "52621": "задержка",
    "52622": "пятка",
    "52623": "пятно",
    "52624": "пяток",
    "52625": "пятый",
    "52626": "пять",
    "52631": "пятью",
    "52632": "пёрка",
    "52633": "пёс",
    "52634": "пёсий",
    "52635": "пёсик",
    "52636": "поддержка",
    "52641": "издержка",
    "52642": "выдержка",
    "52643": "равно",
    "52644": "рагу",
    "52645": "рад",
    "52646": "рада",
    "52651": "радар",
    "52652": "раджа",
    "52653": "ради",
    "52654": "радий",
    "52655": "радио",
    "52656": "радон",
    "52661": "раж",
    "52662": "ражий",
    "52663": "раз",
    "52664": "разве",
    "52665": "разно",
    "52666": "разок",
    "53111": "разом",
    "53112": "разор",
    "53113": "разум",
    "53114": "раит",
    "53115": "рай",
    "53116": "район",
    "53121": "рак",
    "53122": "рака",
    "53123": "ракля",
    "53124": "ралли",
    "53125": "рало",
    "53126": "рама",
    "53131": "рами",
    "53132": "рамка",
    "53133": "рампа",
    "53134": "рамс",
    "53135": "рана",
    "53136": "ранг",
    "53141": "ране",
    "53142": "ранее",
    "53143": "ранет",
    "53144": "ранец",
    "53145": "рано",
    "53146": "рант",
    "53151": "ранчо",
    "53152": "рань",
    "53153": "рапа",
    "53154": "рапс",
    "53155": "раса",
    "53156": "расти",
    "53161": "растр",
    "53162": "ратай",
    "53163": "ратин",
    "53164": "рать",
    "53165": "раунд",
    "53166": "раут",
    "53211": "рафия",
    "53212": "рахит",
    "53213": "рацея",
    "53214": "рация",
    "53215": "рачий",
    "53216": "раёк",
    "53221": "рвань",
    "53222": "рвать",
    "53223": "рвач",
    "53224": "кружка",
    "53225": "рдест",
    "53226": "рдеть",
    "53231": "реал",
    "53232": "ребро",
    "53233": "ребус",
    "53234": "ревмя",
    "53235": "ревун",
    "53236": "ревю",
    "53241": "регби",
    "53242": "редан",
    "53243": "редис",
    "53244": "редко",
    "53245": "редут",
    "53246": "реже",
    "53251": "режим",
    "53252": "резак",
    "53253": "резво",
    "53254": "резец",
    "53255": "резка",
    "53256": "резко",
    "53261": "резня",
    "53262": "резон",
    "53263": "резус",
    "53264": "резь",
    "53265": "рей",
    "53266": "рейд",
    "53311": "рейка",
    "53312": "рейс",
    "53313": "река",
    "53314": "реле",
    "53315": "релин",
    "53316": "рельс",
    "53321": "ремез",
    "53322": "ремиз",
    "53323": "ренет",
    "53324": "рений",
    "53325": "ренин",
    "53326": "рента",
    "53331": "репа",
    "53332": "репей",
    "53333": "репер",
    "53334": "репс",
    "53335": "ретро",
    "53336": "речка",
    "53341": "речь",
    "53342": "решка",
    "53343": "рея",
    "53344": "реять",
    "53345": "ржа",
    "53346": "стружка",
    "53351": "ржище",
    "53352": "риал",
    "53353": "рига",
    "53354": "риза",
    "53355": "рикша",
    "53356": "ринг",
    "53361": "ринит",
    "53362": "рипс",
    "53363": "рипус",
    "53364": "рис",
    "53365": "риск",
    "53366": "риска",
    "53411": "ритм",
    "53412": "ритор",
    "53413": "риф",
    "53414": "рифли",
    "53415": "рифма",
    "53416": "роба",
    "53421": "робко",
    "53422": "робот",
    "53423": "ров",
    "53424": "ровик",
    "53425": "ровно",
    "53426": "ровня",
    "53431": "рог",
    "53432": "рогач",
    "53433": "рогоз",
    "53434": "род",
    "53435": "родео",
    "53436": "родий",
    "53441": "родич",
    "53442": "родня",
    "53443": "лодыжка",
    "53444": "фляжка",
    "53445": "рожок",
    "53446": "рожон",
    "53451": "рожь",
    "53452": "роза",
    "53453": "розан",
    "53454": "розга",
    "53455": "розно",
    "53456": "рознь",
    "53461": "роить",
    "53462": "рой",
    "53463": "рок",
    "53464": "рокер",
    "53465": "рокот",
    "53466": "рол",
    "53511": "ролик",
    "53512": "роль",
    "53513": "роля",
    "53514": "ром",
    "53515": "роман",
    "53516": "ромб",
    "53521": "рондо",
    "53522": "ронжа",
    "53523": "ропак",
    "53524": "ропот",
    "53525": "роса",
    "53526": "росс",
    "53531": "рост",
    "53532": "ростр",
    "53533": "рот",
    "53534": "рота",
    "53535": "ротон",
    "53536": "ротор",
    "53541": "рохля",
    "53542": "роща",
    "53543": "рояль",
    "53544": "ртуть",
    "53545": "рубеж",
    "53546": "рубец",
    "53551": "рубин",
    "53552": "рубка",
    "53553": "рубль",
    "53554": "ругня",
    "53555": "руда",
    "53556": "рудой",
    "53561": "рудый",
    "53562": "рудяк",
    "53563": "ружьё",
    "53564": "руина",
    "53565": "рука",
    "53566": "рукав",
    "53611": "рулет",
    "53612": "рулон",
    "53613": "руль",
    "53614": "румб",
    "53615": "румба",
    "53616": "румын",
    "53621": "рунец",
    "53622": "руно",
    "53623": "руны",
    "53624": "рупия",
    "53625": "рупор",
    "53626": "русак",
    "53631": "русин",
    "53632": "русло",
    "53633": "руст",
    "53634": "русый",
    "53635": "рута",
    "53636": "рутил",
    "53641": "рутин",
    "53642": "ручей",
    "53643": "ручка",
    "53644": "рыба",
    "53645": "рыбак",
    "53646": "рыбий",
    "53651": "рыбка",
    "53652": "рывок",
    "53653": "рыжий",
    "53654": "рыжик",
    "53655": "рык",
    "53656": "рыло",
    "53661": "рым",
    "53662": "рында",
    "53663": "рынок",
    "53664": "рысак",
    "53665": "рысий",
    "53666": "рыск",
    "54111": "рысца",
    "54112": "рысь",
    "54113": "рысью",
    "54114": "рытый",
    "54115": "рыть",
    "54116": "рытьё",
    "54121": "рыхло",
    "54122": "рычаг",
    "54123": "рьяно",
    "54124": "пряжка",
    "54125": "рюмка",
    "54126": "рюха",
    "54131": "рюхи",
    "54132": "рюш",
    "54133": "рябой",
    "54134": "рябь",
    "54135": "ряд",
    "54136": "рядно",
    "54141": "рядом",
    "54142": "ряса",
    "54143": "ряска",
    "54144": "ряст",
    "54145": "ряшка",
    "54146": "рёв",
    "54151": "рёва",
    "54152": "рёлка",
    "54153": "саам",
    "54154": "саами",
    "54155": "саамы",
    "54156": "сабля",
    "54161": "сабо",
    "54162": "сабур",
    "54163": "саван",
    "54164": "сага",
    "54165": "сагиб",
    "54166": "саго",
    "54211": "сад",
    "54212": "садик",
    "54213": "садка",
    "54214": "садки",
    "54215": "садок",
    "54216": "саж",
    "54221": "сажа",
    "54222": "саз",
    "54223": "сазан",
    "54224": "саиб",
    "54225": "сайга",
    "54226": "сайда",
    "54231": "сайка",
    "54232": "сайра",
    "54233": "сак",
    "54234": "саква",
    "54235": "сакля",
    "54236": "сакс",
    "54241": "саксы",
    "54242": "салат",
    "54243": "салеп",
    "54244": "салки",
    "54245": "сало",
    "54246": "салол",
    "54251": "салон",
    "54252": "салоп",
    "54253": "салют",
    "54254": "сам",
    "54255": "сама",
    "54256": "саман",
    "54261": "самая",
    "54262": "самбо",
    "54263": "самец",
    "54264": "сами",
    "54265": "самка",
    "54266": "само",
    "54311": "самум",
    "54312": "самые",
    "54313": "самый",
    "54314": "сан",
    "54315": "сани",
    "54316": "санки",
    "54321": "сап",
    "54322": "сапа",
    "54323": "сапка",
    "54324": "сапог",
    "54325": "сапун",
    "54326": "сапёр",
    "54331": "сарай",
    "54332": "саржа",
    "54333": "сарыч",
    "54334": "сатин",
    "54335": "сатир",
    "54336": "сауна",
    "54341": "сафой",
    "54342": "сахар",
    "54343": "сачок",
    "54344": "саше",
    "54345": "сбег",
    "54346": "сбить",
    "54351": "сбой",
    "54352": "сбоку",
    "54353": "сбор",
    "54354": "сборы",
    "54355": "сброд",
    "54356": "сброс",
    "54361": "сбруя",
    "54362": "сбыт",
    "54363": "сбыть",
    "54364": "свал",
    "54365": "сван",
    "54366": "свара",
    "54411": "сват",
    "54412": "сваха",
    "54413": "свая",
    "54414": "свежо",
    "54415": "сверх",
    "54416": "свес",
    "54421": "свет",
    "54422": "свеча",
    "54423": "свиль",
    "54424": "свист",
    "54425": "свита",
    "54426": "свить",
    "54431": "свих",
    "54432": "свищ",
    "54433": "свод",
    "54434": "своз",
    "54435": "свои",
    "54436": "свой",
    "54441": "свора",
    "54442": "своя",
    "54443": "свояк",
    "54444": "своё",
    "54445": "свыше",
    "54446": "связь",
    "54451": "свято",
    "54452": "сгиб",
    "54453": "сглаз",
    "54454": "сгон",
    "54455": "сдать",
    "54456": "сдача",
    "54461": "сдвиг",
    "54462": "сдоба",
    "54463": "сдуру",
    "54464": "сдуть",
    "54465": "сеанс",
    "54466": "себе",
    "54511": "себя",
    "54512": "сев",
    "54513": "север",
    "54514": "севец",
    "54515": "севок",
    "54516": "севр",
    "54521": "седло",
    "54522": "седой",
    "54523": "седок",
    "54524": "сезам",
    "54525": "сезон",
    "54526": "сей",
    "54531": "сейм",
    "54532": "сейф",
    "54533": "сейша",
    "54534": "секач",
    "54535": "вытяжка",
    "54536": "сказка",
    "54541": "селен",
    "54542": "селин",
    "54543": "село",
    "54544": "сель",
    "54545": "семик",
    "54546": "семит",
    "54551": "семь",
    "54552": "семью",
    "54553": "семья",
    "54554": "семя",
    "54555": "сенаж",
    "54556": "сенат",
    "54561": "сени",
    "54562": "сено",
    "54563": "сенцы",
    "54564": "сень",
    "54565": "сепия",
    "54566": "сера",
    "54611": "серб",
    "54612": "серв",
    "54613": "серия",
    "54614": "серна",
    "54615": "серп",
    "54616": "серсо",
    "54621": "серум",
    "54622": "серый",
    "54623": "сесть",
    "54624": "сет",
    "54625": "сетка",
    "54626": "сеть",
    "54631": "сеча",
    "54632": "сечка",
    "54633": "сечь",
    "54634": "сеять",
    "54635": "сжато",
    "54636": "сжать",
    "54641": "сжечь",
    "54642": "сжим",
    "54643": "сжить",
    "54644": "сзади",
    "54645": "сзаду",
    "54646": "сивер",
    "54651": "сивка",
    "54652": "сивко",
    "54653": "сивуч",
    "54654": "сивый",
    "54655": "сиг",
    "54656": "сигма",
    "54661": "сидр",
    "54662": "сие",
    "54663": "сиена",
    "54664": "сижа",
    "54665": "сизый",
    "54666": "сизяк",
    "55111": "сикоз",
    "55112": "сикх",
    "55113": "сила",
    "55114": "силач",
    "55115": "силен",
    "55116": "силой",
    "55121": "силок",
    "55122": "силом",
    "55123": "силон",
    "55124": "силос",
    "55125": "силою",
    "55126": "силур",
    "55131": "сильф",
    "55132": "синап",
    "55133": "синец",
    "55134": "синий",
    "55135": "синод",
    "55136": "синус",
    "55141": "синь",
    "55142": "синяк",
    "55143": "сип",
    "55144": "сипай",
    "55145": "сирин",
    "55146": "сироп",
    "55151": "сирый",
    "55152": "ситар",
    "55153": "ситец",
    "55154": "сито",
    "55155": "ситро",
    "55156": "сифон",
    "55161": "сия",
    "55162": "сиять",
    "55163": "сказ",
    "55164": "скала",
    "55165": "скарб",
    "55166": "скарн",
    "55211": "скат",
    "55212": "скаут",
    "55213": "сквер",
    "55214": "скетч",
    "55215": "скип",
    "55216": "скирд",
    "55221": "скит",
    "55222": "скифы",
    "55223": "склад",
    "55224": "склеп",
    "55225": "склон",
    "55226": "скоба",
    "55231": "сков",
    "55232": "скок",
    "55233": "скол",
    "55234": "сколь",
    "55235": "скоп",
    "55236": "скопа",
    "55241": "скорм",
    "55242": "скоро",
    "55243": "скос",
    "55244": "скот",
    "55245": "скрап",
    "55246": "скрип",
    "55251": "скука",
    "55252": "скула",
    "55253": "скунс",
    "55254": "скупо",
    "55255": "слабо",
    "55256": "слава",
    "55261": "слад",
    "55262": "слайд",
    "55263": "слать",
    "55264": "слаще",
    "55265": "слева",
    "55266": "слега",
    "55311": "след",
    "55312": "слеза",
    "55313": "слепо",
    "55314": "слечь",
    "55315": "слив",
    "55316": "слива",
    "55321": "слизь",
    "55322": "слип",
    "55323": "слить",
    "55324": "слово",
    "55325": "слог",
    "55326": "слой",
    "55331": "слом",
    "55332": "слон",
    "55333": "слуга",
    "55334": "слух",
    "55335": "слыть",
    "55336": "слышь",
    "55341": "слюда",
    "55342": "слюна",
    "55343": "слюни",
    "55344": "сляб",
    "55345": "слёт",
    "55346": "смак",
    "55351": "смалу",
    "55352": "смело",
    "55353": "смена",
    "55354": "смерд",
    "55355": "смерч",
    "55356": "смесь",
    "55361": "смета",
    "55362": "сметь",
    "55363": "смех",
    "55364": "смог",
    "55365": "смола",
    "55366": "смоль",
    "55411": "смотр",
    "55412": "смочь",
    "55413": "смрад",
    "55414": "смута",
    "55415": "смыв",
    "55416": "смык",
    "55421": "смысл",
    "55422": "смыть",
    "55423": "смять",
    "55424": "снег",
    "55425": "снедь",
    "55426": "снизу",
    "55431": "сноб",
    "55432": "снова",
    "55433": "сноп",
    "55434": "снос",
    "55435": "сноха",
    "55436": "сныть",
    "55441": "сныч",
    "55442": "снять",
    "55443": "собес",
    "55444": "собой",
    "55445": "собор",
    "55446": "собою",
    "55451": "сова",
    "55452": "совет",
    "55453": "совка",
    "55454": "совок",
    "55455": "сода",
    "55456": "содом",
    "55461": "созыв",
    "55462": "сойка",
    "55463": "сойти",
    "55464": "сок",
    "55465": "сокол",
    "55466": "солея",
    "55511": "солка",
    "55512": "соло",
    "55513": "солод",
    "55514": "соль",
    "55515": "сом",
    "55516": "сома",
    "55521": "сон",
    "55522": "сонет",
    "55523": "сонм",
    "55524": "сонно",
    "55525": "соня",
    "55526": "сопка",
    "55531": "подсказка",
    "55532": "сопло",
    "55533": "сор",
    "55534": "сорго",
    "55535": "сорок",
    "55536": "сорт",
    "55541": "сорус",
    "55542": "сосед",
    "55543": "указка",
    "55544": "сосна",
    "55545": "нарезка",
    "55546": "сосуд",
    "55551": "хлеборезка",
    "55552": "соте",
    "55553": "сотка",
    "55554": "сотня",
    "55555": "соты",
    "55556": "сотый",
    "55561": "соус",
    "55562": "софа",
    "55563": "софит",
    "55564": "соха",
    "55565": "сошка",
    "55566": "союз",
    "55611": "соя",
    "55612": "спад",
    "55613": "спазм",
    "55614": "спай",
    "55615": "спас",
    "55616": "спать",
    "55621": "спесь",
    "55622": "спеть",
    "55623": "спех",
    "55624": "спец",
    "55625": "спин",
    "55626": "спина",
    "55631": "спирт",
    "55632": "спить",
    "55633": "спица",
    "55634": "спич",
    "55635": "сплав",
    "55636": "сплин",
    "55641": "спор",
    "55642": "спора",
    "55643": "споро",
    "55644": "спорт",
    "55645": "спрос",
    "55646": "спрут",
    "55651": "спуд",
    "55652": "спурт",
    "55653": "спуск",
    "55654": "сразу",
    "55655": "срам",
    "55656": "среда",
    "55661": "среди",
    "55662": "средь",
    "55663": "срез",
    "55664": "сроду",
    "55665": "срок",
    "55666": "срост",
    "56111": "сруб",
    "56112": "срыв",
    "56113": "срыть",
    "56114": "сряду",
    "56115": "ссек",
    "56116": "ссечь",
    "56121": "ссора",
    "56122": "став",
    "56123": "стадо",
    "56124": "стаж",
    "56125": "стаз",
    "56126": "стаза",
    "56131": "сталь",
    "56132": "стан",
    "56133": "станс",
    "56134": "старт",
    "56135": "стать",
    "56136": "стая",
    "56141": "ствол",
    "56142": "створ",
    "56143": "стезя",
    "56144": "стек",
    "56145": "стела",
    "56146": "стен",
    "56151": "стена",
    "56152": "стенд",
    "56153": "степь",
    "56154": "стерх",
    "56155": "стечь",
    "56156": "стило",
    "56161": "стиль",
    "56162": "стих",
    "56163": "сто",
    "56164": "стог",
    "56165": "стоик",
    "56166": "сток",
    "56211": "стол",
    "56212": "столб",
    "56213": "столп",
    "56214": "столь",
    "56215": "стон",
    "56216": "стоп",
    "56221": "стопа",
    "56222": "стояк",
    "56223": "страж",
    "56224": "страз",
    "56225": "страх",
    "56226": "стриж",
    "56231": "строй",
    "56232": "строп",
    "56233": "струг",
    "56234": "перевозка",
    "56235": "струя",
    "56236": "стужа",
    "56241": "стук",
    "56242": "стул",
    "56243": "ступа",
    "56244": "стыд",
    "56245": "стык",
    "56246": "стыть",
    "56251": "стяг",
    "56252": "суд",
    "56253": "судак",
    "56254": "судно",
    "56255": "судок",
    "56256": "судья",
    "56261": "суета",
    "56262": "сук",
    "56263": "повозка",
    "56264": "загрузка",
    "56265": "сукно",
    "56266": "сулея",
    "56311": "сума",
    "56312": "сумах",
    "56313": "сумка",
    "56314": "сумма",
    "56315": "сумёт",
    "56316": "сунна",
    "56321": "суп",
    "56322": "супин",
    "56323": "суржа",
    "56324": "сурик",
    "56325": "сурок",
    "56326": "сусак",
    "56331": "сусек",
    "56332": "сусло",
    "56333": "сутки",
    "56334": "суть",
    "56335": "суфле",
    "56336": "сухо",
    "56341": "сухой",
    "56342": "нагрузка",
    "56343": "перегрузка",
    "56344": "развязка",
    "56345": "суша",
    "56346": "суше",
    "56351": "сушка",
    "56352": "сушь",
    "56353": "сущее",
    "56354": "сущий",
    "56355": "сфера",
    "56356": "схема",
    "56361": "схима",
    "56362": "сход",
    "56363": "сцена",
    "56364": "сцеп",
    "56365": "счёс",
    "56366": "счёт",
    "56411": "счёты",
    "56412": "сшить",
    "56413": "съезд",
    "56414": "съём",
    "56415": "сын",
    "56416": "сынок",
    "56421": "сыпец",
    "56422": "сыпь",
    "56423": "сыр",
    "56424": "сырее",
    "56425": "сырец",
    "56426": "сыро",
    "56431": "сырой",
    "56432": "сырок",
    "56433": "сырт",
    "56434": "сырть",
    "56435": "сырьё",
    "56436": "сыск",
    "56441": "сыта",
    "56442": "сытно",
    "56443": "сытый",
    "56444": "сыть",
    "56445": "сыч",
    "56446": "сычуг",
    "56451": "сыщик",
    "56452": "сэр",
    "56453": "сюда",
    "56454": "сюжет",
    "56455": "сюита",
    "56456": "сяг",
    "56461": "сяжок",
    "56462": "сяк",
    "56463": "сякой",
    "56464": "сям",
    "56465": "сёмга",
    "56466": "т.е.",
    "56511": "табак",
    "56512": "табес",
    "56513": "табло",
    "56514": "табор",
    "56515": "табу",
    "56516": "табун",
    "56521": "тавот",
    "56522": "тавро",
    "56523": "таган",
    "56524": "таз",
    "56525": "тазик",
    "56526": "таи",
    "56531": "таить",
    "56532": "тайга",
    "56533": "тайм",
    "56534": "тайна",
    "56535": "тайно",
    "56536": "так",
    "56541": "такая",
    "56542": "также",
    "56543": "таки",
    "56544": "такие",
    "56545": "таков",
    "56546": "такое",
    "56551": "такой",
    "56552": "такса",
    "56553": "такси",
    "56554": "такт",
    "56555": "такыр",
    "56556": "тал",
    "56561": "талер",
    "56562": "тали",
    "56563": "талик",
    "56564": "талия",
    "56565": "талон",
    "56566": "талый",
    "56611": "талыш",
    "56612": "таль",
    "56613": "тальк",
    "56614": "там",
    "56615": "тамга",
    "56616": "тамил",
    "56621": "танго",
    "56622": "танец",
    "56623": "танин",
    "56624": "танк",
    "56625": "тапир",
    "56626": "тапки",
    "56631": "тапёр",
    "56632": "тара",
    "56633": "таран",
    "56634": "тари",
    "56635": "тариф",
    "56636": "таска",
    "56641": "тат",
    "56642": "татка",
    "56643": "таты",
    "56644": "тать",
    "56645": "тафта",
    "56646": "тафья",
    "56651": "тахта",
    "56652": "тачка",
    "56653": "таять",
    "56654": "повязка",
    "56655": "твид",
    "56656": "твин",
    "56661": "твист",
    "56662": "твои",
    "56663": "твой",
    "56664": "твоя",
    "56665": "твоё",
    "56666": "театр",
    "61111": "тебе",
    "61112": "тебя",
    "61113": "теза",
    "61114": "тезис",
    "61115": "теизм",
    "61116": "теин",
    "61121": "теист",
    "61122": "текст",
    "61123": "телец",
    "61124": "тело",
    "61125": "телок",
    "61126": "тем",
    "61131": "тема",
    "61132": "тембр",
    "61133": "теми",
    "61134": "темно",
    "61135": "темп",
    "61136": "темь",
    "61141": "темя",
    "61142": "тенор",
    "61143": "тент",
    "61144": "тень",
    "61145": "тенёк",
    "61146": "тепло",
    "61151": "терем",
    "61152": "термы",
    "61153": "тесак",
    "61154": "тесло",
    "61155": "тесно",
    "61156": "тест",
    "61161": "тесто",
    "61162": "тесть",
    "61163": "тех",
    "61164": "связка",
    "61165": "течь",
    "61166": "тиара",
    "61211": "тигр",
    "61212": "тик",
    "61213": "тимол",
    "61214": "тимус",
    "61215": "тина",
    "61216": "тип",
    "61221": "типаж",
    "61222": "типун",
    "61223": "тир",
    "61224": "тираж",
    "61225": "тиран",
    "61226": "тире",
    "61231": "тирс",
    "61232": "тис",
    "61233": "тиски",
    "61234": "титан",
    "61235": "титло",
    "61236": "титр",
    "61241": "титул",
    "61242": "тиун",
    "61243": "тиф",
    "61244": "тифон",
    "61245": "тифус",
    "61246": "тихий",
    "61251": "тихо",
    "61252": "тише",
    "61253": "тишь",
    "61254": "ткань",
    "61255": "ткать",
    "61256": "ткач",
    "61261": "тлен",
    "61262": "тлеть",
    "61263": "тля",
    "61264": "тмин",
    "61265": "тобой",
    "61266": "тобою",
    "61311": "товар",
    "61312": "тога",
    "61313": "тогда",
    "61314": "того",
    "61315": "тож",
    "61316": "тоже",
    "61321": "ток",
    "61322": "токай",
    "61323": "токмо",
    "61324": "тол",
    "61325": "толк",
    "61326": "толпа",
    "61331": "толща",
    "61332": "толще",
    "61333": "толь",
    "61334": "том",
    "61335": "томат",
    "61336": "томно",
    "61341": "тому",
    "61342": "тон",
    "61343": "тонин",
    "61344": "тонко",
    "61345": "тонна",
    "61346": "тонус",
    "61351": "тоня",
    "61352": "топ",
    "61353": "топаз",
    "61354": "топка",
    "61355": "топко",
    "61356": "топор",
    "61361": "топот",
    "61362": "топь",
    "61363": "тор",
    "61364": "тора",
    "61365": "торба",
    "61366": "торг",
    "61411": "торги",
    "61412": "торец",
    "61413": "тори",
    "61414": "торий",
    "61415": "торит",
    "61416": "торк",
    "61421": "торос",
    "61422": "торс",
    "61423": "торт",
    "61424": "торф",
    "61425": "тоска",
    "61426": "тост",
    "61431": "тот",
    "61432": "тотем",
    "61433": "точка",
    "61434": "точно",
    "61435": "точь",
    "61436": "тошно",
    "61441": "тощий",
    "61442": "тпрр",
    "61443": "тпру",
    "61444": "трава",
    "61445": "травы",
    "61446": "трак",
    "61451": "тракт",
    "61452": "трал",
    "61453": "транс",
    "61454": "трап",
    "61455": "неувязка",
    "61456": "трас",
    "61461": "трата",
    "61462": "траур",
    "61463": "мозаика",
    "61464": "треба",
    "61465": "трек",
    "61466": "трель",
    "61511": "трен",
    "61512": "треск",
    "61513": "трест",
    "61514": "треть",
    "61515": "трефа",
    "61516": "трефы",
    "61521": "три",
    "61522": "триас",
    "61523": "триба",
    "61524": "триер",
    "61525": "тризм",
    "61526": "трико",
    "61531": "трио",
    "61532": "триод",
    "61533": "голубика",
    "61534": "трог",
    "61535": "трое",
    "61536": "трок",
    "61541": "ежевика",
    "61542": "трон",
    "61543": "троп",
    "61544": "тропа",
    "61545": "трос",
    "61546": "трот",
    "61551": "труба",
    "61552": "труд",
    "61553": "педагогика",
    "61554": "логика",
    "61555": "гвоздика",
    "61556": "трут",
    "61561": "труха",
    "61562": "трюк",
    "61563": "трюм",
    "61564": "трюмо",
    "61565": "тсс",
    "61566": "туба",
    "61611": "тубус",
    "61612": "туго",
    "61613": "тугой",
    "61614": "туда",
    "61615": "туер",
    "61616": "туес",
    "61621": "туже",
    "61622": "туз",
    "61623": "тук",
    "61624": "тукан",
    "61625": "тулий",
    "61626": "тулуп",
    "61631": "тулья",
    "61632": "тумак",
    "61633": "туман",
    "61634": "тумба",
    "61635": "тун",
    "61636": "тунг",
    "61641": "тунец",
    "61642": "тупей",
    "61643": "тупик",
    "61644": "методика",
    "61645": "физика",
    "61646": "тур",
    "61651": "тура",
    "61652": "турач",
    "61653": "турий",
    "61654": "турне",
    "61655": "турок",
    "61656": "турча",
    "61661": "тут",
    "61662": "туф",
    "61663": "туфля",
    "61664": "туча",
    "61665": "тучка",
    "61666": "туш",
    "62111": "публика",
    "62112": "туше",
    "62113": "республика",
    "62114": "тушь",
    "62115": "туэр",
    "62116": "туя",
    "62121": "тшш",
    "62122": "тщета",
    "62123": "тык",
    "62124": "тыква",
    "62125": "тыл",
    "62126": "тын",
    "62131": "тырса",
    "62132": "тычок",
    "62133": "тьма",
    "62134": "тьфу",
    "62135": "тюбик",
    "62136": "тювик",
    "62141": "тюз",
    "62142": "тюк",
    "62143": "тюль",
    "62144": "тюник",
    "62145": "тюрбо",
    "62146": "тюрк",
    "62151": "тюря",
    "62152": "гидравлика",
    "62153": "тючок",
    "62154": "тяв",
    "62155": "тяга",
    "62156": "тягач",
    "62161": "тягло",
    "62162": "тягун",
    "62163": "тяж",
    "62164": "тяжба",
    "62165": "тяп",
    "62166": "тяпка",
    "62211": "тятя",
    "62212": "тёзка",
    "62213": "динамика",
    "62214": "тёрка",
    "62215": "тёрн",
    "62216": "тёс",
    "62221": "тёска",
    "62222": "тётка",
    "62223": "тётя",
    "62224": "тёшка",
    "62225": "тёща",
    "62226": "уатт",
    "62231": "керамика",
    "62232": "мимика",
    "62233": "экономика",
    "62234": "убор",
    "62235": "убыль",
    "62236": "убыть",
    "62241": "увал",
    "62242": "увар",
    "62243": "увить",
    "62244": "увод",
    "62245": "увоз",
    "62246": "увы",
    "62251": "паника",
    "62252": "угода",
    "62253": "угол",
    "62254": "уголь",
    "62255": "механика",
    "62256": "угорь",
    "62261": "угры",
    "62262": "уда",
    "62263": "удав",
    "62264": "удаль",
    "62265": "удар",
    "62266": "удача",
    "62311": "удел",
    "62312": "удерж",
    "62313": "удила",
    "62314": "удить",
    "62315": "удод",
    "62316": "удой",
    "62321": "удэ",
    "62322": "удэге",
    "62323": "удэхе",
    "62324": "уезд",
    "62325": "уесть",
    "62326": "ужас",
    "62331": "ужать",
    "62332": "уже",
    "62333": "ужели",
    "62334": "ужель",
    "62335": "ужин",
    "62336": "ужли",
    "62341": "ужо",
    "62342": "уза",
    "62343": "узбек",
    "62344": "узда",
    "62345": "уздцы",
    "62346": "узел",
    "62351": "узить",
    "62352": "узкий",
    "62353": "узко",
    "62354": "узник",
    "62355": "узор",
    "62356": "узус",
    "62361": "узы",
    "62362": "уйгур",
    "62363": "уйма",
    "62364": "уйти",
    "62365": "указ",
    "62366": "уклад",
    "62411": "уклон",
    "62412": "укол",
    "62413": "уком",
    "62414": "укор",
    "62415": "укос",
    "62416": "укроп",
    "62421": "уксус",
    "62422": "укус",
    "62423": "улан",
    "62424": "улар",
    "62425": "улей",
    "62426": "улика",
    "62431": "улита",
    "62432": "улить",
    "62433": "улица",
    "62434": "улов",
    "62435": "улус",
    "62436": "ульч",
    "62441": "улюлю",
    "62442": "умбра",
    "62443": "умбры",
    "62444": "умело",
    "62445": "уметь",
    "62446": "умнее",
    "62451": "умник",
    "62452": "умно",
    "62453": "умный",
    "62454": "умолк",
    "62455": "умора",
    "62456": "умыть",
    "62461": "умять",
    "62462": "униат",
    "62463": "уник",
    "62464": "уния",
    "62465": "унос",
    "62466": "унтер",
    "62511": "унты",
    "62512": "унция",
    "62513": "уныло",
    "62514": "унять",
    "62515": "упад",
    "62516": "упечь",
    "62521": "упор",
    "62522": "упрёк",
    "62523": "клубника",
    "62524": "упёк",
    "62525": "ура",
    "62526": "уран",
    "62531": "урат",
    "62532": "урду",
    "62533": "урез",
    "62534": "урема",
    "62535": "клиника",
    "62536": "урна",
    "62541": "электроника",
    "62542": "урок",
    "62543": "урон",
    "62544": "уруть",
    "62545": "урюк",
    "62546": "урёма",
    "62551": "усач",
    "62552": "усечь",
    "62553": "усики",
    "62554": "усол",
    "62555": "успех",
    "62556": "уста",
    "62561": "устав",
    "62562": "устно",
    "62563": "устои",
    "62564": "устой",
    "62565": "уступ",
    "62566": "устье",
    "62611": "усы",
    "62612": "утеря",
    "62613": "утеха",
    "62614": "утечь",
    "62615": "утиль",
    "62616": "утка",
    "62621": "утлый",
    "62622": "уток",
    "62623": "утор",
    "62624": "уторы",
    "62625": "утро",
    "62626": "утром",
    "62631": "утюг",
    "62632": "утёс",
    "62633": "уха",
    "62634": "ухаб",
    "62635": "ухарь",
    "62636": "ухать",
    "62641": "ухват",
    "62642": "ухо",
    "62643": "уход",
    "62644": "учить",
    "62645": "учком",
    "62646": "учхоз",
    "62651": "учёба",
    "62652": "учёс",
    "62653": "учёт",
    "62654": "ушан",
    "62655": "ушат",
    "62656": "ушиб",
    "62661": "ушить",
    "62662": "ушица",
    "62663": "ушко",
    "62664": "ушкуй",
    "62665": "ушник",
    "62666": "ушной",
    "63111": "ущерб",
    "63112": "уют",
    "63113": "уютно",
    "63114": "фавн",
    "63115": "фавор",
    "63116": "фаг",
    "63121": "фагот",
    "63122": "фаза",
    "63123": "фазан",
    "63124": "фазис",
    "63125": "фай",
    "63126": "факел",
    "63131": "хроника",
    "63132": "факс",
    "63133": "факт",
    "63134": "фал",
    "63135": "фалда",
    "63136": "фальц",
    "63141": "фанат",
    "63142": "фанза",
    "63143": "фант",
    "63144": "фанты",
    "63145": "фара",
    "63146": "фарс",
    "63151": "фарт",
    "63152": "фарш",
    "63153": "фас",
    "63154": "фасад",
    "63155": "фасет",
    "63156": "фаска",
    "63161": "фасон",
    "63162": "фат",
    "63163": "фата",
    "63164": "фатум",
    "63165": "фауна",
    "63166": "фацет",
    "63211": "фация",
    "63212": "фаянс",
    "63213": "фен",
    "63214": "фенил",
    "63215": "фенол",
    "63216": "феод",
    "63221": "ферзь",
    "63222": "ферма",
    "63223": "ферт",
    "63224": "феска",
    "63225": "черника",
    "63226": "фетр",
    "63231": "фея",
    "63232": "фиакр",
    "63233": "фибра",
    "63234": "брусника",
    "63235": "фидер",
    "63236": "фижмы",
    "63241": "физик",
    "63242": "фикс",
    "63243": "фикус",
    "63244": "филе",
    "63245": "филей",
    "63246": "филин",
    "63251": "фильм",
    "63252": "филёр",
    "63253": "фимоз",
    "63254": "финал",
    "63255": "финик",
    "63256": "финиш",
    "63261": "финка",
    "63262": "финн",
    "63263": "финна",
    "63264": "фиорд",
    "63265": "фирма",
    "63266": "фирн",
    "63311": "фиск",
    "63312": "фита",
    "63313": "фитин",
    "63314": "техника",
    "63315": "фифи",
    "63316": "фишка",
    "63321": "флаг",
    "63322": "фланг",
    "63323": "фланк",
    "63324": "флат",
    "63325": "флейц",
    "63326": "флешь",
    "63331": "флирт",
    "63332": "флокс",
    "63333": "флора",
    "63334": "флот",
    "63335": "флюид",
    "63336": "флюс",
    "63341": "фляга",
    "63342": "флёр",
    "63343": "фобия",
    "63344": "фойе",
    "63345": "фок",
    "63346": "фокус",
    "63351": "фол",
    "63352": "фолио",
    "63353": "фомка",
    "63354": "фон",
    "63355": "фонд",
    "63356": "фонды",
    "63361": "фора",
    "63362": "форд",
    "63363": "форма",
    "63364": "форс",
    "63365": "форт",
    "63366": "форте",
    "63411": "форум",
    "63412": "фоска",
    "63413": "фот",
    "63414": "фото",
    "63415": "фотон",
    "63416": "фофан",
    "63421": "фраза",
    "63422": "фрак",
    "63423": "франк",
    "63424": "франт",
    "63425": "фрау",
    "63426": "фрахт",
    "63431": "фрез",
    "63432": "фреза",
    "63433": "френч",
    "63434": "фреон",
    "63435": "фриз",
    "63436": "фронт",
    "63441": "фрукт",
    "63442": "фтор",
    "63443": "фуга",
    "63444": "фугас",
    "63445": "фужер",
    "63446": "фузея",
    "63451": "фузия",
    "63452": "фуй",
    "63453": "фук",
    "63454": "фукус",
    "63455": "фуле",
    "63456": "фуляр",
    "63461": "фунт",
    "63462": "фура",
    "63463": "фураж",
    "63464": "фурия",
    "63465": "фурма",
    "63466": "фурор",
    "63511": "фут",
    "63512": "футор",
    "63513": "фуэтэ",
    "63514": "фьорд",
    "63515": "фью",
    "63516": "фьють",
    "63521": "земляника",
    "63522": "фён",
    "63523": "хабар",
    "63524": "хаджи",
    "63525": "хаз",
    "63526": "хай",
    "63531": "фабрика",
    "63532": "хакас",
    "63533": "хаки",
    "63534": "хала",
    "63535": "халат",
    "63536": "халва",
    "63541": "халиф",
    "63542": "рубрика",
    "63543": "лирика",
    "63544": "хамса",
    "63545": "риторика",
    "63546": "хан",
    "63551": "паприка",
    "63552": "ханты",
    "63553": "ханша",
    "63554": "хаос",
    "63555": "метрика",
    "63556": "классика",
    "63561": "акробатика",
    "63562": "энергетика",
    "63563": "атлетика",
    "63564": "косметика",
    "63565": "хвала",
    "63566": "хват",
    "63611": "хвать",
    "63612": "хворь",
    "63613": "хвост",
    "63614": "хвощ",
    "63615": "хвоя",
    "63616": "хедер",
    "63621": "хедив",
    "63622": "хек",
    "63623": "херес",
    "63624": "хетт",
    "63625": "хиазм",
    "63626": "генетика",
    "63631": "химик",
    "63632": "химия",
    "63633": "хина",
    "63634": "хинди",
    "63635": "хинин",
    "63636": "хинон",
    "63641": "хиппи",
    "63642": "хитин",
    "63643": "хитон",
    "63644": "хитро",
    "63645": "хищно",
    "63646": "хлад",
    "63651": "хлам",
    "63652": "хлеб",
    "63653": "хлев",
    "63654": "хлоп",
    "63655": "хлор",
    "63656": "хлыст",
    "63661": "хлюп",
    "63662": "хлюст",
    "63663": "хляби",
    "63664": "хлябь",
    "63665": "хмель",
    "63666": "хмуро",
    "64111": "хмурь",
    "64112": "хна",
    "64113": "хны",
    "64114": "хоана",
    "64115": "хобби",
    "64116": "хобот",
    "64121": "ход",
    "64122": "хода",
    "64123": "ходжа",
    "64124": "ходко",
    "64125": "ходок",
    "64126": "ходун",
    "64131": "хозар",
    "64132": "холка",
    "64133": "холл",
    "64134": "холм",
    "64135": "холод",
    "64136": "холоп",
    "64141": "холст",
    "64142": "холуй",
    "64143": "холя",
    "64144": "хомут",
    "64145": "хомяк",
    "64146": "хор",
    "64151": "хорал",
    "64152": "хорда",
    "64153": "хорей",
    "64154": "хорея",
    "64155": "хором",
    "64156": "хорт",
    "64161": "хоры",
    "64162": "хорь",
    "64163": "хорёк",
    "64164": "хоть",
    "64165": "хотя",
    "64166": "политика",
    "64211": "хохот",
    "64212": "храм",
    "64213": "храп",
    "64214": "критика",
    "64215": "хрип",
    "64216": "хрипы",
    "64221": "хром",
    "64222": "хруп",
    "64223": "хруст",
    "64224": "хрущ",
    "64225": "галактика",
    "64226": "практика",
    "64231": "тактика",
    "64232": "романтика",
    "64233": "хрящ",
    "64234": "худее",
    "64235": "худо",
    "64236": "худое",
    "64241": "худой",
    "64242": "хуже",
    "64243": "хула",
    "64244": "хунта",
    "64245": "хурал",
    "64246": "хурма",
    "64251": "хутор",
    "64252": "цадик",
    "64253": "цанга",
    "64254": "цап",
    "64255": "цапка",
    "64256": "цапля",
    "64261": "цапфа",
    "64262": "царап",
    "64263": "царь",
    "64264": "царёв",
    "64265": "царёк",
    "64266": "цахур",
    "64311": "оптика",
    "64312": "синоптик",
    "64313": "цвель",
    "64314": "цвет",
    "64315": "цветы",
    "64316": "цевка",
    "64321": "цевьё",
    "64322": "цедра",
    "64323": "цеж",
    "64324": "цезий",
    "64325": "цейс",
    "64326": "целик",
    "64331": "целое",
    "64332": "целый",
    "64333": "цель",
    "64334": "цена",
    "64335": "ценз",
    "64336": "цент",
    "64341": "центр",
    "64342": "цеп",
    "64343": "цепи",
    "64344": "цепка",
    "64345": "цепко",
    "64346": "цепь",
    "64351": "цепью",
    "64352": "церий",
    "64353": "цех",
    "64354": "цеце",
    "64355": "циан",
    "64356": "цибик",
    "64361": "цикл",
    "64362": "цикля",
    "64363": "цинга",
    "64364": "циник",
    "64365": "цинк",
    "64366": "цирик",
    "64411": "цирк",
    "64412": "циста",
    "64413": "цитра",
    "64414": "цифра",
    "64415": "цок",
    "64416": "цокот",
    "64421": "цуг",
    "64422": "цугом",
    "64423": "цукат",
    "64424": "цыбик",
    "64425": "цыган",
    "64426": "цыпка",
    "64431": "цыц",
    "64432": "чабан",
    "64433": "чага",
    "64434": "чад",
    "64435": "чадно",
    "64436": "чадо",
    "64441": "чадра",
    "64442": "чай",
    "64443": "чайка",
    "64444": "чал",
    "64445": "чалка",
    "64446": "чалма",
    "64451": "чалый",
    "64452": "чан",
    "64453": "чара",
    "64454": "чарка",
    "64455": "чары",
    "64456": "час",
    "64461": "часок",
    "64462": "часом",
    "64463": "часто",
    "64464": "часть",
    "64465": "часы",
    "64466": "чауш",
    "64511": "чахло",
    "64512": "чаша",
    "64513": "чашка",
    "64514": "чаща",
    "64515": "чаще",
    "64516": "чаять",
    "64521": "чаёк",
    "64522": "чего",
    "64523": "чей",
    "64524": "чек",
    "64525": "чека",
    "64526": "чекан",
    "64531": "чело",
    "64532": "чем",
    "64533": "чемер",
    "64534": "чему",
    "64535": "чепе",
    "64536": "чепец",
    "64541": "черва",
    "64542": "гимнаст",
    "64543": "фантастика",
    "64544": "лингвист",
    "64545": "через",
    "64546": "логистика",
    "64551": "чернь",
    "64552": "черта",
    "64553": "черёд",
    "64554": "честь",
    "64555": "чета",
    "64556": "чех",
    "64561": "чехол",
    "64562": "чешка",
    "64563": "чешки",
    "64564": "чешуя",
    "64565": "чибис",
    "64566": "чиж",
    "64611": "чижик",
    "64612": "чий",
    "64613": "чик",
    "64614": "чилим",
    "64615": "чин",
    "64616": "чина",
    "64621": "чинар",
    "64622": "чинка",
    "64623": "чинно",
    "64624": "чинш",
    "64625": "чирей",
    "64626": "журналист",
    "64631": "чирк",
    "64632": "чирок",
    "64633": "число",
    "64634": "чисто",
    "64635": "читка",
    "64636": "чих",
    "64641": "чище",
    "64642": "мистика",
    "64643": "характеристика",
    "64644": "чомга",
    "64645": "чон",
    "64646": "чоп",
    "64651": "чох",
    "64652": "чохом",
    "64653": "флорист",
    "64654": "чреда",
    "64655": "чрез",
    "64656": "чтец",
    "64661": "чтиво",
    "64662": "чтить",
    "64663": "чтица",
    "64664": "что",
    "64665": "чтоб",
    "64666": "чтобы",
    "65111": "чуб",
    "65112": "чубук",
    "65113": "чуваш",
    "65114": "статистика",
    "65115": "чугун",
    "65116": "чудак",
    "65121": "публицистика",
    "65122": "чудно",
    "65123": "чудо",
    "65124": "чудом",
    "65125": "чудь",
    "65126": "чужак",
    "65131": "чужие",
    "65132": "чужое",
    "65133": "чужой",
    "65134": "чуйка",
    "65135": "чукча",
    "65136": "чулан",
    "65141": "чулки",
    "65142": "чулок",
    "65143": "чум",
    "65144": "чума",
    "65145": "чумак",
    "65146": "чуни",
    "65151": "чур",
    "65152": "чурек",
    "65153": "диагностика",
    "65154": "чутко",
    "65155": "чуток",
    "65156": "чутче",
    "65161": "чуть",
    "65162": "чутьё",
    "65163": "чуфа",
    "65164": "чушка",
    "65165": "чушь",
    "65166": "чуять",
    "65211": "акустика",
    "65212": "чья",
    "65213": "чьё",
    "65214": "чёлка",
    "65215": "чёлн",
    "65216": "графика",
    "65221": "психика",
    "65222": "чёска",
    "65223": "чёт",
    "65224": "чётки",
    "65225": "чётко",
    "65226": "балалайка",
    "65231": "шабер",
    "65232": "шабли",
    "65233": "шабот",
    "65234": "незнайка",
    "65235": "шаг",
    "65236": "шагом",
    "65241": "шажок",
    "65242": "шайба",
    "65243": "индейка",
    "65244": "чародейка",
    "65245": "шалаш",
    "65246": "наклейка",
    "65251": "шалый",
    "65252": "шаль",
    "65253": "шаман",
    "65254": "шамот",
    "65255": "шанец",
    "65256": "шанкр",
    "65261": "шанс",
    "65262": "шапка",
    "65263": "шар",
    "65264": "шарж",
    "65265": "шарик",
    "65266": "шарк",
    "65311": "шарм",
    "65312": "шарф",
    "65313": "шассе",
    "65314": "шасси",
    "65315": "шасть",
    "65316": "шатен",
    "65321": "шатия",
    "65322": "шатко",
    "65323": "шатун",
    "65324": "шатёр",
    "65325": "шафер",
    "65326": "шах",
    "65331": "шахта",
    "65332": "шашка",
    "65333": "шашки",
    "65334": "шашни",
    "65335": "шваб",
    "65336": "скамейка",
    "65341": "швах",
    "65342": "швед",
    "65343": "швец",
    "65344": "швея",
    "65345": "шевро",
    "65346": "шед",
    "65351": "шейк",
    "65352": "шейка",
    "65353": "шейх",
    "65354": "шелеп",
    "65355": "шелом",
    "65356": "шелон",
    "65361": "шельф",
    "65362": "шемая",
    "65363": "шериф",
    "65364": "шерл",
    "65365": "шест",
    "65366": "шесть",
    "65411": "шеф",
    "65412": "шея",
    "65413": "шибер",
    "65414": "шибко",
    "65415": "шиизм",
    "65416": "шиит",
    "65421": "шик",
    "65422": "шило",
    "65423": "шина",
    "65424": "шинок",
    "65425": "шип",
    "65426": "шипун",
    "65431": "шире",
    "65432": "ширма",
    "65433": "ширь",
    "65434": "шитый",
    "65435": "шить",
    "65436": "шитьё",
    "65441": "шифер",
    "65442": "шифон",
    "65443": "шифр",
    "65444": "шихта",
    "65445": "шиш",
    "65446": "шишак",
    "65451": "шишка",
    "65452": "шкала",
    "65453": "шкаф",
    "65454": "шквал",
    "65455": "шкив",
    "65456": "шкода",
    "65461": "школа",
    "65462": "ячейка",
    "65463": "шлак",
    "65464": "шлам",
    "65465": "шланг",
    "65466": "шлейф",
    "65511": "шлем",
    "65512": "шлея",
    "65513": "шлиф",
    "65514": "шлих",
    "65515": "шлиц",
    "65516": "шлык",
    "65521": "шлюз",
    "65522": "шлюп",
    "65523": "шлаг",
    "65524": "шляпа",
    "65525": "шлях",
    "65526": "шлёп",
    "65531": "шмель",
    "65532": "хост",
    "65533": "шкот",
    "65534": "шнапс",
    "65535": "шнек",
    "65536": "шнур",
    "65541": "шов",
    "65542": "шок",
    "65543": "шорец",
    "65544": "шорка",
    "65545": "шорох",
    "65546": "шорты",
    "65551": "шоры",
    "65552": "шоссе",
    "65553": "шоу",
    "65554": "шофёр",
    "65555": "шпага",
    "65556": "шпала",
    "65561": "шпана",
    "65562": "шпат",
    "65563": "шпик",
    "65564": "шпиль",
    "65565": "шпинь",
    "65566": "шпион",
    "65611": "шпиц",
    "65612": "шпон",
    "65613": "шпона",
    "65614": "шпора",
    "65615": "шприц",
    "65616": "шпуля",
    "65621": "шпунт",
    "65622": "шпур",
    "65623": "шрам",
    "65624": "шрифт",
    "65625": "шрот",
    "65626": "штаб",
    "65631": "штаг",
    "65632": "штамб",
    "65633": "штамм",
    "65634": "штамп",
    "65635": "штаны",
    "65636": "штат",
    "65641": "штаты",
    "65642": "штейн",
    "65643": "штиль",
    "65644": "штифт",
    "65645": "шток",
    "65646": "штора",
    "65651": "шторм",
    "65652": "штос",
    "65653": "штоф",
    "65654": "штраф",
    "65655": "штрек",
    "65656": "штрих",
    "65661": "штука",
    "65662": "штурм",
    "65663": "штуф",
    "65664": "штыб",
    "65665": "штык",
    "65666": "штырь",
    "66111": "шуаны",
    "66112": "шуба",
    "66113": "шубат",
    "66114": "шуга",
    "66115": "эмпат",
    "66116": "шум",
    "66121": "шумно",
    "66122": "шумок",
    "66123": "шунт",
    "66124": "шурин",
    "66125": "шуруп",
    "66126": "шурф",
    "66131": "шуст",
    "66132": "шут",
    "66133": "шутка",
    "66134": "шутя",
    "66135": "шхеры",
    "66136": "шхуна",
    "66141": "шёлк",
    "66142": "шёпот",
    "66143": "щебет",
    "66144": "щегол",
    "66145": "щедро",
    "66146": "щека",
    "66151": "щель",
    "66152": "щенок",
    "66153": "щепа",
    "66154": "щепка",
    "66155": "щипец",
    "66156": "щипка",
    "66161": "щипок",
    "66162": "щипцы",
    "66163": "щит",
    "66164": "щиток",
    "66165": "щука",
    "66166": "щуп",
    "66211": "щупик",
    "66212": "щур",
    "66213": "щучий",
    "66214": "щучка",
    "66215": "щёкот",
    "66216": "щёлк",
    "66221": "щёлка",
    "66222": "щёлок",
    "66223": "щётка",
    "66224": "щёчка",
    "66225": "эвен",
    "66226": "эвенк",
    "66231": "эге",
    "66232": "эгида",
    "66233": "эгрет",
    "66234": "эдак",
    "66235": "эдем",
    "66236": "эдикт",
    "66241": "эдил",
    "66242": "экер",
    "66243": "экзот",
    "66244": "экий",
    "66245": "эклер",
    "66246": "экран",
    "66251": "экю",
    "66252": "элита",
    "66253": "эллин",
    "66254": "эльф",
    "66255": "эмаль",
    "66256": "эмир",
    "66261": "эмка",
    "66262": "эму",
    "66263": "эндем",
    "66264": "энзим",
    "66265": "энный",
    "66266": "эолит",
    "66311": "эолов",
    "66312": "эоцен",
    "66313": "эпик",
    "66314": "эпика",
    "66315": "эпос",
    "66316": "эпоха",
    "66321": "эра",
    "66322": "эрбий",
    "66323": "эрг",
    "66324": "эре",
    "66325": "эрзац",
    "66326": "эрзя",
    "66331": "эркер",
    "66332": "эрос",
    "66333": "эсдек",
    "66334": "эсер",
    "66335": "эскиз",
    "66336": "эссе",
    "66341": "эстет",
    "66342": "эта",
    "66343": "этаж",
    "66344": "этак",
    "66345": "этан",
    "66346": "этап",
    "66351": "эти",
    "66352": "этика",
    "66353": "этил",
    "66354": "этнос",
    "66355": "это",
    "66356": "этот",
    "66361": "этюд",
    "66362": "эфа",
    "66363": "эфес",
    "66364": "эфиоп",
    "66365": "эфир",
    "66366": "эхма",
    "66411": "эхо",
    "66412": "юань",
    "66413": "юбка",
    "66414": "юдоль",
    "66415": "южнее",
    "66416": "южный",
    "66421": "юзист",
    "66422": "юзом",
    "66423": "юкка",
    "66424": "юкола",
    "66425": "юла",
    "66426": "юлить",
    "66431": "юмор",
    "66432": "юнга",
    "66433": "юнец",
    "66434": "юниор",
    "66435": "юнкер",
    "66436": "юнкор",
    "66441": "юннат",
    "66442": "юноша",
    "66443": "юный",
    "66444": "юрист",
    "66445": "юрк",
    "66446": "юркий",
    "66451": "юрко",
    "66452": "юрок",
    "66453": "юрта",
    "66454": "юрьев",
    "66455": "юфть",
    "66456": "юха",
    "66461": "юшка",
    "66462": "чипсы",
    "66463": "явить",
    "66464": "явка",
    "66465": "явно",
    "66466": "явный",
    "66511": "явор",
    "66512": "явь",
    "66513": "яга",
    "66514": "ягель",
    "66515": "ягода",
    "66516": "ягуар",
    "66521": "ядро",
    "66522": "мантра",
    "66523": "язык",
    "66524": "язь",
    "66525": "читер",
    "66526": "яйла",
    "66531": "яйцо",
    "66532": "якать",
    "66533": "якобы",
    "66534": "якорь",
    "66535": "якут",
    "66536": "ялик",
    "66541": "яма",
    "66542": "ямб",
    "66543": "ямина",
    "66544": "ямка",
    "66545": "ямс",
    "66546": "ямщик",
    "66551": "янки",
    "66552": "ярд",
    "66553": "ярее",
    "66554": "ярем",
    "66555": "ярка",
    "66556": "яркий",
    "66561": "ярко",
    "66562": "ярлык",
    "66563": "ярмо",
    "66564": "яро",
    "66565": "ярус",
    "66566": "ярче",
    "66611": "ярый",
    "66612": "ярь",
    "66613": "ясак",
    "66614": "ясень",
    "66615": "ясли",
    "66616": "яснее",
    "66621": "ясно",
    "66622": "ясный",
    "66623": "яство",
    "66624": "ястык",
    "66625": "ясырь",
    "66626": "ятка",
    "66631": "ятовь",
    "66632": "ять",
    "66633": "яхонт",
    "66634": "яхта",
    "66635": "ячея",
    "66636": "ячий",
    "66641": "яшма",
    "66642": "ящер",
    "66643": "ящик",
    "66644": "ящур",
    "66645": "ёжик",
    "66646": "ёжиком",
    "66651": "ёжить",
    "66652": "ёканье",
    "66653": "ёкать",
    "66654": "ёкнуть",
    "66655": "ёлка",
    "66656": "ёлкий",
    "66661": "ёлочка",
    "66662": "ёмкий",
    "66663": "ёмко",
    "66664": "ёрзать",
    "66665": "ёрш",
    "66666": "ёршик",
};